import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import styledComponent from "styled-components";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import Picker from "emoji-picker-react";

import { FiMessageSquare } from "react-icons/fi";
import { BsTrash3 } from "react-icons/bs";
import { VscVariableGroup } from "react-icons/vsc";
import { FaSpinner } from "react-icons/fa";

const TransferAgent = ({
    selectedNode,
    handleSelectedNode,
    removeNode,
    nodes,
    edges,
    editFlow,
    updateBot,
    truncateText,
    handleClickModalVariables,
    onEmojiClick,
    customStyles,
    customStylesDark,
    isDark,
    listAreas,
    listAdvisers,
    handleCursorPosition,
    watchSelectedNode
}) => {
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        user_response: null,
        dynamic_buttons_variable: null,
        dynamic_buttons_variable_description: null,
        regular_expressions_error_message: null,
    });

    const [errorAgent, setErrorAgent] = useState(false);

    // ------> Selects, Radios, Checkbox
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    // ------> Modals, Dialogs
    const [showPicker, setShowPicker] = useState(false);

    // FUNCTIONS

    //# Function para manejar cambios de estados de campos
    const handleInputChange = (fieldPath, value) => {
        handleSelectedNode((prevInputValue) => {
            const updateNestedField = (obj = {}, path, val) => {
                const [key, ...rest] = path.split(".");
                return {
                    ...obj,
                    [key]:
                        rest.length > 0
                            ? updateNestedField(
                                  obj[key] || {},
                                  rest.join("."),
                                  val
                              )
                            : val,
                };
            };

            return {
                ...prevInputValue,
                data: updateNestedField(prevInputValue.data, fieldPath, value),
            };
        });
    };

    //# Function para guardar los datos
    const saveData = (values) => {
        if(!selectedNode.data?.agent_area?.value) {
            setErrorAgent(true);
            return;
        }

        setLoadingUpdate(true);

        const data = {
            title: values.title,
            user_response: values.user_response,
            bot_response: values.bot_response
        };

        handleSelectedNode((prevInputValue) => ({
            ...prevInputValue,
            data: {
                ...prevInputValue.data,
                ...data,
                saved: true,
            },
        }));
        setTimeout(async () => {
            await updateBot(editFlow, edges, nodes);
            setLoadingUpdate(false);
        }, 500);
    };

    //# Function para obtner data
    const getData = () => {
        let objectData = {
            title: selectedNode.data?.title ?? null,
            user_response: selectedNode.data?.user_response ?? null,
            bot_response: selectedNode.data?.bot_response ?? null
        };

        reset(objectData, { keepDefaultValues: true });
    };

    useEffect(() => {
        getData();
    }, [watchSelectedNode]);

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-between text-white"
                style={{
                    height: "50px",
                    padding: "10px",
                    borderBottom: "1px solid #CED4DA",
                    backgroundColor: "#F56F89",
                }}
            >
                <div className="d-flex align-items-center">
                    <FiMessageSquare
                        className="mr-1"
                        style={{ fontSize: "24px" }}
                    />
                    <span style={{ fontSize: "22px", whiteSpace: "nowrap" }}>
                        {truncateText(selectedNode.data.title)}
                    </span>
                </div>
                <button
                    className="text-white border-0 bg-transparent d-block pointer mt-2"
                    onClick={() => {
                        removeNode(selectedNode.id);
                    }}
                >
                    <BsTrash3 style={{ fontSize: "18px" }} />
                </button>
            </div>
            <div
                id="content-list-chats"
                className="card-body"
                style={{ overflowY: "auto" }}
            >
                <form onSubmit={handleSubmit(saveData)} style={{ width: "100%" }}>
                    <div>
                        <div className="col p-0">
                            <label
                                htmlFor="title"
                                style={{ color: "#6C757D", fontWeight: 600 }}
                            >
                                Título:{" "}
                            </label>
                            <div>
                                <input
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    type="text"
                                    style={{ width: "100%", height: "35px" }}
                                    {...register("title", {
                                        required: {
                                            value: true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.title && <Alert>* {errors.title.message}</Alert>}
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="user_response"
                                style={{ color: "#6C757D", fontWeight: 600 }}
                            >
                                Respuesta del cliente:{" "}
                            </label>
                            <div>
                                <textarea
                                    className="form-control"
                                    id="user_response"
                                    name="user_response"
                                    type="text"
                                    style={{
                                        width: "100%",
                                        height: "50px",
                                        borderRadius: "4px",
                                    }}
                                    onClick={handleCursorPosition}
                                    onKeyUp={handleCursorPosition} 
                                    {...register("user_response", {
                                        required: {
                                            value: selectedNode.data.first ? false : true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.user_response && <Alert>* {errors.user_response.message}</Alert>}
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 600,
                                    }}
                                >
                                    Mensaje que envía el cliente para
                                    activar la respuesta.
                                </p>
                                <div
                                    className="p-1"
                                    style={{
                                        backgroundColor:
                                            "rgba(245, 111, 137, 0.3)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: 11,
                                            color: "#6C757D",
                                            fontWeight: 600,
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Importante:
                                        </span>{" "}
                                        Los nodos se pueden activar con
                                        multiples respuestas según esten
                                        configuradas, para ello debe tener el
                                        siguiente formato: Respuesta 1 /
                                        Respuesta 2 / Respuesta 3 configurado en
                                        dicho apartado.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="bot_response"
                                style={{ color: "#6C757D", fontWeight: 600 }}
                            >
                                Respuesta del Bot:{" "}
                            </label>
                            <div>
                                <textarea
                                    className="form-control"
                                    id="bot_response"
                                    name="bot_response"
                                    placeholder="Escribe la respuesta del Bot"
                                    style={{
                                        width: "100%",
                                        height: "90px",
                                        borderRadius: "4px",
                                    }}
                                    {...register("bot_response", {
                                        required: {
                                            value: true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.bot_response && <Alert>* {errors.bot_response.message}</Alert>}
                                <div className="d-flex align-items-center">
                                    <div className="pt-2">
                                        <button
                                            className="text-dark border-0 bg-transparent d-block pointer"
                                            type="button"
                                            onClick={(event) =>
                                                handleClickModalVariables(
                                                    event,
                                                    "bot_response",
                                                    "node"
                                                )
                                            }
                                        >
                                            <VscVariableGroup
                                                style={{ fontSize: "18px" }}
                                            />
                                        </button>
                                    </div>
                                    <div
                                        className="picker-container pt-4"
                                        style={{ width: "100%" }}
                                    >
                                        <i
                                            className={
                                                showPicker
                                                    ? "icon-smile emoji-icon active"
                                                    : "icon-smile emoji-icon"
                                            }
                                            style={{ fontSize: "22px" }}
                                            onClick={() =>
                                                setShowPicker((val) => !val)
                                            }
                                        />

                                        {showPicker && (
                                            <Picker
                                                pickerStyle={{
                                                    width: "100%",
                                                    top: "30px",
                                                    position: "absolute",
                                                    zIndex: "999",
                                                }}
                                                onEmojiClick={onEmojiClick}
                                                groupNames={{
                                                    smileys_people:
                                                        "Emoticones y personas",
                                                    animals_nature:
                                                        "Animales y naturaleza",
                                                    food_drink:
                                                        "Alimentos y bebidas",
                                                    travel_places:
                                                        "Viajes y lugares",
                                                    activities: "Actividades",
                                                    objects: "Objetos",
                                                    symbols: "Símbolos",
                                                    flags: "Banderas",
                                                    recently_used: "Recientes",
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div
                                className="p-1"
                                style={{
                                    backgroundColor: "rgba(245, 111, 137, 0.3)",
                                    borderRadius: "4px",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 600,
                                    }}
                                >
                                    <span style={{ fontSize: 12, fontWeight: 600 }}>
                                        Importante:
                                    </span>{" "}
                                    Ten en cuenta que las transferencias a agentes o
                                    áreas se realizarán únicamente durante el
                                    horario comercial. El cliente recibirá el
                                    mensaje previamente configurado en dicho
                                    apartado.
                                </p>
                            </div>
                        </div>

                        <div className="col p-0 mt-4">
                            <div className="d-flex mb-2">
                                <div className="d-flex pointer mr-1">
                                    <input
                                        className="pointer"
                                        style={{ accentColor: "#5BC0DE" }}
                                        type="radio"
                                        value="area"
                                        checked={
                                            selectedNode.data.agent_area?.type ===
                                            "area"
                                        }
                                        onChange={() =>
                                            handleInputChange(
                                                "agent_area",
                                                {
                                                    label: null,
                                                    value: null,
                                                    type: "area",
                                                }
                                            )
                                        }
                                    />
                                    <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                    >
                                        Areas
                                    </label>
                                </div>
                                <div className="d-flex pointer mr-1">
                                    <input
                                        className="pointer"
                                        style={{ accentColor: "#5BC0DE" }}
                                        type="radio"
                                        value="agent"
                                        checked={
                                            selectedNode.data.agent_area?.type ===
                                            "agent"
                                        }
                                        onChange={() =>
                                            handleInputChange(
                                                "agent_area",
                                                {
                                                    label: null,
                                                    value: null,
                                                    type: "agent",
                                                }
                                            )
                                        }
                                    />
                                    <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                    >
                                        Agentes
                                    </label>
                                </div>
                                <div className="d-flex pointer">
                                    <input
                                        className="pointer"
                                        style={{ accentColor: "#5BC0DE" }}
                                        type="radio"
                                        value="automatic"
                                        checked={
                                            selectedNode.data.agent_area?.type ===
                                            "automatic"
                                        }
                                        onChange={() =>
                                            handleInputChange(
                                                "agent_area",
                                                {
                                                    label: null,
                                                    value: null,
                                                    type: "automatic",
                                                }
                                            )
                                        }
                                    />
                                    <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                    >
                                        Automático
                                    </label>
                                </div>
                            </div>

                            {selectedNode.data.agent_area?.type == "area" && (
                                <div>
                                    <label>Areas: </label>
                                    <div className="su-control-group d-flex">
                                        <Select
                                            styles={
                                                isDark == "true"
                                                    ? customStylesDark
                                                    : customStyles
                                            }
                                            className="form-control basic-multi-select"
                                            placeholder="Seleccione..."
                                            name="areas"
                                            value={selectedNode.data.agent_area || null}
                                            onChange={(area) => {
                                                handleInputChange(
                                                    "agent_area",
                                                    area
                                                );
                                                setErrorAgent(false);
                                            }}
                                            options={listAreas}
                                            classNamePrefix="select"
                                            noOptionsMessage={() => "Sin opciones"}
                                            getOptionLabel={(option) => (
                                                <div
                                                    className="option d-flex"
                                                    style={{ fontSize: "15px" }}
                                                >
                                                    {option.label}
                                                </div>
                                            )}
                                            getOptionValue={(option) =>
                                                option.value
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {selectedNode.data.agent_area?.type == "agent" && (
                                <div>
                                    <label>Agentes: </label>
                                    <div className="su-control-group d-flex">
                                        <Select
                                            styles={
                                                isDark == "true"
                                                    ? customStylesDark
                                                    : customStyles
                                            }
                                            className="form-control basic-multi-select"
                                            placeholder="Seleccione..."
                                            name="agents"
                                            value={selectedNode.data.agent_area || null}
                                            onChange={(agent) =>{
                                                handleInputChange(
                                                    "agent_area",
                                                    agent
                                                );
                                                setErrorAgent(false);
                                            }}
                                            options={listAdvisers}
                                            classNamePrefix="select"
                                            noOptionsMessage={() => "Sin opciones"}
                                            getOptionLabel={(option) => (
                                                <div
                                                    className="option d-flex"
                                                    style={{ fontSize: "15px" }}
                                                >
                                                    {option.label}
                                                </div>
                                            )}
                                            getOptionValue={(option) =>
                                                option.value
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {selectedNode.data.agent_area?.type == "automatic" && (
                                <div>
                                    <label>Automático: </label>
                                    <div className="su-control-group d-flex">
                                        <Select
                                            styles={
                                                isDark == "true"
                                                    ? customStylesDark
                                                    : customStyles
                                            }
                                            className="form-control basic-multi-select"
                                            placeholder="Seleccione..."
                                            name="automatics"
                                            value={selectedNode.data.agent_area || null}
                                            onChange={(auto) =>{
                                                handleInputChange(
                                                    "agent_area",
                                                    auto
                                                );
                                                setErrorAgent(false);
                                            }}
                                            options={[
                                                {
                                                    label: "Area",
                                                    id: "areas",
                                                    type: "automatic",
                                                },
                                                {
                                                    label: "Agente",
                                                    id: "agents",
                                                    type: "automatic",
                                                },
                                            ]}
                                            classNamePrefix="select"
                                            noOptionsMessage={() => "Sin opciones"}
                                            getOptionLabel={(option) => (
                                                <div
                                                    className="option d-flex"
                                                    style={{ fontSize: "15px" }}
                                                >
                                                    {option.label}
                                                </div>
                                            )}
                                            getOptionValue={(option) => option.id}
                                        />
                                    </div>
                                </div>
                            )}
                            {errorAgent && <Alert>* Campo requerido</Alert>}
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-end mt-4"
                        style={{ gap: "8px" }}
                    >
                        <a
                            href="javascript:;"
                            className="btn btn-lg btn-default mr-1"
                            onClick={() => {
                                setTimeout(() => {
                                    handleSelectedNode(null);
                                }, 200);
                            }}
                        >
                            Cerrar
                        </a>

                        <button
                            style={{
                                width: "100px",
                                backgroundColor: "#F56F89",
                                borderColor: "#F56F89",
                                color: "#e9edef",
                                boxShadow: "2px 2px 0px 0px #F56F89",
                            }}
                            className="btn btn-lg"
                            type="submit"
                        >
                            {loadingUpdate ? (
                                <FaSpinner
                                    className="fa-spin"
                                    style={{ fontSize: "15px" }}
                                />
                            ) : (
                                <span>Guardar</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={
                    JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"
                }
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            />
        </>
    );
};

export default TransferAgent;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;
