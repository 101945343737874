import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { upload_s3 } from '../../../UploaderS3';
import useAPI from "../../../../../hooks/useAPI";

import styledComponent from "styled-components";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import { sendToast } from "../../../../plugins/toastAll";
import Picker from "emoji-picker-react";
import { RichTreeView } from '@mui/x-tree-view';
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

import { FiMessageSquare } from "react-icons/fi";
import { BsTrash3, BsFillCameraVideoFill, BsFillFileEarmarkTextFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkWordFill, BsFillFileEarmarkFill, BsFileEarmarkExcelFill } from "react-icons/bs";
import { VscVariableGroup } from "react-icons/vsc";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { FaImages, FaHeadphones, FaSpinner } from "react-icons/fa";
import { AiOutlineSelect } from "react-icons/ai";

const BasicAnswer = ({
    selectedNode,
    handleSelectedNode,
    handleNodes,
    removeNode,
    nodes,
    edges,
    editFlow,
    updateBot,
    truncateText,
    handleClickModalVariables,
    onEmojiClick,
    listRequestParams,
    customStyles,
    customStylesDark,
    isDark,
    handleCursorPosition,
    watchSelectedNode,
    handleLoading
}) => {
    const { urlAPI_1, fetchWithAuth } = useAPI(); 
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        user_response: null,
        dynamic_buttons_variable: null,
        dynamic_buttons_variable_description: null,
        regular_expressions_error_message: null,
    });

    // ------> Selects, Radios, Checkbox
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    // ------> Modals, Dialogs
    const [showPicker, setShowPicker] = useState(false);

    // ------> Dropdowns
    const [dropdownRegularExpression, setDropdownRegularExpression] = useState(false);
    const [dropdownAdvancedOptions, setDropdownAdvanceOptions] = useState(false);

    // FUNCTIONS

    //# Function para manejar cierre y aparetura de dropdowns
    const handleDropdown = (property) => {
        switch(property) {
          case 'selectHeaders':
            setSelectHeaders(!selectHeaders);
            break;
          case 'selectRequest':
            setSelectRequest(!selectRequest);
            break;
          case 'selectRequestBody':
            setSelectRequestBody(!selectRequestBody);
            break;
          case 'selectResponse':
            setSelectResponse(!selectResponse);
            break;
          case 'dropdownAdvancedOptions':
            setDropdownAdvanceOptions(!dropdownAdvancedOptions);
            break;
          case 'dropdownRegularExpression':
            setDropdownRegularExpression(!dropdownRegularExpression);
            break;
          default:
            console.error(`Property ${property} not recognized`);
        }
    };

    //# Function para manejar cambios de estados de campos
    const handleInputChange = (fieldPath, value) => {
        handleSelectedNode((prevInputValue) => {
            const updateNestedField = (obj = {}, path, val) => {
                const [key, ...rest] = path.split(".");
                return {
                    ...obj,
                    [key]: rest.length > 0 
                        ? updateNestedField(obj[key] || {}, rest.join("."), val) 
                        : val,
                };
            };            
            
            return {
                ...prevInputValue,
                data: updateNestedField(prevInputValue.data, fieldPath, value),
            };
        });
    };

    //# Function para remover opciones
    const removeOptions = (option, index) => {
        const newOptions = selectedNode.data.options.filter((v, i) => i !== index);
        handleSelectedNode((prevInputValue) => {
            return {
                ...prevInputValue,
                data: {
                    ...prevInputValue.data,
                    options: newOptions,
                }
            }
        });
        const newNodes = nodes.filter(v => v.id !== option.idNode);
        handleNodes(newNodes);
    };

    //# Function para agregar opciones (Btn interactivo)
    const handleOptions = () => {
        if (selectedNode.data.options.length >= 10) {
            return sendToast({
                type: "info",
                message: "Solo se pueden agregar 10 botones u opciones como máximo.",
            });
        }
    
        const length = selectedNode.data.options.length;
        const item = {
            label: "Opción " + (length + 1),
            id: `${selectedNode.id}-${length + 1}-Opción-${length + 1}`,
            name: "titulo",
        };
    
        const updatedOptions = [...selectedNode.data.options, item];
    
        const adjustedOptions =
            length > 3
                ? updatedOptions.map((v) => ({
                      ...v,
                      description: v.description || "",
                  }))
                : updatedOptions;
    
        handleInputChange("options", adjustedOptions);
    
        // Configurar valores para advanced.nextFlow
        handleInputChange("advanced.nextFlow", {
            label: "No",
            id: false,
        });
    };    

    //# Function para guardar los datos
    const saveData = (values) => {

        setLoadingUpdate(true);

        const data = {
            title: values.title,
            user_response: values.user_response,
            bot_response: values.bot_response,
            advanced: {
                ...selectedNode.data?.advanced,
                dynamic_buttons_variable: values.dynamic_buttons_variable,
                dynamic_buttons_variable_description: values.dynamic_buttons_variable_description,
                regularExpressions: {
                    ...selectedNode.data?.advanced?.regularExpressions,
                    errorMessage: values.regular_expressions_error_message,
                },
            },
        };

        handleSelectedNode((prevInputValue) => ({
            ...prevInputValue,
            data: {
                ...prevInputValue.data,
                ...data,
                saved: true
            },
        }))
        setTimeout(async () => {
            await updateBot(editFlow, edges, nodes)
            setLoadingUpdate(false);
        }, 500)
    };

    //# Function para obtner data 
    const getData = () => {
        let objectData = {
            title: selectedNode.data?.title ?? null,
            user_response: selectedNode.data?.user_response ?? null,
            bot_response: selectedNode.data?.bot_response ?? null,
            regular_expressions_error_message: selectedNode.data?.advanced?.regularExpressions?.errorMessage ?? null,
            dynamic_buttons_variable: selectedNode.data?.advanced?.dynamic_buttons_variable ?? null,
            dynamic_buttons_variable_description: selectedNode.data?.advanced?.dynamic_buttons_variable_description ?? null
        };        

        reset(objectData, { keepDefaultValues: true });
    }

    //# Functions para validar solicitud de nombre, confirmacion y editado
    const validateNodeRequestName = () => {
        const find = nodes.find(node => node?.data?.advanced?.request_name?.id === true);
        return find ? find.id !== selectedNode?.id : false;
    }; 
    const validateNodeConfirmName = () => {
        const find = nodes.find(node => node?.data?.advanced?.confirm_name?.id === true);
        return find ? find.id !== selectedNode?.id : false;
    }; 
    const validateNodeEditName = () => {
        const find = nodes.find(node => node?.data?.advanced?.edit_name?.id === true);
        return find ? find.id !== selectedNode?.id : false;
    }; 

    //# Fucntions resources
    const onFileInput = async (e, type_file) => {

        const conversionFunctions = {
          image: convertBase64,
          video: async (file) => URL.createObjectURL(file),
          document: convertDocumentBase64,
          audio: convertAudioBase64,
        };
        
        const conversionFunction = conversionFunctions[type_file];
        if (conversionFunction) {
          let file = e.target.files[0];
          const base64 = await conversionFunction(file);
          if (base64) {
            let file_save = {
              name: file.name,
            }
            const data_file = await uploadToS3(file)

            if(!data_file.error && data_file.data.url && data_file.data.type) {
                file_save['url'] = data_file.data.url;
                file_save['type'] = data_file.data.type;
            }
    
            handleInputChange("file", file_save)
          }
        }
    };
    const convertBase64 = (file) => {
        return new Promise((res, rej) => {
          var fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            res(fileReader.result);
          };
          fileReader.onerror = (error) => {
            rej(error);
          };
        });
    };
    const convertDocumentBase64 = (file) => {
    return new Promise((res, rej) => {
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
        res(fileReader.result);
        };
        fileReader.onerror = (error) => {
        rej(error);
        };
    });
    };
    const convertAudioBase64 = (file) => {
        return new Promise((res, rej) => {
          var fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            res(fileReader.result);
          };
          fileReader.onerror = (error) => {
            rej(error);
          };
        });
    };
    const uploadToS3 = async (file) => {
        try {
          handleLoading(true);
          const max_size = checkMaxSize(file);
          if (max_size) {
            handleLoading(false);
            return {error: true, description: "tam_limit_error", data: {name: file.name, size: file.size, file: file, type: file.type}};
          }else {
            const signedUrl = await getSignedUrl(file.name, file.size);
            if(signedUrl){
              const url = await upload_s3(file, signedUrl);
              handleLoading(false);
              return {error: false,  data: {url: url, name: file.name, size: file.size, file: file, type: file.type}};
            }
            handleLoading(false);
            return {error: true, description: "signed_url_error", data: {name: file.name, size: file.size, file: file, type: file.type}};
          }
        } catch (error) {
          handleLoading(false);
          return {error: true, description: "unknown_error", data: error, data: {name: file.name, size: file.size, file: file, type: file.type}};
        }finally{
          handleLoading(false);
        }
    };
    const getSignedUrl = async (key_name, size) => {
        try {
          key_name = encodeURIComponent(key_name);
          const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
          const options = { method: "GET", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          if(response.status === 200){
              let url_signed = await response.json();
              if(url_signed.error){
                  return false;
              }
              return url_signed;
          }else{
              console.error('Error getting signed URL');
              return false;
          }
        } catch (error) {
          console.error('Error getting signed URL:', error);
          return false;
        }
    };
    const checkMaxSize = (file) => {
    let max_size = false;
    if(file.type.includes("audio") && file.size > ((1024 * 1024) * 16)){
        max_size = true;
        sendToast({type: "info", message: `El archivo ${file.name} supera el peso máximo permitido para audio: 16MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`})
    }
    if(file.type.includes("video") && file.size > ((1024 * 1024) * 16)){
        max_size = true;
        sendToast({type: "info", message: `El archivo ${file.name} supera el peso máximo permitido para video: 16MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`})
    }
    if(file.type.includes("image") && file.size > ((1024 * 1024) * 5)){
        max_size = true;
        sendToast({type: "info", message: `El archivo ${file.name} supera el peso máximo permitido para imagen: 5MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`})
    }
    if(file.type.includes("document") && file.size > ((1024 * 1024) * 100)){
        max_size = true;
        sendToast({type: "info", message: `El archivo ${file.name} supera el peso máximo permitido para documentos: 100MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`})
    }
    return max_size;
    }
    const renderMedia = (type, file) => {
        switch (true) {
            case type.includes("image"):
                return (
                    <img 
                        src={file?.url} 
                        alt={file?.name || "Imagen"} 
                        className="w-100" 
                        style={{ borderRadius: "4px" }} 
                    />
                );
            case type.includes("video"):
                return (
                    <ReactPlayer
                        url={file?.url}
                        loop
                        controls
                        width="100%"
                        height="100%"
                        style={{ borderRadius: "4px" }}
                    />
                );
            case type.includes("application"):
                const appIcon = type.includes("pdf") ? <BsFillFileEarmarkPdfFill style={{ color: "red" }} /> :
                                type.includes("csv") || type.includes("xls") ? <BsFileEarmarkExcelFill style={{ color: "#00d591" }} /> :
                                type.includes("docx") ? <BsFillFileEarmarkWordFill style={{ color: "#31acf6" }} /> :
                                <BsFillFileEarmarkFill style={{ color: "#5a5a5a" }} />;
                return (
                    <div className="d-flex align-items-center justify-content-between p-2" style={{ background: "#f0f0f0", borderRadius: "4px" }}>
                        {appIcon}
                        <span style={{ color: "#282828", fontSize: "9px", overflow: "hidden" }}>
                            {file?.name}
                        </span>
                    </div>
                );
            case type.includes("audio"):
                return (
                    <ReactAudioPlayer 
                        src={file?.url} 
                        controls 
                        style={{ width: "100%", height: "20px" }} 
                        className="custom-audio-player" 
                    />
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        getData();
    }, [watchSelectedNode]);    

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-between text-white"
                style={{
                    height: "50px",
                    padding: "10px",
                    borderBottom: "1px solid #CED4DA",
                    backgroundColor: "#00A884",
                }}
            >
                <div className="d-flex align-items-center w-100 text-truncate">
                    <div className="d-flex align-items-center">
                        <FiMessageSquare
                            className="mr-1"
                            style={{ fontSize: "24px" }}
                        />
                    </div>
                    <span style={{ fontSize: "22px" }}>
                        {truncateText(selectedNode.data.title)}
                    </span>
                </div>
                {!selectedNode.data.first && (
                    <button
                        className="text-white border-0 bg-transparent d-block pointer mt-2"
                        onClick={() => {
                            removeNode(selectedNode.id);
                        }}
                    >
                        <BsTrash3 style={{ fontSize: "18px" }} />
                    </button>
                )}
            </div>
            <div
                id="content-list-chats"
                className="card-body"
                style={{ overflowY: "auto" }}
            >
                <form onSubmit={handleSubmit(saveData)} style={{ width: "100%" }}>
                    <div>
                        <div className="col p-0">
                            <label
                                htmlFor="title"
                                style={{ color: "#6C757D", fontWeight: 500 }}
                            >
                                Título:
                            </label>
                            <div>
                                <input
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    type="text"
                                    style={{ width: "100%", height: "35px" }}
                                    {...register("title", {
                                        required: {
                                            value: true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.title && <Alert>* {errors.title.message}</Alert>}
                            </div>
                        </div>
                        {!selectedNode.data.first && (
                            <div className="col p-0 mt-4">
                                <label
                                    htmlFor="user_response"
                                    style={{ color: "#6C757D", fontWeight: 500 }}
                                >
                                    Respuesta del cliente:{" "}
                                </label>
                                <div>
                                    <textarea
                                        className="form-control"
                                        id="user_response"
                                        name="user_response"
                                        type="text"
                                        style={{
                                            width: "100%",
                                            height: "50px",
                                            borderRadius: "4px",
                                        }}
                                        {...register("user_response", {
                                            required: {
                                                value: selectedNode.data.first ? false : true,
                                                message:"Campo requerido.",
                                            },
                                            pattern: {
                                                value: /\S+/,
                                                message: "No puede ser solo espacios en blanco",
                                            },
                                        })}
                                    />
                                    {errors.user_response && <Alert>* {errors.user_response.message}</Alert>}
                                    <p
                                        style={{
                                            fontSize: 11,
                                            color: "#6C757D",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Mensaje que envía el cliente para
                                        activar la respuesta.
                                    </p>
                                    <div
                                        className="p-1"
                                        style={{
                                            backgroundColor:
                                                "rgba(0, 168, 132, 0.3)",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: 11,
                                                color: "#6C757D",
                                                fontWeight: 500,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Importante:
                                            </span>{" "}
                                            Los nodos se pueden activar con
                                            multiples respuestas según esten
                                            configuradas, para ello debe tener el
                                            siguiente formato: Respuesta 1 /
                                            Respuesta 2 / Respuesta 3 configurado en
                                            dicho apartado.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className="col p-0 mt-4"
                            style={{ overflow: "visible", position: "relative" }}
                        >
                            <label
                                htmlFor="bot_response"
                                style={{ color: "#6C757D", fontWeight: 500 }}
                            >
                                Respuesta del Bot:{" "}
                            </label>
                            <div>
                                <textarea
                                    className="form-control"
                                    id="bot_response"
                                    name="bot_response"
                                    placeholder="Escribe la respuesta del Bot"
                                    style={{
                                        width: "100%",
                                        height: "90px",
                                        borderRadius: "4px",
                                    }}
                                    onClick={handleCursorPosition}
                                    onKeyUp={handleCursorPosition} 
                                    {...register("bot_response", {
                                        required: {
                                            value: true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.bot_response && <Alert>* {errors.bot_response.message}</Alert>}
                                <div className="d-flex align-items-center">
                                    <div className="pt-2">
                                        <button
                                            className="text-dark border-0 bg-transparent d-block pointer"
                                            type="button"
                                            onClick={(event) =>
                                                handleClickModalVariables(
                                                    event,
                                                    "bot_response",
                                                    "node"
                                                )
                                            }
                                        >
                                            <VscVariableGroup
                                                style={{ fontSize: "18px" }}
                                            />
                                        </button>
                                    </div>
                                    <div
                                        className="picker-container pt-4"
                                        style={{ width: "100%" }}
                                    >
                                        <i
                                            className={
                                                showPicker
                                                    ? "icon-smile emoji-icon active"
                                                    : "icon-smile emoji-icon"
                                            }
                                            style={{ fontSize: "22px" }}
                                            onClick={() =>
                                                setShowPicker((val) => !val)
                                            }
                                        />

                                        {showPicker && (
                                            <Picker
                                                pickerStyle={{
                                                    width: "200%",
                                                    top: "30px",
                                                    position: "absolute",
                                                    zIndex: "999",
                                                }}
                                                onEmojiClick={onEmojiClick}
                                                groupNames={{
                                                    smileys_people:
                                                        "Emoticones y personas",
                                                    animals_nature:
                                                        "Animales y naturaleza",
                                                    food_drink:
                                                        "Alimentos y bebidas",
                                                    travel_places:
                                                        "Viajes y lugares",
                                                    activities: "Actividades",
                                                    objects: "Objetos",
                                                    symbols: "Símbolos",
                                                    flags: "Banderas",
                                                    recently_used: "Recientes",
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-3 mt-2">
                                <input
                                    name="regularExpressions"
                                    id="regularExpressions"
                                    type="checkbox"
                                    className="cmn-toggle cmn-toggle-round-flat"
                                    value={selectedNode.data?.advanced?.regularExpressions?.active || false}
                                    checked={selectedNode.data?.advanced?.regularExpressions?.active || false}
                                    onChange={(e) =>
                                        handleInputChange(
                                            "advanced.regularExpressions.active",
                                            e.target.checked
                                        )
                                    }
                                />
                                <label
                                    className="mr-2 mb-0"
                                    htmlFor="regularExpressions"
                                ></label>

                                <div
                                    style={{
                                        fontSize: 12,
                                        color: "#6C757D",
                                        fontWeight: 500,
                                    }}
                                >
                                    Expresiones regulares
                                </div>
                            </div>
                            {selectedNode.data?.advanced?.regularExpressions?.active && (
                                <div
                                    className="col p-0 mt-2 mb-4 panel-group collapsible"
                                    id="user-collapsible"
                                >
                                    <div className="panel panel-default">
                                        <div
                                            className="panel-heading p-2"
                                            style={{
                                                border: "1px solid #4F4F4F",
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <div
                                                id="title-regularExpression"
                                                className="panel-title d-flex justify-content-between align-items-center pointer"
                                                onClick={() =>
                                                    handleDropdown(
                                                        "dropdownRegularExpression"
                                                    )
                                                }
                                            >
                                                <span>Expresiones Regulares</span>
                                                {dropdownRegularExpression ? (
                                                    <IoIosArrowDropup
                                                        className="pointer mr-3"
                                                        style={{ fontSize: "20px" }}
                                                    />
                                                ) : (
                                                    <IoIosArrowDropdown
                                                        className="pointer mr-3"
                                                        style={{ fontSize: "20px" }}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        {dropdownRegularExpression ? (
                                            <div
                                                className="panel-collapse p-2"
                                                style={{
                                                    borderRadius: "4px",
                                                }}
                                            >
                                                <div
                                                    className="panel-body"
                                                    style={{ paddingInline: 0 }}
                                                >
                                                    <div className="col p-0 mt-4">
                                                        <label>
                                                            Tipo de expresión:{" "}
                                                        </label>
                                                        <div className="su-control-group d-flex">
                                                            <Select
                                                                styles={
                                                                    isDark == "true"
                                                                        ? customStylesDark
                                                                        : customStyles
                                                                }
                                                                className="form-control basic-multi-select"
                                                                placeholder="Seleccionar"
                                                                value={selectedNode.data?.advanced?.regularExpressions?.type || null}
                                                                name="expression_type"
                                                                onChange={(value) =>
                                                                    handleInputChange("advanced.regularExpressions.type", value)
                                                                }
                                                                options={[
                                                                    {
                                                                        label: "Fecha",
                                                                        id: "date",
                                                                    },
                                                                    {
                                                                        label: "Hora",
                                                                        id: "hour",
                                                                    },
                                                                    {
                                                                        label: "Correo",
                                                                        id: "mail",
                                                                    },
                                                                    {
                                                                        label: "Numérico",
                                                                        id: "number",
                                                                    },
                                                                    {
                                                                        label: "Teléfono",
                                                                        id: "phone",
                                                                    },
                                                                ]}
                                                                classNamePrefix="select"
                                                                noOptionsMessage={() =>
                                                                    "Sin opciones"
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) => (
                                                                    <div
                                                                        className="option d-flex"
                                                                        style={{
                                                                            fontSize:
                                                                                "15px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </div>
                                                                )}
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                            />
                                                        </div>
                                                    </div>
                                                    {![
                                                        "number",
                                                        "mail",
                                                        "phone",
                                                    ].includes(
                                                        selectedNode.data?.advanced?.regularExpressions?.type?.id
                                                    ) && (
                                                        <div className="col p-0 mt-4">
                                                            <label
                                                                htmlFor="expressionFormat"
                                                                style={{
                                                                    color: "#6C757D",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Formato:{" "}
                                                            </label>
                                                            <div>
                                                                <Select
                                                                    styles={
                                                                        isDark ==
                                                                        "true"
                                                                            ? customStylesDark
                                                                            : customStyles
                                                                    }
                                                                    className="form-control basic-multi-select"
                                                                    placeholder="Seleccionar"
                                                                    value={selectedNode.data?.advanced?.regularExpressions?.format  || false}
                                                                    name="expressionFormat"
                                                                    onChange={(value) =>
                                                                        handleInputChange("advanced.regularExpressions.format", value)
                                                                    }
                                                                    options={
                                                                        selectedNode.data?.advanced?.regularExpressions?.type?.id === "date"
                                                                        ? [
                                                                                {
                                                                                    label: "mm/dd/aaaa",
                                                                                    id: "mm/dd/aaaa",
                                                                                },
                                                                                {
                                                                                    label: "mm/dd/aa",
                                                                                    id: "mm/dd/aa",
                                                                                },
                                                                                {
                                                                                    label: "dd/mm/aaaa",
                                                                                    id: "dd/mm/aaaa",
                                                                                },
                                                                                {
                                                                                    label: "dd/mm/aa",
                                                                                    id: "dd/mm/aa",
                                                                                },
                                                                                {
                                                                                    label: "dd-mm-aaaa",
                                                                                    id: "dd-mm-aaaa",
                                                                                },
                                                                                {
                                                                                    label: "dd-mm-aa",
                                                                                    id: "dd-mm-aa",
                                                                                },
                                                                                {
                                                                                    label: "mm-dd-aaaa",
                                                                                    id: "mm-dd-aaaa",
                                                                                },
                                                                                {
                                                                                    label: "mm-dd-aa",
                                                                                    id: "mm-dd-aa",
                                                                                },
                                                                                {
                                                                                    label: "aaaa-mm-dd",
                                                                                    id: "aaaa-mm-dd",
                                                                                },
                                                                                {
                                                                                    label: "m/d/aaaa",
                                                                                    id: "m/d/aaaa",
                                                                                },
                                                                                {
                                                                                    label: "m/d/aa",
                                                                                    id: "m/d/aa",
                                                                                },
                                                                                {
                                                                                    label: "d/m/aaaa",
                                                                                    id: "d/m/aaaa",
                                                                                },
                                                                                {
                                                                                    label: "d/m/aa",
                                                                                    id: "d/m/aa",
                                                                                },
                                                                                {
                                                                                    label: "m-d-aaaa",
                                                                                    id: "m-d-aaaa",
                                                                                },
                                                                                {
                                                                                    label: "m-d-aa",
                                                                                    id: "m-d-aa",
                                                                                },
                                                                                {
                                                                                    label: "d-m-aaaa",
                                                                                    id: "d-m-aaaa",
                                                                                },
                                                                                {
                                                                                    label: "d-m-aa",
                                                                                    id: "d-m-aa",
                                                                                },
                                                                                {
                                                                                    label: "aaaa-mm-dd",
                                                                                    id: "aaaa-mm-dd",
                                                                                },
                                                                            ]
                                                                        : [
                                                                                {
                                                                                    label: "hh:mm",
                                                                                    id: "hh:mm",
                                                                                },
                                                                            ]
                                                                    }
                                                                    classNamePrefix="select"
                                                                    noOptionsMessage={() =>
                                                                        "Sin opciones"
                                                                    }
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) => (
                                                                        <div
                                                                            className="option d-flex"
                                                                            style={{
                                                                                fontSize:
                                                                                    "15px",
                                                                            }}
                                                                        >
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </div>
                                                                    )}
                                                                    getOptionValue={(
                                                                        option
                                                                    ) => option.id}
                                                                />
                                                                <p
                                                                    style={{
                                                                        fontSize: 11,
                                                                        color: "#6C757D",
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    Formato de la
                                                                    respuesta que se
                                                                    le solicta al
                                                                    usuario.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="col p-0 mt-4">
                                                        <label
                                                            htmlFor="expressionErrorMessage"
                                                            style={{
                                                                color: "#6C757D",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            Mensaje de error:{" "}
                                                        </label>
                                                        <div>
                                                            <textarea
                                                                className="form-control"
                                                                id="expressionErrorMessage"
                                                                name="expressionErrorMessage"
                                                                type="text"
                                                                style={{
                                                                    width: "100%",
                                                                    height: "80px",
                                                                    borderRadius:
                                                                        "4px",
                                                                }}
                                                                {...register("regular_expressions_error_message", {
                                                                    required: {
                                                                        value: selectedNode.data?.advanced?.regularExpressions?.active ? true : false,
                                                                        message:"Campo requerido.",
                                                                    },
                                                                    pattern: {
                                                                        value: /\S+/,
                                                                        message: "No puede ser solo espacios en blanco",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.regular_expressions_error_message && <Alert>* {errors.regular_expressions_error_message.message}</Alert>}
                                                            <p
                                                                style={{
                                                                    fontSize: 11,
                                                                    color: "#6C757D",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Mensaje que envía el
                                                                bot en si la respuesta no cumple con la expresión.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                            {!selectedNode?.data?.options?.length && (
                                <div className="row px-3">
                                    <div
                                        className="mr-2"
                                        style={{
                                            width: "120px",
                                            backgroundColor: "#00A884",
                                            borderRadius: "4px",
                                            color: "#fff",
                                            padding: "2px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <input
                                            type="file"
                                            name="image"
                                            id="images"
                                            accept="image/gif,image/jpeg,image/jpg,image/png"
                                            data-tooltip-id="my-tooltip" 
                                            data-tooltip-content="Subir imagen"
                                            style={{
                                                display: "none",
                                            }}
                                            onChange={(e) => onFileInput(e, "image")}
                                        />
                                        <label
                                            htmlFor="images"
                                            title="Imagenes"
                                            className="pointer d-flex m-0 py-1 pl-2"
                                        >
                                            <FaImages
                                                className="mr-2 icon-picture"
                                                style={{
                                                    fontSize: "20px",
                                                    color: "#00A884",
                                                }}
                                            />
                                            Imagen
                                        </label>
                                    </div>
                                    <div
                                        style={{
                                            width: "120px",
                                            backgroundColor: "#00A884",
                                            borderRadius: "4px",
                                            color: "#fff",
                                            padding: "2px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <input
                                            type="file"
                                            name="videos"
                                            id="videos"
                                            accept="video/*"
                                            style={{ display: "none" }}
                                            onChange={(e) => onFileInput(e, "video")}
                                            multiple
                                            data-tooltip-id="my-tooltip" 
                                            data-tooltip-content="Subir video"
                                        />
                                        <label
                                            htmlFor="videos"
                                            title="Videos"
                                            className="pointer d-flex m-0 py-1 pl-2"
                                        >
                                            <BsFillCameraVideoFill
                                                className="mr-2 icon-file-video"
                                                style={{
                                                    fontSize: "20px",
                                                    color: "#00A884",
                                                }}
                                            />
                                            Video
                                        </label>
                                    </div>
                                    <div
                                        className="mr-2 mt-2"
                                        style={{
                                            width: "120px",
                                            backgroundColor: "#00A884",
                                            borderRadius: "4px",
                                            color: "#fff",
                                            padding: "2px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <input
                                            type="file"
                                            name="document"
                                            id="documents"
                                            accept=".pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            style={{ display: "none" }}
                                            onChange={(e) => onFileInput(e, "document")}
                                            data-tooltip-id="my-tooltip" 
                                            data-tooltip-content="Subir documento"
                                        />
                                        <label
                                            htmlFor="documents"
                                            title="Documentos"
                                            className="pointer d-flex m-0 py-1"
                                        >
                                            <BsFillFileEarmarkTextFill
                                                className="mr-1 icon-doc"
                                                style={{
                                                    fontSize: "18px",
                                                    color: "#00A884",
                                                }}
                                            />
                                            Documento
                                        </label>
                                    </div>
                                    <div
                                        className="mt-2"
                                        style={{
                                            width: "120px",
                                            backgroundColor: "#00A884",
                                            borderRadius: "4px",
                                            color: "#fff",
                                            padding: "2px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <input
                                            type="file"
                                            name="audio"
                                            id="audios"
                                            accept="audio/*"
                                            style={{ display: "none" }}
                                            onChange={(e) => onFileInput(e, "audio")}
                                            multiple
                                            data-tooltip-id="my-tooltip" 
                                            data-tooltip-content="Subir audio"
                                        />
                                        <label
                                            htmlFor="audios"
                                            title="Audios"
                                            className="pointer d-flex m-0 pt-1 pl-2"
                                        >
                                            <FaHeadphones
                                                className="mr-2 icon-headphones"
                                                style={{
                                                    fontSize: "20px",
                                                    color: "#00A884",
                                                }}
                                            />
                                            Audio
                                        </label>
                                    </div>
                                    <p
                                        style={{
                                            fontSize: 11,
                                            color: "#6C757D",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Multimedia que puedes cargar en la respuesta.
                                    </p>

                                    {selectedNode.data?.file && (
                                        <>
                                            {renderMedia(selectedNode.data.file.type, selectedNode.data.file)}
                                            <button
                                                type="button"
                                                className="mt-3"
                                                style={{
                                                    backgroundColor: "#00A884",
                                                    border: 0,
                                                    borderRadius: "4px",
                                                    color: "#fff",
                                                    cursor: "pointer",
                                                    fontFamily: "Helvetica, Arial, sans-serif",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    height: "30px",
                                                    width: "100%",
                                                }}
                                                onClick={() => handleInputChange("file", null)}
                                            >
                                                <BsTrash3 className="mr-1" /> Eliminar Multimedia
                                            </button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        {!selectedNode?.data?.file && (
                            <>
                                <div className="col p-0 mt-2">
                                    <div>
                                        {selectedNode.data.options &&
                                            selectedNode.data.options.map(
                                                (option, index) => {
                                                    return (
                                                        <div
                                                            className="col p-0 mt-2"
                                                            key={index}
                                                        >
                                                            <div>
                                                                <div
                                                                    className={`d-flex ${
                                                                        selectedNode?.data
                                                                            ?.options
                                                                            ?.length > 3
                                                                            ? "d-flex align-items-center"
                                                                            : "align-items-end"
                                                                    }`}
                                                                >
                                                                    <div>
                                                                        <div>
                                                                            <label
                                                                                htmlFor={
                                                                                    option.name
                                                                                }
                                                                                style={{
                                                                                    color: "#6C757D",
                                                                                    fontWeight: 500,
                                                                                }}
                                                                            >
                                                                                Título:{" "}
                                                                            </label>
                                                                            <input
                                                                                className="form-control"
                                                                                id={
                                                                                    option.name
                                                                                }
                                                                                name={
                                                                                    option.name
                                                                                }
                                                                                type="text"
                                                                                style={{
                                                                                    width:
                                                                                        selectedNode
                                                                                            .data
                                                                                            .options
                                                                                            .length >
                                                                                        3
                                                                                            ? "95%"
                                                                                            : "100%",
                                                                                    height: "35px",
                                                                                }}
                                                                                value={option.label || ""}
                                                                                maxLength={selectedNode.data.options.length > 3 ? 24 : 20}
                                                                                onBlur={() => {
                                                                                    const currentOption = selectedNode.data.options[index];
                                                                                    if(!currentOption?.label) {
                                                                                        handleSelectedNode((prevInputValue) => {
                                                                                            prevInputValue.data.options[index].label = `Opción ${(index+1)}`;
                                                                                    
                                                                                            return {
                                                                                                ...prevInputValue,
                                                                                                data: {
                                                                                                    ...prevInputValue.data,
                                                                                                    options: prevInputValue.data.options
                                                                                                },
                                                                                            };
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    const { value } = e.target;
                                                                                
                                                                                    handleSelectedNode((prevInputValue) => {
                                                                                        const updatedOptions = [...prevInputValue.data.options];
                                                                                        const currentOption = updatedOptions[index];
                                                                                        const formattedId = `${selectedNode.id}-${updatedOptions.length + 1}-${value.toLowerCase()
                                                                                            .replace(/[^a-z0-9]+/g, '-')
                                                                                            .replace(/(^-|-$)/g, '')}`;
                                                                                    
                                                                                        currentOption.label = value;
                                                                                        currentOption.id = formattedId;
                                                                                
                                                                                        return {
                                                                                            ...prevInputValue,
                                                                                            data: {
                                                                                                ...prevInputValue.data,
                                                                                                options: updatedOptions,
                                                                                            },
                                                                                        };
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {selectedNode.data
                                                                            .options
                                                                            .length > 3 && (
                                                                            <div className="mt-2">
                                                                                <label
                                                                                    htmlFor={`${option.name}_description`}
                                                                                    style={{
                                                                                        color: "#6C757D",
                                                                                        fontWeight: 500,
                                                                                    }}
                                                                                >
                                                                                    Descripción
                                                                                    (opcional):{" "}
                                                                                </label>
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id={`${option.name}_description`}
                                                                                    name={`${option.name}_description`}
                                                                                    style={{
                                                                                        width:
                                                                                            selectedNode
                                                                                                .data
                                                                                                .options
                                                                                                .length >
                                                                                            3
                                                                                                ? "95%"
                                                                                                : "100%",
                                                                                        height: "35px",
                                                                                    }}
                                                                                    value={option.description || ""}
                                                                                    maxLength={76}
                                                                                    onChange={(e) => {
                                                                                        const { value } = e.target;
                                                                                    
                                                                                        handleSelectedNode((prevInputValue) => {
                                                                                            const updatedOptions = [...prevInputValue.data.options];
                                                                                            const currentOption = updatedOptions[index];
                                                                                            currentOption.description = value;
                                                                                    
                                                                                            return {
                                                                                                ...prevInputValue,
                                                                                                data: {
                                                                                                    ...prevInputValue.data,
                                                                                                    options: updatedOptions,
                                                                                                },
                                                                                            };
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <button
                                                                        className="text-danger border-0 bg-transparent d-block pointer"
                                                                        onClick={() => removeOptions(option, index)}
                                                                    >
                                                                        <BsTrash3
                                                                            style={{
                                                                                fontSize:
                                                                                    "18px",
                                                                            }}
                                                                        />
                                                                    </button>
                                                                </div>
                                                                <p
                                                                    style={{
                                                                        fontSize: 11,
                                                                        color: "#6C757D",
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    Mensaje que envía el
                                                                    cliente para activar
                                                                    la respuesta.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>

                                    <button
                                        type="button"
                                        style={{
                                            backgroundColor: "#00A884",
                                            border: 0,
                                            borderRadius: "4px",
                                            color: "#fff",
                                            cursor: "pointer",
                                            fontFamily: "Helvetica, Arial, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            height: "30px",
                                            width: "100%",
                                        }}
                                        onClick={() => handleOptions()}
                                    >
                                        <AiOutlineSelect className="mr-1" />
                                        Añadir botón interactivo
                                    </button>
                                    <p
                                        style={{
                                            fontSize: 11,
                                            color: "#6C757D",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Al ingresar más de tres botones se enviarán como
                                        lista.
                                    </p>
                                </div>
                                <div className="col p-0 mt-2">
                                    <div className="d-flex align-items-center">
                                        <input
                                            name="dynamic_buttons"
                                            id="dynamic_buttons"
                                            type="checkbox"
                                            value={
                                                selectedNode.data?.advanced
                                                    ?.dynamic_buttons || false
                                            }
                                            className="cmn-toggle cmn-toggle-round-flat"
                                            checked={selectedNode.data?.advanced?.dynamic_buttons || false}
                                            onChange={(e) =>
                                                handleInputChange("advanced.dynamic_buttons", e.target.checked)
                                            }
                                        />
                                        <label
                                            className="mr-2 mb-0"
                                            htmlFor="dynamic_buttons"
                                        ></label>

                                        <div
                                            style={{
                                                fontSize: 12,
                                                color: "#6C757D",
                                                fontWeight: 500,
                                            }}
                                        >
                                            Botones dinámicos
                                        </div>
                                    </div>
                                    {selectedNode.data?.advanced?.dynamic_buttons && (
                                        <div>
                                            <div className="mt-2">
                                                <label
                                                    htmlFor="dynamic_title"
                                                    style={{
                                                        color: "#6C757D",
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    Título:{" "}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="dynamic_title"
                                                    name="dynamic_title"
                                                    type="text"
                                                    {...register("dynamic_buttons_variable")}
                                                />
                                                <p
                                                    style={{
                                                        fontSize: 11,
                                                        color: "#6C757D",
                                                        fontWeight: 500,
                                                        margin: 0,
                                                    }}
                                                >
                                                    Permite generar botones interactivos de
                                                    forma dinámica a partir de una respuesta
                                                    (API).
                                                </p>
                                                <RichTreeView
                                                    sx={{ color: "#9fb4b9" }}
                                                    className="p-0"
                                                    items={listRequestParams}
                                                    onSelectedItemsChange={(event, ids) => {
                                                        if (ids !== "response") {
                                                            handleInputChange("advanced.dynamic_buttons_variable", ids);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <label
                                                    htmlFor="dynamic_description"
                                                    style={{
                                                        color: "#6C757D",
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    Descripción:{" "}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="dynamic_description"
                                                    name="dynamic_description"
                                                    type="text"
                                                    {...register("dynamic_buttons_variable_description")}
                                                />
                                                <p
                                                    style={{
                                                        fontSize: 11,
                                                        color: "#6C757D",
                                                        fontWeight: 500,
                                                        margin: 0,
                                                    }}
                                                >
                                                    La descripciónsolo solo se mostrará en
                                                    los botones si se generan mas de 3.
                                                </p>
                                                <RichTreeView
                                                    sx={{ color: "#9fb4b9" }}
                                                    className="p-0"
                                                    items={listRequestParams}
                                                    onSelectedItemsChange={(event, ids) => {
                                                        if (ids !== "response") {
                                                            handleInputChange(
                                                                "advanced.dynamic_buttons_variable_description",
                                                                ids
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        <div
                            className="col p-0 mt-3 panel-group collapsible"
                            id="user-collapsible"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading p-2"
                                    style={{
                                        border: "1px solid #4F4F4F",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <div
                                        id="title-aditionalDates"
                                        className="panel-title d-flex justify-content-between align-items-center pointer"
                                        onClick={() =>
                                            handleDropdown("dropdownAdvancedOptions")
                                        }
                                    >
                                        <span>Opciones avanzadas</span>
                                        {dropdownAdvancedOptions ? (
                                            <IoIosArrowDropup
                                                className="pointer mr-3"
                                                style={{ fontSize: "20px" }}
                                            />
                                        ) : (
                                            <IoIosArrowDropdown
                                                className="pointer mr-3"
                                                style={{ fontSize: "20px" }}
                                            />
                                        )}
                                    </div>
                                </div>

                                {dropdownAdvancedOptions ? (
                                    <div
                                        className="panel-collapse"
                                        style={{
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <div
                                            className="panel-body"
                                            style={{ paddingInline: 0 }}
                                        >
                                            {selectedNode.data?.first && (
                                                <>
                                                    <div className="col p-2 mt-2">
                                                        <label>
                                                            Terminos y condiciones:{" "}
                                                        </label>
                                                        <div className="su-control-group d-flex">
                                                            <Select
                                                                styles={
                                                                    isDark == "true"
                                                                        ? customStylesDark
                                                                        : customStyles
                                                                }
                                                                classNamePrefix="select"
                                                                className="form-control basic-multi-select"
                                                                placeholder="Seleccione..."
                                                                value={selectedNode?.data?.advanced?.isAcceptTerms || false}
                                                                name="isAcceptTerms"
                                                                onChange={(term) =>
                                                                    handleInputChange("advanced.isAcceptTerms", term)
                                                                }
                                                                options={[
                                                                    {
                                                                        label: "Si",
                                                                        id: true,
                                                                    },
                                                                    {
                                                                        label: "No",
                                                                        id: false,
                                                                    },
                                                                ]}
                                                                noOptionsMessage={() =>
                                                                    "Sin opciones"
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) => (
                                                                    <div
                                                                        className="option d-flex"
                                                                        style={{
                                                                            fontSize:
                                                                                "15px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </div>
                                                                )}
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                            />
                                                        </div>
                                                        <p
                                                            style={{
                                                                fontSize: 11,
                                                                color: "#6C757D",
                                                            }}
                                                        >
                                                            <strong>
                                                                Importante:
                                                            </strong>{" "}
                                                            Si el nodo requiere{" "}
                                                            <strong>
                                                                Aceptación de
                                                                terminos y
                                                                condiciones
                                                            </strong>
                                                            , la opción o botón que
                                                            indica que estos han
                                                            sido aceptados debe
                                                            contener de manera
                                                            obligatoria en el título
                                                            la palabra{" "}
                                                            <strong>Acepto</strong>{" "}
                                                            o{" "}
                                                            <strong>Accept</strong>.
                                                        </p>
                                                    </div>

                                                    <div className="col p-2">
                                                        <label>Campañas: </label>
                                                        <div className="su-control-group d-flex">
                                                            <Select
                                                                styles={
                                                                    isDark == "true"
                                                                        ? customStylesDark
                                                                        : customStyles
                                                                }
                                                                classNamePrefix="select"
                                                                className="form-control basic-multi-select"
                                                                placeholder="Seleccione..."
                                                                value={selectedNode?.data?.advanced?.campaign || false}
                                                                name="campaign"
                                                                onChange={(term) =>
                                                                    handleInputChange("advanced.campaign", term)
                                                                }
                                                                options={[
                                                                    {
                                                                        label: "Si",
                                                                        id: true,
                                                                    },
                                                                    {
                                                                        label: "No",
                                                                        id: false,
                                                                    },
                                                                ]}
                                                                noOptionsMessage={() =>
                                                                    "Sin opciones"
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) => (
                                                                    <div
                                                                        className="option d-flex"
                                                                        style={{
                                                                            fontSize:
                                                                                "15px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </div>
                                                                )}
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                            />
                                                        </div>
                                                        {/* LISTA DE BOTONES PARA CAMPAÑAS */}
                                                        {selectedNode?.data?.advanced?.campaign?.id == true && (
                                                            <div className="col p-0 mt-2">
                                                                <div>
                                                                    {selectedNode?.data?.advanced?.campaign_options?.map(
                                                                        (
                                                                            option,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    className="col p-0 mt-2"
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <label
                                                                                        htmlFor={`campaign-option-${index}`}
                                                                                        style={{
                                                                                            color: "#6C757D",
                                                                                            fontWeight: 500,
                                                                                        }}
                                                                                    >
                                                                                        Título:{" "}
                                                                                    </label>
                                                                                    <div>
                                                                                        <div className="d-flex">
                                                                                            <input
                                                                                                id={`campaign-option-${index}`}
                                                                                                name={`campaign-option-${index}`}
                                                                                                type="text"
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    height: "35px",
                                                                                                }}
                                                                                                value={option.label || ""}
                                                                                                onChange={(e) => {
                                                                                                    const { value } = e.target;
                                                                                                  
                                                                                                    handleSelectedNode((prevInputValue) => {
                                                                                                      const updatedCampaignOptions = [...prevInputValue.data.advanced.campaign_options];
                                                                                                      const option = updatedCampaignOptions[index];
                                                                                                      const formattedId = `${selectedNode.id}-campaign_option-${updatedCampaignOptions.length + 1}-${value.toLowerCase()
                                                                                                        .replace(/[^a-z0-9]+/g, '-')
                                                                                                        .replace(/(^-|-$)/g, '')}`;
                                                                                                  
                                                                                                      option.label = value;
                                                                                                      option.id = formattedId;
                                                                                                  
                                                                                                      return {
                                                                                                        ...prevInputValue,
                                                                                                        data: {
                                                                                                          ...prevInputValue.data,
                                                                                                          advanced: {
                                                                                                            ...prevInputValue.data.advanced,
                                                                                                            campaign_options: updatedCampaignOptions,
                                                                                                          },
                                                                                                        },
                                                                                                      };
                                                                                                    });
                                                                                                }}                                                                                                  
                                                                                            />
                                                                                            <button
                                                                                                className="text-danger border-0 bg-transparent d-block pointer"
                                                                                                onClick={() => {
                                                                                                    const newOptions = selectedNode?.data?.advanced?.campaign_options?.filter((_, i) => i !== index);
                                                                                                    handleInputChange("advanced.campaign_options", newOptions);
                                                                                                    const newNodes = nodes.filter((v) => v.id !== option.idNode);
                                                                                                    handleNodes(newNodes);
                                                                                                }}
                                                                                            >
                                                                                                <BsTrash3 style={{ fontSize: "18px" }} />
                                                                                            </button>
                                                                                        </div>
                                                                                        {!option.label ||
                                                                                            (option
                                                                                                .label
                                                                                                .length >
                                                                                                (selectedNode
                                                                                                    ?.data
                                                                                                    ?.advanced
                                                                                                    ?.campaign_options
                                                                                                    .length >
                                                                                                3
                                                                                                    ? 24
                                                                                                    : 20) && (
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize: 11,
                                                                                                        color: "#f70202",
                                                                                                        fontWeight: 500,
                                                                                                    }}
                                                                                                >
                                                                                                    {!option.label
                                                                                                        ? "Campo para el botón es obligatorio"
                                                                                                        : `El campo para el botón no debe ser mayor a ${
                                                                                                            selectedNode
                                                                                                                ?.data
                                                                                                                ?.advanced
                                                                                                                ?.campaign_options
                                                                                                                .length >
                                                                                                            3
                                                                                                                ? "24"
                                                                                                                : "20"
                                                                                                        } caracteres`}
                                                                                                </p>
                                                                                            ))}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>

                                                                <button
                                                                    type="button"
                                                                    className="mt-2"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#00A884",
                                                                        border: 0,
                                                                        borderRadius:
                                                                            "4px",
                                                                        color: "#fff",
                                                                        cursor: "pointer",
                                                                        fontFamily:
                                                                            "Helvetica, Arial, sans-serif",
                                                                        fontSize:
                                                                            "14px",
                                                                        fontWeight:
                                                                            "bold",
                                                                        height: "30px",
                                                                        width: "100%",
                                                                    }}
                                                                    onClick={() => {
                                                                        const length = selectedNode.data?.advanced?.campaign_options?.length || 0;
                                                                        const item = {
                                                                          label: 'Opción ' + (length + 1),
                                                                          id: `${selectedNode.id}-campaign_option-${length + 1}-Opción-${length + 1}`
                                                                        };
                                                                        
                                                                        const newCampaignOptions = [
                                                                          ...(selectedNode.data?.advanced?.campaign_options || []),
                                                                          item
                                                                        ];
              
                                                                        handleInputChange('advanced.campaign_options', newCampaignOptions);
                                                                    }}
                                                                >
                                                                    <AiOutlineSelect className="mr-1" />
                                                                    Añadir botón de
                                                                    campaña
                                                                </button>
                                                                <p
                                                                    style={{
                                                                        fontSize: 11,
                                                                        color: "#6C757D",
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    <strong>
                                                                        Importante:{" "}
                                                                    </strong>
                                                                    Estos botones
                                                                    solo se muestran
                                                                    en el
                                                                    constructor de
                                                                    bots como guía
                                                                    del flujo que
                                                                    sigue un bot si
                                                                    la respuesta
                                                                    viene a partir
                                                                    de una campaña,
                                                                    por ende los
                                                                    titulos de cada
                                                                    uno deben ser{" "}
                                                                    <strong>
                                                                        iguales
                                                                    </strong>{" "}
                                                                    a las respuesta
                                                                    que se esperan.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {!selectedNode.data?.first && (
                                                <div className="col p-2">
                                                    <label>Solicitud de nombre: </label>
                                                    <div className="su-control-group d-flex pb-3">
                                                    <Select
                                                        styles={isDark === 'true' ? customStylesDark : customStyles}
                                                        className="form-control basic-multi-select"
                                                        placeholder="Solicitar nombre"
                                                        value={selectedNode.data?.advanced?.request_name || false}
                                                        name="request_name"
                                                        onChange={(value) => handleInputChange("advanced.request_name", value)}
                                                        options={[
                                                        { label: 'Si', id: true },
                                                        { label: 'No', id: false }
                                                        ]}
                                                        classNamePrefix="select"
                                                        noOptionsMessage={() => "Sin opciones"}
                                                        getOptionLabel={(option) => (
                                                        <div className="option d-flex" style={{ fontSize: "15px" }}>
                                                            {option.label}
                                                        </div>
                                                        )}
                                                        getOptionValue={(option) => option.id}
                                                        isDisabled={validateNodeRequestName()}
                                                    />
                                                    </div>
                                                    <label>Confirmar nombre: </label>
                                                    <div className="su-control-group d-flex">
                                                    <Select
                                                        styles={isDark === 'true' ? customStylesDark : customStyles}
                                                        className="form-control basic-multi-select"
                                                        placeholder="Confirmar nombre"
                                                        value={selectedNode.data?.advanced?.confirm_name || false}
                                                        name="confirm_name"
                                                        onChange={(value) =>
                                                        handleInputChange("advanced.confirm_name", value)
                                                        }
                                                        options={[
                                                        { label: 'Si', id: true },
                                                        { label: 'No', id: false }
                                                        ]}
                                                        classNamePrefix="select"
                                                        noOptionsMessage={() => "Sin opciones"}
                                                        getOptionLabel={(option) => (
                                                        <div className="option d-flex" style={{ fontSize: "15px" }}>
                                                            {option.label}
                                                        </div>
                                                        )}
                                                        getOptionValue={(option) => option.id}
                                                        isDisabled={!validateNodeRequestName() || validateNodeConfirmName()}
                                                    />
                                                    </div>
                                                    <p style={{ fontSize: 11, color: '#6C757D' }}>
                                                    <strong>Importante:</strong> Si el nodo requiere <strong>Confirmar Nombre</strong>,
                                                    la opción o botón que indica que este ha sido confirmado debe contener de manera obligatoria
                                                    en el título la palabra <strong>Confirmar</strong> o <strong>Confirm</strong>.
                                                    </p>
                                                    <label>Editar nombre: </label>
                                                    <div className="su-control-group d-flex">
                                                    <Select
                                                        styles={isDark === 'true' ? customStylesDark : customStyles}
                                                        className="form-control basic-multi-select"
                                                        placeholder="Editar nombre"
                                                        value={selectedNode.data?.advanced?.edit_name || false}
                                                        name="edit_name"
                                                        onChange={(value) =>
                                                        handleInputChange("advanced.edit_name", value)
                                                        }
                                                        options={[
                                                        { label: 'Si', id: true },
                                                        { label: 'No', id: false }
                                                        ]}
                                                        classNamePrefix="select"
                                                        noOptionsMessage={() => "Sin opciones"}
                                                        getOptionLabel={(option) => (
                                                        <div className="option d-flex" style={{ fontSize: "15px" }}>
                                                            {option.label}
                                                        </div>
                                                        )}
                                                        getOptionValue={(option) => option.id}
                                                        isDisabled={!validateNodeRequestName() || !validateNodeConfirmName() || validateNodeEditName()}
                                                    />
                                                    </div>
                                                    <p style={{ fontSize: 11, color: '#6C757D' }}>
                                                    <strong>Importante:</strong> Si el nodo requiere <strong>Editar Nombre</strong>,
                                                    la opción o botón que indica que este será editado debe contener de manera obligatoria
                                                    en el título la palabra <strong>Editar</strong> o <strong>Edit</strong>.
                                                    </p>
                                                </div>
                                            )}
                                            {!selectedNode.data?.first && (
                                                <div className="col p-2">
                                                    <label>
                                                        Cerrar la conversación:{" "}
                                                    </label>
                                                    <div className="su-control-group d-flex">
                                                        <Select
                                                            styles={
                                                                isDark == "true"
                                                                    ? customStylesDark
                                                                    : customStyles
                                                            }
                                                            className="form-control basic-multi-select"
                                                            placeholder="Estado"
                                                            value={selectedNode.data?.advanced?.closeChatBot || false}
                                                            name="closeChatBot"
                                                            onChange={(value) =>
                                                                handleInputChange("advanced.closeChatBot", value)
                                                            }
                                                            options={[
                                                                {
                                                                    label: "Si",
                                                                    id: true,
                                                                },
                                                                {
                                                                    label: "No",
                                                                    id: false,
                                                                },
                                                            ]}
                                                            classNamePrefix="select"
                                                            noOptionsMessage={() =>
                                                                "Sin opciones"
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => (
                                                                <div
                                                                    className="option d-flex"
                                                                    style={{
                                                                        fontSize:
                                                                            "15px",
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </div>
                                                            )}
                                                            getOptionValue={(
                                                                option
                                                            ) => option.id}
                                                        />
                                                    </div>
                                                    <p
                                                        style={{
                                                            fontSize: 11,
                                                            color: "#6C757D",
                                                        }}
                                                    >
                                                        Estado para determinar si al
                                                        llegar a este nodo es el
                                                        último punto de la
                                                        conversación.
                                                    </p>
                                                </div>
                                            )}
                                            {!selectedNode.data?.first && (
                                                <div className="col p-2">
                                                    <label>Saltar nodo: </label>
                                                    <div className="su-control-group d-flex">
                                                        <Select
                                                            styles={
                                                                isDark == "true"
                                                                    ? customStylesDark
                                                                    : customStyles
                                                            }
                                                            className="form-control basic-multi-select"
                                                            value={selectedNode.data?.advanced?.nextFlow || false}
                                                            name="nextFlow"
                                                            onChange={(value) =>
                                                                handleInputChange("advanced.nextFlow", value)
                                                            }
                                                            options={[
                                                                {
                                                                    label: "Si",
                                                                    id: true,
                                                                },
                                                                {
                                                                    label: "No",
                                                                    id: false,
                                                                },
                                                            ]}
                                                            classNamePrefix="select"
                                                            noOptionsMessage={() =>
                                                                "Sin opciones"
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => (
                                                                <div
                                                                    className="option d-flex"
                                                                    style={{
                                                                        fontSize:
                                                                            "15px",
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </div>
                                                            )}
                                                            getOptionValue={(
                                                                option
                                                            ) => option.id}
                                                            isDisabled={
                                                                selectedNode.data
                                                                    ?.options.length
                                                            }
                                                        />
                                                    </div>
                                                    <p
                                                        style={{
                                                            fontSize: 11,
                                                            color: "#6C757D",
                                                        }}
                                                    >
                                                        Opción para determinar que
                                                        el siguiente nodo se ejecute
                                                        automaticamente.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-end mt-4"
                        style={{ gap: "8px" }}
                    >
                        <a
                            href="javascript:;"
                            className="btn btn-lg btn-default mr-1"
                            onClick={() => {
                                setTimeout(() => {
                                    handleSelectedNode(null);
                                    setDropdownAdvanceOptions(false);
                                    setDropdownRegularExpression(false);
                                }, 200);
                            }}
                        >
                            Cerrar
                        </a>

                        <button
                            style={{
                                width: "100px",
                                backgroundColor: "#00A884",
                                borderColor: "#00A884",
                                color: "#e9edef",
                                boxShadow: "2px 2px 0px 0px #00A884",
                            }}
                            className="btn btn-lg"
                            type="submit"
                        >
                            {loadingUpdate ? (
                                <FaSpinner
                                    className="fa-spin"
                                    style={{ fontSize: "15px" }}
                                />
                            ) : (
                                <span>Guardar</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>

            <ToastContainer 
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            />
        </>
    );
};

export default BasicAnswer;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;
