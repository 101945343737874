// Resources
import React, {useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import useAPI from "../../../../hooks/useAPI";
import usePage from "../../../../hooks/usePage";
import styledComponent from "styled-components";
import dayjs from "dayjs";

// Components
import Header from "../../../layout/Header";
import Modal from "../../../layout/Modal";
import Pagination from "../../../plugins/Pagination";
import Spinner from "../../../plugins/Spinner";
import {sendToast} from "../../../plugins/toastAll";
import Select from "react-select";

// Icons
import { BsSearch, BsPencilFill, BsTrash3 } from "react-icons/bs";
import { FaRobot } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { CiSettings } from "react-icons/ci";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";

const initialNodes = [
    {
      id: '1-basicAnswer',
      type: 'basicAnswer',
      data: { 
        title: 'Saludo Inicial',
        first: true,
        bot_response: "¡Hola! ¿Cómo puedo ayudarte hoy?",
        user_response: null,
        options: [
          {
            label: 'Conversar con agente',
            id: '1-basicAnswer-1-conversar-con-agente',
            name: 'agente',
          },
          {
            label: 'Continuar con Bot',
            id: '1-basicAnswer-2-continuar-con-bot',
            name: 'Bot',
          },
        ],
        file: null,
        saved: true,
        variables: null,
        color: '#00A884',
        advanced: {
          isAcceptTerms: null,
          campaign: null,
          campaign_options: [],
          request_name: null,
          confirm_name: null,
          edit_name: null,
        },
      },
      position: { x: 250, y: 5 }
    },
];

const List = ({
    handleSearch,
    handlEditChatBot,
    handleOpenNewBot,
    handleNewBot,
    deleteBot,
    getBots,
    validation,
    listBots,
    listInactivity,
    listCompanyScheduleOptions,
    listAttempts,
    listAreas,
    listAdvisers,
    listBotsTemplates,
    isDark,
    customStyles,
    customStylesDark,
    mode,
    handleModalBotAssistant
}) => {
    const { urlAPI_3, fetchWithAuth } = useAPI(); 
    const { page, setPage, byPage, resetPage } = usePage();
    const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({
        defaultValues: {
            inactivityMessages: null,
            controlMessage: null,
            waitingMessageSOS: null,
            listSOSMessages: null
        },
    });

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [configBot, setConfigBot] = useState(null);

    // ------> Selects, Radio, Checkbox
    const [transferSOSType, setTransferSOSType] = useState(false);
    const [inactivityStatus, setInactivityStatus] = useState(false);
    const [attemptsSOS, setAttemptsSOS] = useState(null);
    const [inactivityTime, setInactivityTime] = useState(null);
    const [inactivityTimeClose, setInactivityTimeClose] = useState(null);
    const [transferSOS, setTransferSOS] = useState(null);

    // ------> Modals
    const [selectedTemplateModal, setSelectedTemplateModal] = useState(false);
    const [configModal, setConfigModal] = useState(false);
    
    // ------> Dropdowns
    const [selectTimeInactivity, setSelectTimeInactivity] = useState(false);
    const [selectControlMessage, setSelectControlMessage] = useState(false);
    const [selectSOS, setSelectSOS] = useState(false);

    // Functions

    //# Function para manejar apertura de modal de configuraciones
    const openConfig = (bot) => {
        const objectData = {
            inactivityMessages: bot.inactivityMessages?.length ? bot.inactivityMessages[0]?.text?.body : null,
            companySchedule: listCompanyScheduleOptions.find(v => v.value == bot.companySchedule) || null,
            controlMessage: bot.controlMessage ?? null,
            waitingMessageSOS: bot.waitingMessageSOS ?? null,
            listSOSMessages: bot.listSOSMessages ?? null
        };
        setAttemptsSOS(bot.attemptsSOS ?? null);
        setInactivityStatus(bot.inactivityStatus || null);
        setInactivityTime(listInactivity.find(v => v.value == bot.inactivityTime) ?? null);
        setInactivityTimeClose(listInactivity.find(v => v.value == bot.inactivityTimeClose) ?? null);
        setTransferSOS(bot?.transferSOS?.agent_area ?? null);
        setTransferSOSType(bot?.transferSOS?.type ?? null);
        setConfigBot(bot);

        reset(objectData, { keepDefaultValues: true });
        setConfigModal(true);
    };

    //# Function para guardar configuración
    const handleBotConfig = async (values) => {
        
        let json = {
          ...configBot,
          inactivityTime: inactivityTime.value,
          inactivityTimeClose: inactivityTimeClose.value,
          inactivityMessages: values.inactivityMessages,
          companySchedule: values.companySchedule,
          controlMessage: values.controlMessage,
          inactivityStatus: inactivityStatus,
          attemptsSOS: attemptsSOS,
          waitingMessageSOS: values.waitingMessageSOS,
          transferSOS: {
            type: transferSOSType,
            agent_area: transferSOS
          },
          listSOSMessages: values.listSOSMessages
        };
    
        if(json.inactivityMessages){
          json.inactivityMessages = [{type: "text", text: {body: json.inactivityMessages}}];
        }
        
        setLoading(true);
        const url = urlAPI_3 + `chatBot/${json._id}`;
        const options = { method: "PUT", mode: "cors", body: JSON.stringify(json)};
        await fetchWithAuth(url, options);
        getBots(false);
        setSelectTimeInactivity(false);
        setSelectControlMessage(false);
        setSelectSOS(false);
        setLoading(false);
    };

    //# Function BotIsActive
    const handleBotIsActive = async (e, bot) => {
        let enabled = e.target.checked
        let json = {
            ...bot,
            enabled: enabled
        }

        const validate = listBots.find(v => v.enabled == true) 
        if(validate && enabled == true) {
            setListBots([])
            await getBots(true)
            sendToast({type: "info", message: "No se puede tener más de un Bot activo. Por favor desactiva el que tengas en uso para poder activar este."})
            return
        }

        setLoading(true);
        const url = urlAPI_3 + `chatBot/${json._id}`;
        const options = { method: "PUT", mode: "cors", body: JSON.stringify(json)};
        await fetchWithAuth(url, options);

        getBots(false);
        setLoading(false);
        sendToast({type: "info", message: "Estatus del Bot actualizado."})
    };
    
    //# Function para manejar cierre y aparetura de dropdowns
    const handleDropdown = (property) => {
        switch(property) {
            case 'selectTimeInactivity':
                setSelectTimeInactivity(!selectTimeInactivity);
                setSelectControlMessage(false);
                setSelectSOS(false);
                break;
            case 'selectSOS':
                setSelectSOS(!selectSOS);
                setSelectTimeInactivity(false);
                setSelectControlMessage(false);
                break;
            case 'selectControlMessage':
                setSelectControlMessage(!selectControlMessage);
                setSelectTimeInactivity(false);
                setSelectSOS(false);
                break;
          default:
            console.error(`Property ${property} not recognized`);
        }
    };

    useEffect(() => {
        if (search === "") {
          handleSearch(search);
        }
    }, [search]);

    useEffect(() => {
        setSelectTimeInactivity(false);
        setSelectControlMessage(false);
        setSelectSOS(false);
    }, [configModal]);

    useEffect(() => {
        if(mode == "create") {
            setSelectedTemplateModal(false);
        } 
    }, [mode])
    
    return (
        <>  
            {loading ? (
                <div className="content-spinner">
                <Spinner />
                </div>
            ) : null}

            <div className="m-auto container-fluid p-0 h-100">
                <div className="col-xs-9 heading">
                    <div className="mt-2">
                        <h1>Constructor de Bots</h1>
                        <input
                            type="button"
                            className="btn btn-default"
                            value="Nuevo Bot"
                            onClick={() => {
                                setSelectedTemplateModal(true)
                            }}
                        />
                    </div>
                    <Header />
                </div>

                <header>
                    <div style={{ float: "right" }}>
                        <form className="form-inline">
                            <div className="su-control my-1">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Búsqueda"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button
                                    className="btn btn-info"
                                    type="button"
                                    id="buttonSearchProductBack"
                                    style={{ height: "34px" }}
                                    onClick={handleSearch}
                                >
                                    <BsSearch />
                                </button>
                            </div>
                        </form>
                    </div>
                </header>

                <div className="container-fluid mt-3 px-0" style={{ height:"75%", overflowY:"auto", overflowX:"none" }}>
                    <table className="table table-striped table-campaigns">
                        <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                            <tr>
                                <th>Acciones</th>
                                <th>Activar/Desactivar</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Estado</th>
                                <th>Creación</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listBots.length > 0 && listBots.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((bot) => (
                                <tr key={bot._id}>
                                    <td>
                                        <div className="row justify-content-around">
                                            <div className="dropdown">
                                                <button
                                                    className="bg-transparent border-0"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <FaEllipsisVertical className="text-success f-24 pointer" />
                                                </button>
                                                <div
                                                    className="dropdown-menu p-2"
                                                    aria-labelledby="dropdownMenuButton"
                                                > 
                                                    <button
                                                        onClick={() => handlEditChatBot(bot)}
                                                        className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                        <BsPencilFill style={{ fontSize: "18px" }}/>
                                                        <span className="text-dark ml-2 f-14">
                                                            Editar
                                                        </span>
                                                    </button>
                                                    <button 
                                                        onClick={() => deleteBot(bot._id)}
                                                        className="text-danger border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                        <BsTrash3 style={{ fontSize: "18px" }}/>
                                                        <span className="text-dark ml-2 f-14">
                                                            Eliminar
                                                        </span>
                                                    </button>
                                                    <button 
                                                        className="text-muted border-0 bg-transparent d-block pointer mt-2" 
                                                        onClick={() => openConfig(bot)}
                                                    >
                                                        <div className="d-flex aling-items-center">
                                                            <CiSettings style={{ fontSize: "24px" }}/>
                                                            <span className="text-dark ml-2 f-14">
                                                                Configuración
                                                            </span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <input
                                                type="checkbox"
                                                value={bot.enabled}
                                                className="cmn-toggle cmn-toggle-round-flat"
                                                name={bot._id}
                                                id={bot._id}
                                                defaultChecked={bot.enabled}
                                                onChange={(e) => handleBotIsActive(e, bot)}
                                            />
                                            <label className="m-0" htmlFor={bot._id}></label>
                                        </div>
                                    </td>
                                    <td>
                                        {bot?.name}
                                    </td>
                                    <td>
                                        {
                                        bot?.description.length >= 35 ? 
                                            bot?.description.substr(0, 35) + "..."
                                            : bot?.description || "error"
                                        }
                                    </td>
                                    <td className="status-template">
                                        <p>
                                            <a
                                                href="javascript:;"
                                                className={
                                                bot.enabled
                                                    ? "green"
                                                    : "red"
                                                }
                                            >
                                                {bot.enabled ? 'ACTIVO' : 'INACTIVO'}
                                            </a>
                                        </p>
                                    </td>
                                    <td>
                                        Creada el{" "}
                                        {dayjs(bot.created)
                                        .locale("es")
                                        .format("DD MMMM YYYY")}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {validation && (
                        <div className="text-center mt-3">
                            <div className="empty">
                                <FaRobot style={{ fontSize: "55px" }}/>
                                <h1>No tienes Bots</h1>
                                <input
                                    type="button"
                                    className="btn btn-default"
                                    value="Agregar Nuevo"
                                    onClick={() => {
                                        setSelectedTemplateModal(true)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <footer>
                    <div className="row">
                        <div className="col-md-8"></div>

                        <div className="col-md-4">
                            <Pagination
                                totalData={listBots.length}
                                byPage={byPage}
                                setPage={setPage}
                            />
                        </div>
                    </div>
                </footer>
            </div>

            {/*----------MODALS----------*/}

            {/* MODAL CONFIG BOT*/}
            <Modal
                status={configModal}
                changeStatus={setConfigModal}
                title="Configuración"
                width={"450px"}
            >
                <div 
                    className="justify-content-center container-fluid"
                    style={{
                        maxHeight: "450px",
                        overflowY: "auto",
                    }}
                >
                    <form onSubmit={handleSubmit(handleBotConfig)} style={{margin: "0px"}}>
                        <div className="col p-0 mt-4">
                            <div className="panel-group collapsible" id="user-collapsible">
                                <div className="panel panel-default">
                                    <div 
                                        className="panel-heading p-2" 
                                        style={{
                                            border: "1px solid #4F4F4F",
                                            borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={() => handleDropdown('selectTimeInactivity')}>
                                            <span>
                                                Tiempo de inactividad
                                            </span>
                                            {selectTimeInactivity ?
                                                <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                                <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                            }
                                        </div>
                                    </div>

                                    {selectTimeInactivity ? (
                                        <div 
                                        className="panel-collapse pt-2" 
                                        style={{
                                            borderRadius: "4px"
                                        }}>
                                        <div className="panel-body" style={{paddingInline: 0}}>
                                            <div className="py-3 d-flex align-items-center justify-content-between">
                                                <label>Estado de inactividad:</label>
                                                <div style={{ marginLeft: "auto" }}>
                                                    <input
                                                        type="checkbox"
                                                        value={inactivityStatus || false}
                                                        className="cmn-toggle cmn-toggle-round-flat"
                                                        name={"inactivityStatus"}
                                                        id={"inactivityStatus"}
                                                        defaultChecked={inactivityStatus || false}
                                                        onChange={(e) => setInactivityStatus(e.target.checked)}
                                                    />
                                                    <label className="m-0" htmlFor={"inactivityStatus"}></label>
                                                </div>
                                            </div>
                                            <div className="py-3">
                                                <label>Tiempo para enviar mensaje de inactividad: </label>
                                                <div className="su-control-group d-flex">
                                                    <Select
                                                    styles={isDark == 'true' ? customStylesDark : customStyles}
                                                    className="form-control basic-multi-select"
                                                    placeholder="Seleccione..."
                                                    name="inactivity"
                                                    value={inactivityTime}
                                                    onChange={(option) => setInactivityTime(option)}
                                                    options={listInactivity}
                                                    classNamePrefix="select"
                                                    noOptionsMessage={() => "Sin opciones"}
                                                    getOptionLabel={(option) => (
                                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                                        {option.label} 
                                                        </div>
                                                    )}
                                                    getOptionValue={(option) => option.value}
                                                    />
                                                </div>
                                            </div>
                                            <div className="py-3">
                                                <label>Tiempo para cerrar la conversacion: </label>
                                                <div className="su-control-group d-flex">
                                                    <Select
                                                    styles={isDark == 'true' ? customStylesDark : customStyles}
                                                    className="form-control basic-multi-select"
                                                    placeholder="Seleccione..."
                                                    name="inactivity"
                                                    value={inactivityTimeClose}
                                                    onChange={(option) => {
                                                        if(option.value > inactivityTime.value) {
                                                            setInactivityTimeClose(option);
                                                        }
                                                    }}
                                                    options={listInactivity}
                                                    classNamePrefix="select"
                                                    noOptionsMessage={() => "Sin opciones"}
                                                    getOptionLabel={(option) => (
                                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                                        {option.label} 
                                                        </div>
                                                    )}
                                                    getOptionValue={(option) => option.value}
                                                    />
                                                </div>
                                                <p style={{fontSize: 11, fontWeight: 500}}>
                                                    Ten en cuenta que el tiempo seleccionado siempre debe ser 
                                                    <strong> MAYOR</strong> al <strong>Tiempo para enviar mensaje de inactividad</strong>
                                                </p>
                                            </div>
                                            <div className="col p-0">
                                                <div className="su-control">
                                                    <label htmlFor="companySchedule_message" style={{color: '#6C757D', fontWeight: 500}}>Mensaje de inactividad: </label>
                                                    <div className="su-control-group">
                                                        <textarea
                                                            className="form-control"
                                                            id="companySchedule_message"
                                                            name="companySchedule_message"
                                                            placeholder="Escribe el mensaje"
                                                            style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                                            {...register("inactivityMessages", {
                                                                required: {
                                                                value: true,
                                                                message: "Mensaje de inactividad es requerido.",
                                                                },
                                                                pattern: {
                                                                value: /\S+/,
                                                                message: "No puede ser solo espacios en blanco",
                                                                },
                                                            })}
                                                        />
                                                        {errors.inactivityMessages && <Alert>* {errors.inactivityMessages.message}</Alert>}
                                                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 500}}>
                                                            Mensaje de ausencia que se envía al cliente.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <div className="panel-group collapsible" id="user-collapsible">
                                <div className="panel panel-default">
                                    <div 
                                        className="panel-heading p-2" 
                                        style={{
                                        border: "1px solid #4F4F4F",
                                        borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={() => handleDropdown('selectControlMessage')}>
                                        <span>
                                            Mensaje de control
                                        </span>
                                        {selectControlMessage ?
                                            <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                            <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                        }
                                        </div>
                                    </div>

                                    {selectControlMessage ? (
                                        <div 
                                        className="panel-collapse pt-2" 
                                        style={{
                                            borderRadius: "4px"
                                        }}>
                                            <div className="panel-body" style={{paddingInline: 0}}>
                                            <div className="col p-0">
                                                <label htmlFor="controlMessage_message" style={{color: '#6C757D', fontWeight: 500}}>Mensaje de control: </label>
                                                <div>
                                                    <textarea
                                                        className="form-control"
                                                        id="controlMessage_message"
                                                        name="controlMessage_message"
                                                        placeholder="Escribe el mensaje"
                                                        style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                                        {...register("controlMessage", {
                                                            required: {
                                                            value: true,
                                                            message: "El Mensaje de control es requerido.",
                                                            },
                                                            pattern: {
                                                            value: /\S+/,
                                                            message: "No puede ser solo espacios en blanco",
                                                            },
                                                        })}
                                                    />
                                                    {errors.controlMessage && <Alert>* {errors.controlMessage.message}</Alert>}

                                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 500}}>
                                                        Mensaje de control que se envía al cliente si la respuesta u 
                                                        opción que seleccione o escriba no es la esperada por el Bot.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <div className="panel-group collapsible" id="user-collapsible">
                                <div className="panel panel-default">
                                    <div 
                                        className="panel-heading p-2" 
                                        style={{
                                            border: "1px solid #4F4F4F",
                                            borderRadius: "4px"
                                        }}
                                    >
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={() => handleDropdown('selectSOS')}>
                                            <span>
                                                Mensajes SOS
                                            </span>
                                            {selectSOS ?
                                                <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                                <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                            }
                                        </div>
                                    </div>

                                    {selectSOS ? (
                                        <div 
                                            className="panel-collapse pt-2" 
                                            style={{
                                                borderRadius: "4px"
                                            }}
                                        >
                                            <div className="panel-body" style={{paddingInline: 0}}>
                                                <div className="py-2">
                                                    <label htmlFor="list_sos_messages">Mensajes de ayuda: </label>
                                                    <div>
                                                        <textarea
                                                            className="form-control"
                                                            id="list_sos_messages"
                                                            name="list_sos_messages"
                                                            placeholder="Escribe el mensaje"
                                                            style={{ width: "100%", height: "90px", borderRadius: "4px" }}
                                                            {...register("listSOSMessages", {
                                                                pattern: {
                                                                value: /\S+/,
                                                                message: "No puede ser solo espacios en blanco",
                                                                },
                                                            })}
                                                        />
                                                        {errors.listSOSMessages && <Alert>* {errors.listSOSMessages.message}</Alert>}
                                                        <p style={{fontSize: 11, fontWeight: 500}}>
                                                            Este campo guardará las palabras como una lista de palabras de ayuda,
                                                            para poder lograr esto, se debe agregar una coma (,) luego de cada palabra, ejemplo
                                                            (Help, Ayuda, Agente, Agente).
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="py-2">
                                                    <label>Intentos para controlar ayuda: </label>
                                                    <div className="su-control-group d-flex">
                                                        <Select
                                                            styles={isDark == 'true' ? customStylesDark : customStyles}
                                                            className="form-control basic-multi-select"
                                                            placeholder="Seleccione..."
                                                            name="inactivity"
                                                            value={attemptsSOS}
                                                            onChange={(option) => setAttemptsSOS(option)}
                                                            options={listAttempts}
                                                            classNamePrefix="select"
                                                            noOptionsMessage={() => "Sin opciones"}
                                                            getOptionLabel={(option) => (
                                                                <div className="option d-flex" style={{fontSize: "15px"}}>
                                                                    {option.label} 
                                                                </div>
                                                            )}
                                                            getOptionValue={(option) => option.value}
                                                        />
                                                    </div>
                                                    <p style={{fontSize: 11, fontWeight: 500}}>
                                                        Este número de intentos se refiere a la cantidad de repeticiones
                                                        del mensaje de control de error, es decir según los intentos que se seleccionen, será 
                                                        el máximo de mensajes de control o error que se mostrarán y posteriormente realizár transferencia de ayuda.
                                                    </p>
                                                </div>
                                                <div className="py-2">
                                                    <label>Transferencia de ayuda: </label>
                                                    <div className="d-flex mb-2">
                                                        <div className="d-flex pointer mr-1">
                                                            <input
                                                                style={{accentColor: '#5BC0DE'}}
                                                                type="radio"
                                                                value="areas"
                                                                className="pointer"
                                                                checked={transferSOSType === "areas"}
                                                                onChange={(e) => {
                                                                    setTransferSOSType(e.target.value);
                                                                    setTransferSOS(null);
                                                                }}
                                                            />
                                                            <label
                                                                className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                                                style={{ padding: "5px 2px 5px" }}
                                                            >
                                                                Areas
                                                            </label>
                                                        </div>
                                                        <div className="d-flex pointer mr-1">
                                                            <input
                                                                style={{accentColor: '#5BC0DE'}}
                                                                type="radio"
                                                                value="agents"
                                                                className="pointer"
                                                                checked={transferSOSType === "agents"}
                                                                onChange={(e) => {
                                                                    setTransferSOSType(e.target.value);
                                                                    setTransferSOS(null);
                                                                }}
                                                            />
                                                            <label
                                                                className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                                                style={{ padding: "5px 2px 5px" }}
                                                            >
                                                                Agentes
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {transferSOSType == 'areas' && (
                                                        <div>
                                                            <label>Areas: </label>
                                                            <div className="su-control-group d-flex">
                                                                <Select
                                                                    styles={isDark == 'true' ? customStylesDark : customStyles}
                                                                    className="form-control basic-multi-select"
                                                                    placeholder="Seleccione..."
                                                                    name="areas"
                                                                    value={transferSOS}
                                                                    onChange={(area) => setTransferSOS(area)}
                                                                    options={listAreas}
                                                                    classNamePrefix="select"
                                                                    noOptionsMessage={() => "Sin opciones"}
                                                                    getOptionLabel={(option) => (
                                                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                                                            {option.label} 
                                                                        </div>
                                                                    )}
                                                                    getOptionValue={(option) => option.value}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {transferSOSType == 'agents' && (
                                                        <div>
                                                            <label>Agentes: </label>
                                                            <div className="su-control-group d-flex">
                                                                <Select
                                                                    styles={isDark == 'true' ? customStylesDark : customStyles}
                                                                    className="form-control basic-multi-select"
                                                                    placeholder="Seleccione..."
                                                                    name="agents"
                                                                    value={transferSOS}
                                                                    onChange={(agent) => setTransferSOS(agent)}
                                                                    options={listAdvisers}
                                                                    classNamePrefix="select"
                                                                    noOptionsMessage={() => "Sin opciones"}
                                                                    getOptionLabel={(option) => (
                                                                    <div className="option d-flex" style={{fontSize: "15px"}}>
                                                                        {option.label} 
                                                                    </div>
                                                                    )}
                                                                    getOptionValue={(option) => option.value}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="py-2">
                                                    <label>Mensajes de espera: </label>
                                                    <div>
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Escribe el mensaje"
                                                            style={{ width: "100%", height: "90px", borderRadius: "4px" }}
                                                            {...register("waitingMessageSOS", {
                                                                pattern: {
                                                                value: /\S+/,
                                                                message: "No puede ser solo espacios en blanco",
                                                                },
                                                            })}
                                                        />
                                                        {errors.waitingMessageSOS && <Alert>* {errors.waitingMessageSOS.message}</Alert>}
                                                        <p style={{fontSize: 11, fontWeight: 500}}>
                                                            Mensaje que se envía una vez realizada la transferenciade ayuda
                                                            para comunicar al usuario la acción que se esta realizando.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div 
                            className="row justify-content-end mt-3"
                            style={{paddingInline: "15px"}}
                        >
                            <a
                                href="javascript:;"
                                className="btn btn-lg btn-default mr-1"
                                onClick={() => {
                                    reset({ 
                                        inactivityMessages: null,
                                        controlMessage: null,
                                        waitingMessageSOS: null,
                                        listSOSMessages: null 
                                    });
                                    setConfigModal(false);
                                }}
                            >
                                Volver
                            </a>
                            <input
                                className="btn btn-lg btn-info"
                                value="Guardar"
                                name="submit"
                                id="submit"
                                type="submit"
                            />
                        </div>
                    </form>
                </div>
            </Modal>

            {/* MODAL TEMPLATES */}
            <Modal
                status={selectedTemplateModal}
                changeStatus={setSelectedTemplateModal}
                title="Escoge una plantilla para diseñar tu bot"
                width={"90%"}
            >
                <div className="content-list-contacts su-control mt-2 text-center" style={{overflow: "auto", height: "75vh"}}>
                    <div className="options-container">
                        <div key="01" className="option-item">
                            <div>
                                <img
                                    className="img-answer w-100"
                                    src="https://media-appsendingup-us-east-1.s3.amazonaws.com/f7e0bb5-76c4-0a1-dcf-e2b2c73b03/1730476539201.jpeg"
                                    style={{ margin: "0 auto" }}
                                />
                            </div>
                            <div>
                                <h5>Crear desde cero</h5>
                                <p>Si deseas crear un bot desde cero, selecciona esta opción.</p>
                            </div>
                            <div>
                                <input 
                                    type="button" 
                                    className="btn btn-default" 
                                    value="Crear bot🤖"
                                    onClick={() => {
                                        handleOpenNewBot(initialNodes);
                                        setSelectedTemplateModal(false);
                                    }}
                                />
                            </div>
                        </div> 
                        <div key="02" className="option-item">
                            <div>
                                <img
                                    className="img-answer w-100"
                                    src="https://media-sendingup-front.s3.amazonaws.com/624db33d-8fe1-482f-b3a5-cdff7e2993a4.png"
                                    style={{ margin: "0 auto" }}
                                />
                            </div>
                            <div>
                                <h5>Crear con IA</h5>
                                <p>Si deseas crear un bot con la IA, selecciona esta opción.</p>
                            </div>
                            <div>
                                <input 
                                    type="button" 
                                    className="btn btn-default" 
                                    value="Crear bot🤖"
                                    onClick={() => {
                                        handleModalBotAssistant(true);
                                        setSelectedTemplateModal(false);
                                    }}
                                />
                            </div>
                        </div> 
                        {listBotsTemplates && listBotsTemplates.map(item => (
                            <div key={item._id} className="option-item">
                                <div>
                                    <img
                                        className="img-answer w-100"
                                        src={item.image}
                                        style={{ margin: "0 auto" }}
                                    />
                                </div>
                                    <div>
                                    <h5>{item.name}</h5>
                                    <p>{item.description}</p>
                                </div>
                                <div>
                                    <input 
                                        type="button" 
                                        className="btn btn-default" 
                                        value="Crear bot🤖"
                                        onClick={() => handleNewBot(item)}
                                    />
                                </div>
                            </div> 
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default List;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;