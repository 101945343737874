import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import styledComponent from "styled-components";
import Select from "react-select";
import { ToastContainer } from "react-toastify";

import { BsTrash3 } from "react-icons/bs";
import { LuTags } from "react-icons/lu";
import { FaSpinner } from "react-icons/fa";

const Tags = ({
    selectedNode,
    handleSelectedNode,
    removeNode,
    nodes,
    edges,
    editFlow,
    updateBot,
    truncateText,
    customStyles,
    customStylesDark,
    isDark,
    listTags,
    watchSelectedNode
}) => {
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        user_response: null,
        dynamic_buttons_variable: null,
        dynamic_buttons_variable_description: null,
        regular_expressions_error_message: null,
    });

    const [errorTag, setErrorTag] = useState(false);

    // ------> Selects, Radios, Checkbox
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    // FUNCTIONS

    //# Function para manejar cambios de estados de campos
    const handleInputChange = (fieldPath, value) => {
        handleSelectedNode((prevInputValue) => {
            const updateNestedField = (obj = {}, path, val) => {
                const [key, ...rest] = path.split(".");
                return {
                    ...obj,
                    [key]:
                        rest.length > 0
                            ? updateNestedField(
                                  obj[key] || {},
                                  rest.join("."),
                                  val
                              )
                            : val,
                };
            };

            return {
                ...prevInputValue,
                data: updateNestedField(prevInputValue.data, fieldPath, value),
            };
        });
    };

    //# Function para guardar los datos
    const saveData = (values) => {
        if (!selectedNode.data.tags.length) {
            setErrorTag(true);
            return;
        };

        setLoadingUpdate(true);

        const data = {
            title: values.title,
            user_response: values.user_response,
        };

        handleSelectedNode((prevInputValue) => ({
            ...prevInputValue,
            data: {
                ...prevInputValue.data,
                ...data,
                saved: true,
            },
        }));
        setTimeout(async () => {
            await updateBot(editFlow, edges, nodes);
            setLoadingUpdate(false);
        }, 500);
    };

    //# Function para obtner data
    const getData = () => {
        let objectData = {
            title: selectedNode.data?.title ?? null,
            user_response: selectedNode.data?.user_response ?? null,
        };

        reset(objectData, { keepDefaultValues: true });
    };

    useEffect(() => {
        getData();
    }, [watchSelectedNode]);

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-between text-white"
                style={{
                    height: "50px",
                    padding: "10px",
                    borderBottom: "1px solid #CED4DA",
                    backgroundColor: "#5BC0DE",
                }}
            >
                <div className="d-flex align-items-center">
                    <LuTags className="mr-1" style={{ fontSize: "24px" }} />
                    <span style={{ fontSize: "22px", whiteSpace: "nowrap" }}>
                        {truncateText(selectedNode.data.title)}
                    </span>
                </div>
                <button
                    className="text-white border-0 bg-transparent d-block pointer mt-2"
                    onClick={() => {
                        removeNode(selectedNode.id);
                    }}
                >
                    <BsTrash3 style={{ fontSize: "18px" }} />
                </button>
            </div>
            <div
                id="content-list-chats"
                className="card-body"
                style={{ overflowY: "auto" }}
            >
                <form
                    onSubmit={handleSubmit(saveData)}
                    style={{ width: "100%" }}
                >
                    <div>
                        <div className="col p-0">
                            <label
                                htmlFor="title"
                                style={{ color: "#6C757D", fontWeight: 600 }}
                            >
                                Título:{" "}
                            </label>
                            <div>
                                <input
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    type="text"
                                    style={{ width: "100%", height: "35px" }}
                                    {...register("title", {
                                        required: {
                                            value: true,
                                            message: "Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message:
                                                "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.title && (
                                    <Alert>* {errors.title.message}</Alert>
                                )}
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="user_response"
                                style={{ color: "#6C757D", fontWeight: 600 }}
                            >
                                Respuesta del cliente:{" "}
                            </label>
                            <div>
                                <textarea
                                    className="form-control"
                                    id="user_response"
                                    name="user_response"
                                    type="text"
                                    style={{
                                        width: "100%",
                                        height: "50px",
                                        borderRadius: "4px",
                                    }}
                                    {...register("user_response", {
                                        required: {
                                            value: selectedNode.data.first
                                                ? false
                                                : true,
                                            message: "Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message:
                                                "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.user_response && (
                                    <Alert>
                                        * {errors.user_response.message}
                                    </Alert>
                                )}
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 600,
                                    }}
                                >
                                    Mensaje que envía el cliente para activar la
                                    respuesta.
                                </p>
                            </div>

                            <div
                                className="p-1"
                                style={{
                                    backgroundColor: "rgba(91, 192, 222, 0.3)",
                                    borderRadius: "4px",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: isDark == "true" ? "#b6b6b6" :  "#6C757D",
                                        fontWeight: 600,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Importante:
                                    </span>{" "}
                                    Con el signo * el etiquetado funcionará para
                                    cuaquier respuesta que de el cliente.
                                </p>
                            </div>

                            <div
                                className="p-1 mt-2"
                                style={{
                                    backgroundColor: "rgba(91, 192, 222, 0.3)",
                                    borderRadius: "4px",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: isDark == "true" ? "#b6b6b6" :  "#6C757D",
                                        fontWeight: 600,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Importante:
                                    </span>{" "}
                                    Los nodos se pueden activar con multiples
                                    respuestas según esten configuradas, para
                                    ello debe tener el siguiente formato:
                                    Respuesta 1 / Respuesta 2 / Respuesta 3
                                    configurado en dicho apartado.
                                </p>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label>Etiquetas: </label>
                            <div className="su-control-group d-flex">
                                <Select
                                    styles={
                                        isDark == "true"
                                            ? customStylesDark
                                            : customStyles
                                    }
                                    className="form-control basic-multi-select"
                                    isMulti
                                    placeholder="Seleccione..."
                                    value={selectedNode.data.tags}
                                    name="tags"
                                    onChange={(tag) =>{
                                        handleInputChange("tags", tag);
                                        setErrorTag(false);
                                    }}
                                    options={listTags}
                                    classNamePrefix="select"
                                    noOptionsMessage={() => "Sin opciones"}
                                    getOptionLabel={(option) => (
                                        <div
                                            className="option d-flex"
                                            style={{ fontSize: "15px" }}
                                        >
                                            {option.title}
                                        </div>
                                    )}
                                    getOptionValue={(option) => option.title}
                                />
                            </div>
                        </div>
                        {errorTag && (
                            <Alert>* Campo requerido</Alert>
                        )}
                    </div>
                    <div
                        className="d-flex justify-content-end mt-4"
                        style={{ gap: "8px" }}
                    >
                        <a
                            href="javascript:;"
                            className="btn btn-lg btn-default mr-1"
                            onClick={() => {
                                setTimeout(() => {
                                    handleSelectedNode(null);
                                }, 200);
                            }}
                        >
                            Cerrar
                        </a>
                        <button
                            style={{
                                width: "100px",
                                backgroundColor: "#5BC0DE",
                                borderColor: "#5BC0DE",
                                color: "#e9edef",
                                boxShadow: "2px 2px 0px 0px #5BC0DE",
                            }}
                            className="btn btn-lg"
                            type="submit"
                        >
                            {loadingUpdate ? (
                                <FaSpinner
                                    className="fa-spin"
                                    style={{ fontSize: "15px" }}
                                />
                            ) : (
                                <span>Guardar</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={
                    JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"
                }
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            />
        </>
    );
};

export default Tags;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;
