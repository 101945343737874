import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import Modal from "../layout/Modal";
import {
  ReactFlow,
  Background,
  BackgroundVariant,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  reconnectEdge,
  MarkerType,
  MiniMap,
  Controls,
} from 'reactflow';
import 'reactflow/dist/style.css';
import useAPI from "../../hooks/useAPI";
import Select, { components } from "react-select";
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json'; 
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-github_dark';
import { RichTreeView } from '@mui/x-tree-view';
import { Dialog, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import styledComponent from "styled-components";
import Countries from "../plugins/Countries.json";
import { Tooltip } from "react-tooltip";

import { BsPencilFill, BsTrash3, BsDatabaseFillAdd } from "react-icons/bs";
import { FaAngleLeft, FaBrain, FaHistory, FaWhatsapp } from "react-icons/fa";
import { FiMessageSquare } from "react-icons/fi";
import { LuTags, LuListTree } from "react-icons/lu";
import { MdOutlineSupportAgent, MdCleaningServices } from "react-icons/md";
import { HiOutlineSave } from "react-icons/hi";
import { TiFlowMerge } from "react-icons/ti";
import { BiTransfer } from "react-icons/bi";
import { TbHeartRateMonitor } from "react-icons/tb";
import { IoMdAdd, IoIosSettings } from "react-icons/io";
import { VscVariableGroup } from "react-icons/vsc";
import { IoClose } from "react-icons/io5";
import { FaSquareShareNodes } from "react-icons/fa6";

import BasicAnswer from "./BotsContent/NodeTypes/BasicAnswer";
import TransferAgent from "./BotsContent/NodeTypes/TransferAgent";
import Tags from "./BotsContent/NodeTypes/Tags";
import AssitantAI from "./BotsContent/NodeTypes/AssitantAI";
import SaveAnswer from "./BotsContent/NodeTypes/SaveAnswer";
import FlowsWhatsApp from "./BotsContent/NodeTypes/FlowsWhatsApp";
import QueryApi from "./BotsContent/NodeTypes/QueryApi";
import {questionToast, sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

import List from "./BotsContent/Others/List";
import Preview from "./BotsContent/Others/Preview";
import ErrorMonitor from "./BotsContent/Others/ErrorMonitor";
import HistoryVersions from "./BotsContent/Others/HistoryVersions"
import RemoveStatuses from "./BotsContent/Others/RemoveStatuses"
import ViewBasicAnswer from "./BotsContent/Others/Nodes/BasicAnswer"
import ViewTransferAgent from "./BotsContent/Others/Nodes/TransferAgent"
import ViewTags from "./BotsContent/Others/Nodes/Tags"
import ViewSaveAnswer from "./BotsContent/Others/Nodes/SaveAnswer"
import ViewAssistant from "./BotsContent/Others/Nodes/Assistant"
import ViewFlowsWhatsApp from "./BotsContent/Others/Nodes/FlowsWhatsApp"
import ViewQueryApi from "./BotsContent/Others/Nodes/QueryApi"

const nodeTypes = { 
  basicAnswer: BasicAnswer, 
  transferAgent: TransferAgent,
  tags: Tags,
  assistantAI: AssitantAI,
  saveAnswer: SaveAnswer,
  flowsWhatsApp: FlowsWhatsApp,
  queryApi: QueryApi
};

const nodeTypesColors = {
  basicAnswer: '#00A884', 
  transferAgent: '#F56F89',
  tags: '#5BC0DE',
  assistantAI: '#A7ADA7',
  saveAnswer: '#D89C7E',
  flowsWhatsApp: '#B85C7C',
  queryApi: '#7ECCD8',
}

const getId = () => {
  return makeRandomId(10);
};
const makeRandomId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const listCompanyScheduleOptions = [
  {
    label: "24 Horas",
    value: "24h"
  },
  {
    label: "Horario comercial",
    value: "companySchedule"
  }
]

const Flow = () => {

  //# Usos
  const { urlAPI_1, urlAPI_2, urlAPI_3, fetchWithAuth } = useAPI(); 
  const [isDark, setIsDark] = useState(false);
    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm({});

  //# Estados de plugins (Modales, Spinner...)
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [mode, setMode] = useState("list");
  const [modalHeaders, setModalHeaders] = useState(false);
  const [modalRequestParams, setModalRequestParams] = useState(false);
  const [modalRequestParamsBody, setModalRequestParamsBody] = useState(false);
  const [modalResponseParams, setModalResponseParams] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [variablesModal, setVariablesModal] = useState(false);
  const [variablesOptionsModal, setVariablesOptionsModal] = useState(false);
  const [indexOptionResToNode, setIndexOptionResToNode] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorElConditionsModel, setAnchorElConditionsModel] = useState(null);
  const [responseToNodeModal, setResponseToNodeModal] = useState(false);
  const [typeInput, setTypeInput] = useState("");
  const [typeInputValue, setTypeInputValue] = useState("");
  const [editName, setEditName] = useState("");
  const [showEditName, setShowEditName] = useState(false);
  const [showModalCreateTemplate, setShowModalCreateTemplate] = useState(false);
  const [modalConnectionTest, setModalConnectionTest] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [errorMonitoring, setErrorMonitoring] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedLog, setSelectedLog] = useState(false);
  const [animateClass, setAnimateClass] = useState('');
  const [triggersDuplicateCount, setTriggersDuplicateCount] = useState(0);
  const [transitionsDuplicateCount, setTransitionsDuplicateCount] = useState(0);
  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [showRemoveStatusesModal, setShowRemoveStatusesModal] = useState(false);
  const [showNodes, setShowNodes] = useState(true);
  const [modalBotAssistant, setModalBotAssistant] = useState(false);
  const [watchSelectedNode, setWatchSelectedNode] = useState(null);

  //# Estados del Modulo
  const [listBotsTemplates, setListBotsTemplates] = useState([]);
  const [listBots, setListBots] = useState([]);
  const [listLogs, setListLogs] = useState([]);
  const [nameBot, setNameBot] = useState("")
  const [descriptionBot, setDescriptionBot] = useState("")
  const [listAreas, setListAreas] = useState([]);
  const [listAdvisers, setListAdvisers] = useState([]);
  const [listTags, setListTags] = useState([]);
  const [listAssistants, setListAssistants] = useState([]);
  const [correspondence, setCorrespondence] = useState([]);
  const [listRequestParams, setListRequestParams] = useState([]);
  const [listOptionsResToNode, setListOptionsResToNode] = useState([]);
  const [flows, setFlows] = useState([]);
  const [listInactivity, setListInactivity] = useState([]);
  const [listAttempts, setListAttempts] = useState([]);
  const [listDisconnectedNodes, setListDisconnectedNodes] = useState([]);
  const [listDisconnectedOptions, setListDisconnectedOptions] = useState([]);
  const [listNodesWithErrorInFields, setListNodesWithErrorInFields] = useState([]);
  const prevNodesWithErrors = useRef([]);
  const [listOptionsOperators, setListOptionsOperators] = useState([]);

  // FLUJO
  const { screenToFlowPosition,fitBounds } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const memoizedNodeTypes = useMemo(() => nodeTypes, []);
  const reconnectDone = useRef(true);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [headerParams, setHeaderParams] = useState({});
  const [requestParams, setRequestParams] = useState({});
  const [requestParamsBody, setRequestParamsBody] = useState({});
  const [responseParams, setResponseParams] = useState({});
  const [requestResponse, setRequestResponse] = useState({});
  const [editFlow, setEditFlow] = useState({});
  const [connectionTestData, setConnectionTestData] = useState({
    headers: [],
    params: [],
    data: []
  });
  const [listToNodeData, setListToNodeData] = useState({
    options: [],
    description: "",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [cursorPosition, setCursorPosition] = useState(0);

  // #--> FLUJO
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onConnect = (params) => {
      
    let color = ""  
    if(params.source.includes('basicAnswer')) {
      color = nodeTypesColors['basicAnswer']
    }
    if(params.source.includes('transferAgent')) {
      color = nodeTypesColors['transferAgent']
    }
    if(params.source.includes('tags')) {
      color = nodeTypesColors['tags']
    }
    if(params.source.includes('assistantAI')) {
      color = nodeTypesColors['assistantAI']
    }
    if(params.source.includes('saveAnswer')) {
      color = nodeTypesColors['saveAnswer']
    }
    if(params.source.includes('flowsWhatsApp')) {
      color = nodeTypesColors['flowsWhatsApp']
    }
    if(params.source.includes('queryApi')) {
      color = nodeTypesColors['queryApi']
    }

    let edgeRetunr = false
    if(params.targetHandle.includes('target-return')) {
      edgeRetunr = true
    }
    
    let newNodes = nodes
    if(!edgeRetunr) {
      const result = setDataFromChildToParent(params);
      newNodes = result
    }
    
    const newEdge = {
      ...params,
      type: 'smoothstep',
      animated: edgeRetunr,
      label: validateTransitionsLength(params.source),
      labelStyle: { fontSize: 8, fontWeight: 700, fill: '#ffffff'}, 
      labelBgStyle: { fill: color },
      labelBgPadding: [4, 2], 
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: color,
      },
      style: {
        strokeWidth: 2,
        stroke: color,
      },
      zIndex: edgeRetunr ? 0 : 100
    };    

    const updatedEdges = addEdge(newEdge, edges);
    setEdges(updatedEdges);

    setTimeout(async() => {
      await updateBot(editFlow, updatedEdges, newNodes);
    }, 1000)
  }

  const onDrop = (event) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/reactflow');

    // check if the dropped element is valid
    if (typeof type === 'undefined' || !type) {
      return;
    }

    let newNode
    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });
    if(type == 'BasicAnswer') {
      
      newNode = {
        id: getId() + "-basicAnswer",
        type: 'basicAnswer',
        data: { 
          title: 'Respues Básica',
          bot_response: "",
          user_response: null,
          options: [],
          file: null,
          variables: null,
          saved: false,
          advanced: {
            request_name: null,
            confirm_name: null,
            edit_name: null,
            dynamic_buttons: false,
            dynamic_buttons_variable: null,
            dynamic_buttons_variable_description: null,
            closeChatBot: null,
            nextFlow: null,
            regularExpressions: {
              active: false,
              type: null,
              format: null,
              errorMessage: null
            } 
          },
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'TransferAgent') {
      
      newNode = {
        id: getId() + "-transferAgent",
        type: 'transferAgent',
        data: { 
          title: 'Transferir Agente/Area',
          first: false,
          bot_response: "",
          user_response: null,
          agent_area: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'Tags') {
      
      newNode = {
        id: getId() + "-tags",
        type: 'tags',
        data: { 
          title: 'Etiquetar',
          first: false,
          user_response: null,
          tags: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'AssitantAI') {
      
      newNode = {
        id: getId() + "-assistantAI",
        type: 'assistantAI',
        data: { 
          title: 'Asistente IA',
          user_response: null,
          assitant: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'SaveAnswer') {
      
      newNode = {
        id: getId() + "-saveAnswer",
        type: 'saveAnswer',
        data: { 
          title: 'Guardar Respuesta',
          user_response: '*',
          variable: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'FlowsWhatsApp') {
      
      newNode = {
        id: getId() + "-flowsWhatsApp",
        type: 'flowsWhatsApp',
        data: { 
          title: 'Flujo de WhatsApp',
          user_response: null,
          flow: null,
          flow_data: null,
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }
    if(type == 'QueryApi') {
      
      newNode = {
        id: getId() + "-queryApi",
        type: 'queryApi',
        data: { 
          title: 'Consulta API',
          user_response: null,
          succes: 'Exito',
          failed: 'Fallo',
          url_api: null,
          auth: null,
          protocol: null,
          user: null,
          password: null,
          headers: [],
          requestParams: [],
          requestParamsBody: [],
          responseParams: [],
          variables: null,
          saved: false,
          onRemove: (value) => removeNode(value)
        },
        position,
      };
    }


    createLogs({nodes: nodes, edges: edges, flows: editFlow.flows, id: editFlow._id});
    setNodes((nds) => [...nds, newNode]);
    let newNodes = [...nodes, newNode]
    setTimeout(() => {
      addNewNode(editFlow, newNodes, newNode)
    }, 500);
  }

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  const onReconnectStart = () => {
    reconnectDone.current = false;
  }

  const onReconnect = (oldEdge, newConnection) => {
    reconnectDone.current = true;
    let reconnect = reconnectEdge(oldEdge, newConnection, edges)
    setEdges(reconnect);

    setTimeout(async() => {
      await updateBot(editFlow, reconnect, nodes);
    }, 500)
  }

  const onReconnectEnd = (_, edge) => {
    if (!reconnectDone.current) {
      let newEdges = edges.filter((e) => e.id !== edge.id) 
      setEdges(newEdges);
      const newNodes = removeDataFromChildToParent(edge);
      
      setTimeout(async() => {
        await updateBot(editFlow, newEdges, newNodes);
      }, 500)
    }

    reconnectDone.current = true;
  }

  const handleNodeClick = (event, node) => {
    if(!node.parentId){
      setPosition({
        x: event.clientX,
        y: event.clientY,
      });
      setSelectedNode(node);
      setWatchSelectedNode(node.id);
    }
  };

  const handleEdgeClick = (event , edge) => {
    setAnchorElConditionsModel(event.currentTarget);
    setSelectedEdge(edge);
    setShowConditionsModal(true);
  };

  const onEmojiClick = (event, emojiObject) => {
    setSelectedNode((prevInputValue) => {
      const botResponse = prevInputValue.data.bot_response ?? "";
      const updatedResponse =
        botResponse.slice(0, cursorPosition) +
        emojiObject.emoji +
        botResponse.slice(cursorPosition);

      return {
        ...prevInputValue,
        data: {
          ...prevInputValue.data,
          bot_response: updatedResponse,
        },
      };
    });
  };

  const setDataFromChildToParent = (params) => {
    if (!params || !params.sourceHandle || !params.target) {
      console.error('Invalid parameters', params);
      return nodes;
    }
  
    let sHandle = params.sourceHandle.replace(/source-/, '').replace(/-\d+$/, '');
  
    const findNodeById = (nodes, id) => {
      for (let node of nodes) {
        if (node.data?.options) {
          let optionFind = node.data.options.find(option => option.id === id);
          if (optionFind) {
            return optionFind.label;
          }
        }
      }
      return null;
    };
  
    const optionLabel = findNodeById(nodes, sHandle);
  
    if (optionLabel) {
      const newNodes = nodes.map((prevNode) => {
        if (prevNode.id === params.target) {
          const currentResponse = prevNode.data.user_response || '';
          const cleanedResponse = currentResponse.startsWith('*') 
                                  ? currentResponse.substring(1).trim() 
                                  : currentResponse;
          const optionExists = cleanedResponse.split(' / ').includes(optionLabel);
          
          let newResponse = cleanedResponse
            ? cleanedResponse + ' / ' + optionLabel
            : optionLabel;
  
          newResponse = newResponse.replace(/\/\s*\/+/g, '/'); // Eliminar barras dobles
  
          return optionExists
            ? prevNode
            : {
                ...prevNode,
                data: {
                  ...prevNode.data,
                  user_response: newResponse,
                },
              };
        }
        return prevNode;
      });
      setNodes(newNodes);    
      return newNodes;
    } else {
      console.warn(`No matching option found for handle: ${sHandle}`);
      return nodes;
    }
  };    

  const removeDataFromChildToParent = (params) => {
    if (!params || !params.sourceHandle || !params.target) {
      console.error('Invalid parameters', params);
      return;
    }
  
    let sHandle = params.sourceHandle.replace(/source-/, '').replace(/-\d+$/, '');
  
    const findNodeById = (nodes, id) => {
      for (let node of nodes) {
        if (node.data?.options) {
          let optionFind = node.data.options.find(option => option.id === id);
          if (optionFind) {
            return optionFind.label;
          }
        }
      }
      return null;
    };
  
    const optionLabel = findNodeById(nodes, sHandle);
  
    if (optionLabel) {
      const newNodes = nodes.map((prevNode) => {
        if (prevNode.id === params.target) {
          let currentResponse = prevNode.data.user_response || '';
  
          // Crear una expresión regular que busque el texto y cualquier barra precedente
          const regex = new RegExp(`\\s*/?\\s*\\b${optionLabel}\\b`, 'g');
          const cleanedResponse = currentResponse.replace(regex, '').trim();
          
          // Limpiar las barras redundantes
          const updatedResponse = cleanedResponse.replace(/\s*\/\s*/g, ' / ').replace(/^\s*\/\s*|\s*\/\s*$/g, '').trim();
  
          return {
            ...prevNode,
            data: {
              ...prevNode.data,
              user_response: updatedResponse,
            },
          };
        }
        return prevNode;
      });
      setNodes(newNodes);    
      return newNodes;    
    } else {
      console.warn(`No matching option found for handle: ${sHandle}`);
      return nodes;
    }
  };   

  const validateTransitionsLength = (source) => {
    const filter = edges.filter(v => v.source == source);
    return filter.length + 1;
  };

  // CRUD
  //#--> Listar plantillas de bots
  const getBotsTemplates = async () => {
    try {
      const url = urlAPI_3 + 'chatBotTemplates';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
        console.log("result----result----result----result---->", result.data);
      if(result.length <= 0) {
        console.log("no hay data de plantillas ❌");
        setListBotsTemplates([]);
        // setValidation(true);
      } else {
        console.log("si hay lista de plantillas✔");
        setListBotsTemplates(result.data);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
  //#--> Listar
  const getBots = async (loader) => {
    try {
      if(loader) {
        setLoading(true);
      }
      const url = urlAPI_3 + 'chatBot';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(result.length <= 0) {
        console.log("no hay data");
        setListBots([]);
        setValidation(true);
      } else {
        console.log("si hay lista");
        setListBots(result);
        setValidation(false);
      }
      if(loader) {
        setLoading(false);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }finally{
      setLoading(false);
    }
  };
  //#--> Crear Bot
  const createBot = async (values) => {
    console.log(values, 'values')
    try {
      setLoading(true);

      setModalCreate(false);
      setMode("create");
      

      let dataBot = await buildJson("create", edges, nodes)

      if(values) {
        setNameBot(values.nameBot);
        setDescriptionBot(values.descriptionBot);
        if(!dataBot.name) {
          dataBot.name = values.nameBot
          dataBot.description = values.descriptionBot
        }
      }

      const url = urlAPI_3 + "chatBot";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(dataBot)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setEditFlow(result)

      reset();
      await getBots();
      
      setLoading(false);
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    }
  };
  //#--> Agregar Nuevo nodo 
  const addNewNode = async (data, newNodes, newNode) => {

    setSelectedNode(newNode);
    data.nodes = newNodes
    const url = urlAPI_3 + `chatBot/${data._id}`;
    const options = { method: "PUT", mode: "cors", body: JSON.stringify(data)};
    await fetchWithAuth(url, options);

    getBots(false);
  }
  //#--> Remover un nodo
  const removeNode = async (id) => {
    // Swal.fire({
    //   title: "¿Estas seguro que deseas eliminar este nodo?",
    //   text: "No se podrá revertir esta acción.",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Confirmar",
    //   cancelButtonText: "Cancelar",
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    //     setLoading(true);
    //     const logsObject = {
    //       id: editFlow._id,
    //       nodes: nodes,
    //       edges: edges,
    //       flows: editFlow.flows
    //     }
    //     const newNodes = nodes.filter(v => v.id !== id);
    //     const newEdges = edges.filter((e) => e.source !== id && e.target !== id) 
    //     setNodes(newNodes);
    //     setEdges(newEdges);
    //     setHasAssistantAi(false);
    //     setTimeout(async() => {
    //       await updateBot(editFlow, newEdges, newNodes);
    //       setSelectedNode(null);
    //       await getBots(false);
    //       setLoading(false);
    //       await createLogs(logsObject);
    //     }, 500)
    //   }
    // });

    const data = {
      isFetch: false,
      fetch: {},
      title: "¿Estas seguro que deseas eliminar este nodo?",
      text: "No se podrá revertir esta acción.",
      icon: "warning",
      buttons: {
        success: "Confirmar",
        cancel: "Cancelar"
      }        
    }
    console.log("Datos enviados a questionToast:", data);
    const result = await questionToast(data, setLoading)
    
    console.log("Result of removeNode:", result);
    setLoading(true);
        
    const logsObject = {
      id: editFlow._id,
      nodes: nodes,
      edges: edges,
      flows: editFlow.flows
    }
    const newNodes = nodes.filter(v => v.id !== id);
    const newEdges = edges.filter((e) => e.source !== id && e.target !== id) 
    setNodes(newNodes);
    setEdges(newEdges);

    setTimeout(async() => {
      await updateBot(editFlow, newEdges, newNodes);

      setSelectedNode(null);
      await getBots(false);
      setLoading(false);
      await createLogs(logsObject);
    }, 500)
  };
  //#--> Actualizar BOT
  const updateBot = async (data, newEdges, newNodes) => {
    try {

      const dataBot = await buildJson("update", newEdges, newNodes)

      data.flows = dataBot.flows
      data.nodes = dataBot.nodes
      data.edges = dataBot.edges
      
      const url = urlAPI_3 + `chatBot/${data._id}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(data)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setEditFlow(result)
      getBots(false);
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Listar Logs
  const getLogs = async () => {
    try {
      const url = urlAPI_3 + `chatBot/logs/${editFlow._id}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      let result = await response.json();
      if(result.length <= 0) {
        setListLogs([]);
      } else {
        result = result.sort((a, b) => new Date(b.created) - new Date(a.created));
        setListLogs(result);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }finally{
      setLoading(false);
    }
  };
  //#--> Crear Logs
  const createLogs = async (params) => {
    try {

      const dataLogs = {
        chatBotId: params.id,
        nodes: params.nodes,
        edges: params.edges,
        flows: params.flows
      }

      const url = urlAPI_3 + "chatBot/logs";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(dataLogs)};
      const response = await fetchWithAuth(url, options);
      await response.json();
      await getLogs();
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Remover Bot
  const deleteBot = async (id) => {
    try {
      const data = {
        isFetch: true,
        fetch: {
          url: urlAPI_3 + `chatBot/${id}`,
          options: { 
            method: "DELETE", 
            mode: "cors", 
          },
        },
        title: "¿Estas seguro que deseas eliminar este bot?",
        text: "No podrás recuperarlo.",
        icon: "warning",
        buttons: {
          success: "Eliminar",
          cancel: "Cancelar"
        }        
      }
      console.log("Datos enviados a questionToast:", data);
      const result = await questionToast(data, setLoading)
      
      console.log("Result of the API: delete chatBot/{id}:", result);

      //#--> Actualización de la tabla
      setLoading(true);
      await getBots()
      setLoading(false);
      //#--> Confirmación de la eliminación
      sendToast({type: "success", message: "Bot eliminado con éxito."})
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    }
  };
  // #--> Armar JSON
  const buildJson = async (type, newEdges, newNodes) => {
    newEdges = cleanEdges(newEdges, newNodes);

    let flows = []
    for(let i in newNodes) {
      const node = newNodes[i]
      let newFlow = {};


      // Transitions
      const filterEdge = newEdges.filter(v => v.source == node.id)
      const transitions = filterEdge.reduce((acc, v) => {
        if (!acc.seen.has(v.target)) {
          acc.transitions.push({
            next_flow_id: v.target,
            conditions: v.conditions || [],
            control_message: selectedEdge?.message_control || null

          });
          acc.seen.add(v.target);
        }
        return acc;
      }, { transitions: [], seen: new Set() }).transitions;

      // Triggers
      let triggers = [];
      const seenTriggers = new Set();

      if (node.data.first) {
        if (!seenTriggers.has('*')) {
          triggers.push({
            type: 'message',
            pattern: '*',
          });
          seenTriggers.add('*');
        }
      } else if (node.data.user_response) {
        const userResponseArray = node.data.user_response.includes(" / ")
          ? node.data.user_response.split(" / ")
          : [node.data.user_response];

        const uniquePatterns = new Set(userResponseArray.map(item => item.trim()));
        uniquePatterns.forEach(pattern => {
          if (!seenTriggers.has(pattern)) {
            triggers.push({
              type: 'message',
              pattern,
            });
            seenTriggers.add(pattern);
          }
        });
      } 

      if(node.type == "basicAnswer"){
        let actions = []
        if(node.data.options.length || node.data?.advanced?.dynamic_buttons) {
          actions.push(
            {
              type: 'send_message', 
              typeMessage: "interactive",
              content: {
                options: node.data.options,
                message: node.data.bot_response
              },
            }
          )
        } else if(node.data.bot_response && !node.data.file) {
          actions.push(
            {
              type: 'send_message',
              typeMessage: "text",
              content: node.data.bot_response,
            }
          )
        } else if(node.data.bot_response && node.data.file) {
          actions.push(
            {
              type: 'send_message',
              typeMessage: "media",
              content: {
                name: node.data.file.name,
                type: node.data.file.type,
                url: node.data.file.url,
                fileText: node.data.bot_response
              }
            }
          )
        } else if(node.data.file) {
          actions.push(
            {
              type: 'send_message',
              typeMessage: "media",
              content: {
                name: node.data.file.name,
                type: node.data.file.type,
                url: node.data.file.url,
                fileText: ""
              }
            }
          )
        }
        
        const moreTriggers = findMoreTrigger(newEdges, newNodes, node.id)
        moreTriggers.forEach(trigger => {
          if (!seenTriggers.has(trigger.pattern)) {
            triggers.push(trigger);
            seenTriggers.add(trigger.pattern);
          }
        });

        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: actions,
          transitions: transitions,
        };
      }
      if(node.type == "transferAgent"){
        let type_transfer;
        let content;
        if(node.data?.agent_area?.type == 'agent') {
          type_transfer = 'transfer_agent';
          content = node.data?.agent_area?.value;
        }
        if(node.data?.agent_area?.type == 'area') {
          type_transfer = 'transfer_area';
          content = node.data?.agent_area?.label;
        }
        if(node.data?.agent_area?.type == 'automatic') {
          type_transfer = 'transfer_automatic';
          content = node.data?.agent_area?.id;
        }
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: 'send_message',
              typeMessage: "text",
              content: node.data.bot_response,
            },
            {
              type: type_transfer,
              content: content,
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "tags"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: "taged",
              content: node.data.tags,
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "saveAnswer"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: "saveAnswer",
              content: node.data.variable,
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "flowsWhatsApp"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: 'send_flow',
              content: {
                flow: node.data.flow,
                flow_data: node.data.flow_data
              },
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "queryApi"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: "queryApi",
              content: node.data,
            },
            {
              type: 'send_message',
              typeMessage: "text",
              content: node.data.bot_response,
            }
          ],
          transitions: transitions,
        };
      }
      if(node.type == "assistantAI"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: triggers,
          actions: [
            {
              type: 'send_message',
              typeMessage: "text",
              content: node.data.bot_response,
            },
            {
              type: "assistantAI",
              content: node.data.assistant,
            }
          ],
          transitions: transitions,
        };
      }

      flows.push(newFlow);
    }

    flows = validateFlowsTags(flows);
    flows = validateFlowsSaveAnswer(flows);

    let json = {
      name: nameBot,
      description: descriptionBot,
      enabled: false,
      inactivityTime: 14,
      inactivityTimeClose: 15,
      inactivityStatus: false,
      companySchedule: "24h",
      flows: flows,
      nodes: nodes,
      edges: edges
    };

    if(type == 'create') {
      return json;
    } else {
      return {
        flows: flows,
        nodes: newNodes,
        edges: newEdges
      };
    }
  }
  //#--> Buscar Bot
  const handleSearch = async (search) => {
    try {
        setLoading(true);
        const url = urlAPI_3 + `chatBot?search=${encodeURIComponent(search)}`;
        const options = { method: "GET", mode: "cors"};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();
        if(result.length <= 0) {
            setListBots([]);
            setValidation(true);
        } else {
            setListBots(result);
            setValidation(false);
        }

        setLoading(false);
    } catch (error) {
        console.error("Error en la búsqueda:", error);
    }finally{
        setLoading(false);
    }
  };
  //#--> Crear Bot con IA
  const createBotWithIA = async (values) => {
    return
    try {
      setLoading(true);

      const data = {
        message: values.instructions_bot
      }

      const url = urlAPI_3 + "chatBot/newAI";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(data)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log(result, 'res')
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // #--> ResetData
  const resetData = () => {
    setNameBot("");
    setDescriptionBot("");
    setNodes([]);
    setEdges([]);
    setHeaderParams({});
    setRequestParams({});
    setRequestParamsBody({});
    setResponseParams({});
    setRequestResponse({});
    setEditFlow({});
    setSelectedNode(null);
    setErrorMonitoring(false);
    setSelectedLog(false);
    setShowLogs(false);
    setShowPreview(false);
  };
  // #--> Update name bot
  const updateNameBot = async (bot) => {
    let json = {
      ...bot,
      name: editName
    }

    setLoading(true);
    const url = urlAPI_3 + `chatBot/${json._id}`;
    const options = { method: "PUT", mode: "cors", body: JSON.stringify(json)};
    await fetchWithAuth(url, options);

    setLoading(false);
    setShowEditName(false);
    setNameBot(editName);
    setEditName("");
  };
  const validateFlowsTags = (flows) => {
    let tagFlow = null;
    let updatedFlowId = null;

    // Buscar el flujo con 'tags' en su id
    for (let flow of flows) {
      if (flow.id.includes('tags')) {
        tagFlow = flow;
        break;
      }
    }

    if (!tagFlow) {
      return flows;  // No se encontró el flujo de tags, devolvemos el array original
    }

    // Si `transitions` no está vacío
    if (tagFlow.transitions && tagFlow.transitions.length > 0) {
      const nextFlowId = tagFlow.transitions[0].next_flow_id;

      // Buscar el siguiente flujo por `next_flow_id`
      for (let flow of flows) {
        if (flow.id === nextFlowId) {
          // Concatenar `actions` del siguiente flujo
          flow.actions = flow.actions.concat(tagFlow.actions);
          updatedFlowId = flow.id;
          break;
        }
      }

      // Eliminar solo el flujo de 'tags' específico
      flows = flows.filter(flow => flow.id !== tagFlow.id);
    }

    // Actualizar las transiciones de los otros flujos si contienen el id del flujo eliminado
    if (updatedFlowId) {
      for (let flow of flows) {
        if (flow.transitions) {
          for (let transition of flow.transitions) {
            if (transition.next_flow_id === tagFlow.id) {
              transition.next_flow_id = updatedFlowId;
            }
          }
        }
      }
    }

    return flows;
  };
  const validateFlowsSaveAnswer = (flows) => {
    let saveAnswerFlow = null;
    let updatedFlowId = null;

    // Buscar el flujo con 'saveAnswer' en su id
    for (let flow of flows) {
      if (flow.id.includes('saveAnswer')) {
        saveAnswerFlow = flow;
        break;
      }
    }

    if (!saveAnswerFlow) {
      return flows;  // No se encontró el flujo de saveAnswer, devolvemos el array original
    }

    // Si `transitions` no está vacío
    if (saveAnswerFlow.transitions && saveAnswerFlow.transitions.length > 0) {
      const nextFlowId = saveAnswerFlow.transitions[0].next_flow_id;

      // Buscar el siguiente flujo por `next_flow_id`
      for (let flow of flows) {
        if (flow.id === nextFlowId) {
          // Concatenar `actions` del siguiente flujo
          flow.actions = flow.actions.concat(saveAnswerFlow.actions);
          updatedFlowId = flow.id;
          break;
        }
      }

      // Eliminar solo el flujo de 'saveAnswer' específico
      flows = flows.filter(flow => flow.id !== saveAnswerFlow.id);
    }

    // Actualizar las transiciones de los otros flujos si contienen el id del flujo eliminado
    if (updatedFlowId) {
      for (let flow of flows) {
        if (flow.transitions) {
          for (let transition of flow.transitions) {
            if (transition.next_flow_id === saveAnswerFlow.id) {
              transition.next_flow_id = updatedFlowId;
            }
          }
        }
      }
    }

    return flows;
  };
  const findMoreTrigger = (edges, nodes, nodeId) => {
    const filterEdges = edges.filter(edge => edge.target == nodeId && edge.targetHandle.includes('target-return'))
    let triggers = []
    if(filterEdges.length) {
      filterEdges.forEach(edge => {
        const findNode = nodes.find(node => node.id == edge.source)
        if(findNode) {
          let sHandle = edge.sourceHandle
          sHandle = sHandle.replace(/source-/, '');;
          sHandle = sHandle.replace(/-\d+$/, '');
          const findOption = findNode.data.options.find(opt => opt.id == sHandle)

          if(findOption){
            triggers.push({
              type: 'message',
              pattern: findOption.label,
            })
          }
        }
      })
    }
    return triggers
  };
  const handlEditChatBot = (bot) => {
    setMode("create")
    setNodes(bot.nodes)
    setEdges(bot.edges)
    setEditFlow(bot)
    setNameBot(bot.name)
    setDescriptionBot(bot.description)
  };
  const handleOpenNewBot = (initialNodes) => {
    setNodes(initialNodes);
    setModalCreate(true);
  };
  const handleNewBot = (item) => {
    let name = item.name;
    const filter = listBots.filter(v => v.name.includes(name))
    if(filter.length) {
      name = name + " " + (filter.length + 1)
    }

    setNameBot(name);
    setDescriptionBot(item.description);
    setNodes(item.nodes);
    setEdges(item.edges);
    setShowModalCreateTemplate(true);
  };

  // RESOURCES
  const getAreas = async () => {
    try {
      const url = urlAPI_2 + "getAreasByCompany";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        sendToast(result)
        setListAreas([])
      }else{
        const opts = result.data.map(v => {
          return {
            label: v.description,
            value: v._id,
            type: "area"
          }
        })
        setListAreas(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAgents = async () => {
    try {
      const url = urlAPI_2 + "usersbyArea";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        sendToast(result)
      } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales."})
        navigate("/");
      } else if (result.data.length <= 0) {
        setListAdvisers([]);
      } else {
        const opts = result.data.map(v => {
          return {
            label: v.name,
            value: v._id,
            type: "agent"
          }
        })
        setListAdvisers(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getTags = async () => {
    try {
      const url = urlAPI_1 + `tags`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(!result.data) {
        setListTags([]);
      } else {
        setListTags(result.data);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
  const getCorresp = async () => {
    try {
      const url = urlAPI_2 + "getDataEmail";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      let data = result.data
      setCorrespondence(data);
      if (result.message === "Tu petición no tiene cabecera de autorización") {
        sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales."})
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getFlows = async () => {
    try {
      const url = urlAPI_2 + "flows";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.type === "error") {
        sendToast(result)
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales."})
        navigate('/');
      }  else if (!result.data) {
        setFlows([]);
      } else {
        setFlows(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAssistants = async () => {
    try {
      const url = urlAPI_3 + "assistants";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.type === "error") {
        sendToast(result)
      } else if (!result.data) {
        setListAssistants([])
      } else {
        setListAssistants(result.data)
      }
    } catch (error) {
      console.log('error---->', error);
    }
  };
  const getOptionsInactivity = () => {
    let options = []
    for(let i = 1; i <= 15; i++) {
      options.push({
        label: i + ' min',
        value: i
      })
    }
    
    setListInactivity(options) 
  };
  const getOptionsAttempts = () => {
    let options = []
    for(let i = 1; i <= 10; i++) {
      options.push({
        label: i + ' intento/s',
        value: i
      })
    }
    
    setListAttempts(options) 
  };

  // RESOURCES 
  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };
  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };
  
  const requestApiNode = async () => {
    setLoading(true);
    let url = selectedNode.data.url_api;
    let options = {
      method: selectedNode.data.protocol.id,
      headers: {}
    };

    if (connectionTestData?.headers?.length) {
      options.headers = connectionTestData.headers.reduce((acc, header) => {
        acc[header.key] = header.value;
        return acc;
      }, {});
    }

    if (selectedNode.data.protocol.id !== 'GET') {
      options.body = JSON.stringify({});
      if (connectionTestData?.data?.length) {
        options.body = JSON.stringify(
          connectionTestData.data.reduce((acc, param) => {
            acc[param.key] = param.value;
            return acc;
          }, {})
        );
      }
    }

    if (connectionTestData?.params?.length) {
      const params = new URLSearchParams();
      connectionTestData.params.forEach(param => {
          params.append(param.key, param.value);
      });
      url += `?${params.toString()}`;
    }

    try {
      const response = await fetch(url, options);
    
      if (!response.ok) {
        throw new Error('Error en la respuesta de la consulta');
      }
    
      const blob = await response.blob();
      let result;
    
      try {
        const text = await blob.text();
        result = JSON.parse(text);
        const res = convertToTree(result, "response")
        const res2 = convertToTreeResToNode(result, "response")
        setListRequestParams([res]);
        setListOptionsResToNode([res2])
      } catch (e) {
        result = await blob.text();
      }
  
      if (!result || (Array.isArray(result) && result.length <= 0)) {
        sendToast({type: "info", message: "Ocurrió un error al realizar la consulta, por favor valida los datos necesarios para realizar la consulta"})
      } else {
        setRequestResponse(result);
      }
    } catch (error) {
      console.log("error: ", error);
      sendToast({type: "info", message: "Ocurrió un error al realizar la consulta, por favor valida los datos necesarios para realizar la consulta"})
    } finally {
      setLoading(false);
    }
    
  };
  const convertToTree = (item, key) => {
    if (item === null || item === undefined) {
      return { id: key, label: key };
    }

    if (Array.isArray(item)) {
      const children = item.map((subItem, index) => convertToTree(subItem, `${key}[${index}]`));
      return { id: key, label: key, children };
    } else if (typeof item === "object") {
      const children = Object.keys(item).map((subKey) => convertToTree(item[subKey], `${key}.${subKey}`));
      return { id: key, label: key, children };
    } else {
      return { id: key, label: key };
    }
  };
  const convertToTreeResToNode = (item, key, parentKey = '') => {
    const uniqueKey = parentKey ? `${parentKey}.${key}` : key;
  
    if (item === null) {
      return { id: uniqueKey, label: `${key}: null` };
    }
  
    if (item === undefined) {
      return { id: uniqueKey, label: `${key}: undefined` };
    }
  
    if (Array.isArray(item)) {
      const children = item.map((subItem, index) => convertToTreeResToNode(subItem, `${key}[${index}]`, uniqueKey));
      return { id: uniqueKey, label: key, children };
    } else if (typeof item === "object") {
      const children = Object.keys(item).map((subKey) => convertToTreeResToNode(item[subKey], subKey, uniqueKey));
      return { id: uniqueKey, label: key, children };
    } else {
      return { id: uniqueKey, label: `${key}: ${item}` };
    }
  };
  const handleItemValSelect = (event, ids) => {
    if (ids !== 'response') {
      setResponseParams((prev) => ({
        ...prev,
        key: ids
      }))
    }
  };
  const nodeColor = (node) => {
    if(node.id.includes('basicAnswer')) {
      return nodeTypesColors['basicAnswer']
    }
    if(node.id.includes('bottomNode')) {
      return nodeTypesColors['bottomNode']
    }
    if(node.id.includes('transferAgent')) {
      return nodeTypesColors['transferAgent']
    }
    if(node.id.includes('tags')) {
      return nodeTypesColors['tags']
    }
    if(node.id.includes('assistantAI')) {
      return nodeTypesColors['assistantAI']
    }
    if(node.id.includes('saveAnswer')) {
      return nodeTypesColors['saveAnswer']
    }
    if(node.id.includes('flowsWhatsApp')) {
      return nodeTypesColors['flowsWhatsApp']
    }
    if(node.id.includes('queryApi')) {
      return nodeTypesColors['queryApi']
    }
  }

  const handleClickModalVariables = (event, typeValue, type) => {
    setAnchorEl(event.currentTarget);
    setVariablesModal(true);
    setTypeInputValue(typeValue);
    setTypeInput(type);
  };

  const responseToNode = (event) => {
    setAnchorEl2(event.currentTarget);
    setResponseToNodeModal(true);
  };
  const findNodeById = (nodes, id) => {
    for (let node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.children) {
        const childResult = findNodeById(node.children, id);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  };
  const convertToNode = () => {
    const position = screenToFlowPosition({ x: 250, y: 15 });

    const newNode = {
      id: getId() + "-basicAnswer",
      type: 'basicAnswer',
      data: { 
        title: 'Respues Básica',
        bot_response: listToNodeData.description,
        user_response: "*",
        options: listToNodeData.options,
        file: null,
        variables: null,
        saved: true
      },
      position,
    };

    setNodes((nds) => [...nds, newNode]);
    let newNodes = [...nodes, newNode]
    setTimeout(() => {
      addNewNode(editFlow, newNodes, newNode)
    }, 500);

    setResponseToNodeModal(false);
    setVariablesOptionsModal(false);
    setListToNodeData({
      options: [],
      description: "",
    });
    setConnectionTestData({
      headers: [],
      params: [],
      data: []
    });
  };

  // Funcion para manejar vista ventana tools
  const handleErrorMonitoring = (close = false) => {
    if (errorMonitoring) {
      setAnimateClass('slide-out');
      setTimeout(() => {
        setErrorMonitoring(false);
        setAnimateClass('');
      }, 500); 
    } else {
      if(!close) {
        setShowPreview(false);
        setShowLogs(false);
        setErrorMonitoring(true);
        setAnimateClass('slide-in');
      }
    }
  };

  // Funcion para ir al nodo inicial 
  const goToInitialNode = () => {
    const fundNode = nodes.find(v => v.data?.first);
    if (fundNode) {
      const nodeWidth = fundNode.width || 100;
      const nodeHeight = fundNode.height || 100;
  
      const nodeBounds = {
        x: fundNode.position.x - nodeWidth / 20,
        y: fundNode.position.y - nodeHeight / 20,
        width: nodeWidth,
        height: nodeHeight
      };
  
      fitBounds(nodeBounds, { duration: 1000, padding: 0.1 });
    }
  };
  // Funcion para ir a un nodo
  const goToNode = (paramNode) => {
    const nodeWidth = paramNode.width || 100;
    const nodeHeight = paramNode.height || 100;

    const nodeBounds = {
      x: paramNode.position.x - nodeWidth / 20,
      y: paramNode.position.y - nodeHeight / 20,
      width: nodeWidth,
      height: nodeHeight
    };

    fitBounds(nodeBounds, { duration: 1000, padding: 0.1 });
  };
  // Funcion para observar nodos desconectados
  const watchDisconnectedEdges = () => {
    const connectedNodes = new Set();

    edges.forEach(edge => {
      connectedNodes.add(edge.source);
      connectedNodes.add(edge.target);
    });

    const disconnectedNodes = nodes.filter(node => !connectedNodes.has(node.id));

    setListDisconnectedNodes(disconnectedNodes);
  };  
  // Funcion para observar nodos con opciones desconectadas
  const watchDisconnectedOptions = () => {
    const connectedOptions = new Set();
    const optionsToNodesMap = new Map();

    // Buscar todas las opciones en el flujo y mapear nodos
    nodes.forEach(node => {
      if (node.type === 'basicAnswer' && node.data?.options) {
        node.data.options.forEach(option => {
          connectedOptions.add(option.id);
          optionsToNodesMap.set(option.id, node);
        });
      }
    });

    const nodesWithDisconnectedOptions = new Set();

    // Buscar todos los edges en el flujo y comparar
    optionsToNodesMap.forEach((node, optionId) => {
      const findEdge = edges.find(edge => edge.sourceHandle.includes(optionId));
      if (!findEdge) {
        nodesWithDisconnectedOptions.add(node);
      }
    });

    setListDisconnectedOptions(Array.from(nodesWithDisconnectedOptions));
  };   
  // Funcion para observar nodos con campos incompletos
  const watchNodesWithErrorInFields = () => {
    const nodesWithErrorInFields = nodes.filter(node => {
      switch(node.type) {
        case 'basicAnswer':
          return !node.data?.first && !node.data.user_response || !node.data.bot_response;
        case 'transferAgent':
          return !node.data.user_response || !node.data.bot_response || !node.data.agent_area;
        case 'tags':
          return !node.data.user_response || !node.data.tags;
        case 'assitantAI':
          return !node.data.user_response || !node.data.bot_response || !node.data.assistant;
        case 'saveAnswer':
          return !node.data.user_response || !node.data.variable;
        case 'flowsWhatsApp':
          return !node.data.user_response || !node.data.flow  || !node.data.flow_data?.body || !node.data.flow_data?.footer || !node.data.flow_data?.button;
        case 'queryApi':
          return !node.data.user_response || !node.data.bot_response || !node.data.url_api || !node.data.protocol || !node.data.auth;
        default:
          return false;
      }
    });
    
    setListNodesWithErrorInFields(nodesWithErrorInFields);
  }; 
  // Funcion para observar flujos con triggers duplicados
  const watchTriggersDuplicates = () => {
    let totalDuplicateCount = 0;

    editFlow?.flows?.forEach(flow => {
      const triggersMap = new Map();
      flow.triggers.forEach(trigger => {
        const key = `${trigger.type}-${trigger.pattern}`;
        if (triggersMap.has(key)) {
          triggersMap.set(key, triggersMap.get(key) + 1);
        } else {
          triggersMap.set(key, 1);
        }
      });

      triggersMap.forEach((count) => {
        if (count > 1) {
          totalDuplicateCount += count;
        }
      });
    });

    setTriggersDuplicateCount(totalDuplicateCount);
  };
  // Funcion para observar flujos con transitions duplicadas
  const watchTransitionsDuplicates = () => {
    let totalDuplicateCount = 0;

    editFlow?.flows?.forEach(flow => {
        const transitionsMap = new Map();
        flow.transitions.forEach(transition => {
            const key = transition.next_flow_id;
            if (transitionsMap.has(key)) {
                transitionsMap.set(key, transitionsMap.get(key) + 1);
            } else {
                transitionsMap.set(key, 1);
            }
        });

        transitionsMap.forEach((count) => {
            if (count > 1) {
                totalDuplicateCount += count;
            }
        });
    });

    setTransitionsDuplicateCount(totalDuplicateCount);
  };    
  // Funcion para buscar el color del nodo 
  const findNodeColor = (typeNode) => {
    let color = ""  
    if(typeNode === 'basicAnswer') {
      color = nodeTypesColors['basicAnswer']
    }
    if(typeNode === 'transferAgent') {
      color = nodeTypesColors['transferAgent']
    }
    if(typeNode === 'tags') {
      color = nodeTypesColors['tags']
    }
    if(typeNode === 'assistantAI') {
      color = nodeTypesColors['assistantAI']
    }
    if(typeNode === 'saveAnswer') {
      color = nodeTypesColors['saveAnswer']
    }
    if(typeNode === 'flowsWhatsApp') {
      color = nodeTypesColors['flowsWhatsApp']
    }
    if(typeNode === 'queryApi') {
      color = nodeTypesColors['queryApi']
    }

    return color
  };
  // Funciona para detectar nodos con errores y colocar icono
  const findNodesError = () => {
    const nodesWithErrors = [
      ...listDisconnectedNodes,
      ...listDisconnectedOptions,
      ...listNodesWithErrorInFields
    ];

    // Compara nodesWithErrors con la referencia anterior
    if (JSON.stringify(nodesWithErrors) !== JSON.stringify(prevNodesWithErrors.current)) {
      prevNodesWithErrors.current = nodesWithErrors;
      setNodes(nodes => 
          nodes.map(node => {
          const hasError = nodesWithErrors.some(errorNode => errorNode.id === node.id);
          return {
            ...node,
            data: {
              ...node.data,
              error: hasError
            }
          };
        })
      );
    }
  };
  // Funciona para abrir logs
  const handleShowLogs = async () => {
    if (showLogs) {
      setAnimateClass('slide-out');
      setTimeout(() => {
        setShowLogs(false);
      }, 500); 
    } else {
      handleErrorMonitoring(true);
      setShowPreview(false);
      setTimeout(() => {
        setShowLogs(true);
        setAnimateClass('slide-in');
      }, 500);
    }
  };
  // Funciona para seleccionar log
  const selectLog = (params) => {
    setSelectedLog(true);
    setNodes(params.nodes);
    setEdges(params.edges);
    
    if(errorMonitoring) {
      handleErrorMonitoring(true)
    }
    if(showPreview) {
      handlePreview()
    }
  };
  // Funcion para retornar a version seleccionada de los logs
  const returnVersion = async () => {
    setLoading(true);
    setSelectedLog(false);
    await createLogs({nodes: editFlow.nodes, edges: editFlow.edges, flows: editFlow.flows, id: editFlow._id});
    await updateBot(editFlow, edges, nodes);
    setLoading(false);
  };

  const truncateText = (text, maxLength = 26) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  // Functions for conditions
  const addRule = (type) => {
    setSelectedEdge((edge) => {
      const updatedEdge = {
        ...edge,
        conditions: edge.conditions ? [...edge.conditions] : []
      };
  
      if (type === "and") {
        updatedEdge.conditions.push({
          type: "AND",
          rule: {
            value1: null,
            value2: null,
            operator: null,
            operatorType: null
          }
        });
      }
  
      if (type === "or") {
        updatedEdge.conditions.push({
          type: "OR",
          rule: {
            value1: null,
            value2: null,
            operator: null,
            operatorType: null
          }
        });
      }

      if(!updatedEdge.message_control) {
        updatedEdge.message_control = "Respuesta incorrecta, no cumple con las condiciones especificadas."
      }
  
      return updatedEdge;
    });
  };   

  const getOptionsOperators = () => {
    setListOptionsOperators([
      {
        label: 'Operadores básicos',
        options: [
          {
            label: 'Existe',
            id: 'exist',
            type: 'basic'
          },
          {
            label: 'No existe',
            id: 'does_not_exist',
            type: 'basic'
          }
        ]
      },
      {
        label: 'Operadores de texto',
        options: [
          {
            label: 'Igual a',
            id: 'equal_to',
            type: 'text'
          },
          {
            label: 'Igual a (No distingue entre mayúscula o minúscula)',
            id: 'equal_to_case_sensative',
            type: 'text'
          },
          {
            label: 'No es igual a',
            id: 'not_equal_to',
            type: 'text'
          },
          {
            label: 'No es igual a (No distingue entre mayúscula o minúscula)',
            id: 'not_equal_to_case_sensative',
            type: 'text'
          },
          {
            label: 'Contiene',
            id: 'contain',
            type: 'text'
          },
          {
            label: 'Contiene (No distingue entre mayúscula o minúscula)',
            id: 'contain_case_sensative',
            type: 'text'
          },
          {
            label: 'No contiene',
            id: 'does_not_contain',
            type: 'text'
          },
          {
            label: 'No contiene (No distingue entre mayúscula o minúscula)',
            id: 'does_not_contain_case_sensative',
            type: 'text'
          },
        ]
      },
      {
        label: 'Operadores númericos',
        options: [
          {
            label: 'Igual a',
            id: 'equal_to',
            type: 'numeric'
          },
          {
            label: 'No es igual a',
            id: 'not_equal_to',
            type: 'numeric'
          },
          {
            label: 'Mayor que',
            id: 'greater_than',
            type: 'numeric'
          },
          {
            label: 'Menor que',
            id: 'less_than',
            type: 'numeric'
          },
          {
            label: 'Mayor que o igual a',
            id: 'greater_than_or_equal_to',
            type: 'numeric'
          },
          {
            label: 'Menor que o igual a',
            id: 'less_than_or_equal_to',
            type: 'numeric'
          },
        ]
      },
      {
        label: 'Operadores de fecha',
        options: [
          {
            label: 'Igual a',
            id: 'equal_to',
            type: 'datatime'
          },
          {
            label: 'No es igual a',
            id: 'not_equal_to',
            type: 'datatime'
          },
          {
            label: 'Más tarde que',
            id: 'later_than',
            type: 'datatime'
          },
          {
            label: 'Más temprano que',
            id: 'earlier_than',
            type: 'datatime'
          },
          {
            label: 'Más tarde o igual a',
            id: 'later_than_or_equal_to',
            type: 'datatime'
          },
          {
            label: 'Más temprano o igual a',
            id: 'earlier_than_or_equal_to',
            type: 'datatime'
          },
        ]
      },
      {
        label: 'Operadores de tiempo',
        options: [
          {
            label: 'Igual a',
            id: 'equal_to',
            type: 'time'
          },
          {
            label: 'No es igual a',
            id: 'not_equal_to',
            type: 'time'
          },
          {
            label: 'Mayor que',
            id: 'greater_than',
            type: 'time'
          },
          {
            label: 'Menor que',
            id: 'less_than',
            type: 'time'
          },
          {
            label: 'Mayor que o igual a',
            id: 'greater_than_or_equal_to',
            type: 'time'
          },
          {
            label: 'Menor que o igual a',
            id: 'less_than_or_equal_to',
            type: 'time'
          },
        ]
      },
    ]);
  };

  const customSingleValue = (props) => {
    const group = listOptionsOperators.find(group =>
      group.options.some(opt => opt.id === props.data.id)
    );
    const prefix = group ? `${group.label}: ` : '';
    return (
      <components.SingleValue {...props}>
        {`${prefix}${props.data.label}`}
      </components.SingleValue>
    );
  };

  const removeCondition = (index) => {
    setSelectedEdge((edge) => {
      const updatedConditions = edge.conditions ? [...edge.conditions] : [];
      updatedConditions.splice(index, 1);
      
      return {
        ...edge,
        conditions: updatedConditions,
      };
    });
  };
  
  const saveConditions = async () => {
    if (!selectedEdge) {
      return;
    }

    setLoading(true);
    let newEdges = edges.map((edge) => {
      if (selectedEdge.id === edge.id) {
        return {
          ...edge,
          conditions: selectedEdge.conditions,
          message_control: selectedEdge.message_control,
        };
      }
      return edge; 
    });
    
    await updateBot(editFlow, newEdges, nodes);
    setEdges(editFlow.edges);
    setShowConditionsModal(false);
    setSelectedEdge(null);
    setLoading(false);
    sendToast({type: "success", message: "Condiciones agregadas con éxito"})
  };   

  const handlePreview = () => {
    if (showPreview) {
      setAnimateClass('slide-out');
      setTimeout(() => {
        setShowPreview(false);
      }, 500); 
    } else {
      handleErrorMonitoring(true);
      setShowLogs(false);
      setTimeout(() => {
        setShowPreview(true);
        setAnimateClass('slide-in');
      }, 500);
    }
  };

  const cleanEdges = (newEdges, newNodes) => {
    const nodeIds = new Set(newNodes.map(node => node.id));
    const optionIds = new Set(newNodes.flatMap(node => (node.data?.options || []).map(option => option.id)));

    return newEdges.filter(edge => {
      const match = edge.sourceHandle?.match(/^source-(.*?)(-\d+)?$/);
      const sourceHandleId = match ? match[1] : null;
      if (["source-queryApi-succes", "source-queryApi-failde"].includes(edge.sourceHandle)) {
        return nodeIds.has(edge.source) && nodeIds.has(edge.target);
      }

      return nodeIds.has(edge.source) &&
        nodeIds.has(edge.target) &&
        (nodeIds.has(sourceHandleId) || optionIds.has(sourceHandleId));
    });
  }; 

  const handleCursorPosition = (e) => {
    setCursorPosition(e.target.selectionStart); 
  };
 
  const CustomBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      background: '#00A884',
      right: '2px',
      top: '2px',
    },
  })); 

  useEffect(() => {
    getIsDark();
    getOptionsInactivity();
    getOptionsAttempts();

    const fetchData = async () => {
      try {
        await Promise.all([
          getAgents(),
          getBotsTemplates(),
          getAreas(),
          getTags(),
          getCorresp(),
          getFlows(),
          getAssistants(),
          getLogs(),
          getOptionsOperators(),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [mode]);
  
  useEffect(() => {
    setNodes((nds) => 
      nds.map((node) => {
        if (selectedNode && node.id === selectedNode.id) {
          node.data = {
            ...selectedNode.data
          };
        }

        return node;
      })
    )
  }, [selectedNode]);

  useEffect(() => {
    watchDisconnectedEdges();
    watchDisconnectedOptions();
    watchNodesWithErrorInFields();
    findNodesError();
    watchTriggersDuplicates();
    watchTransitionsDuplicates();
  }, [nodes, listBots, edges]);


  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      {mode === "list" && (
        <List
          handleSearch={handleSearch}
          handlEditChatBot={handlEditChatBot}
          handleOpenNewBot={handleOpenNewBot}
          handleNewBot={handleNewBot}
          deleteBot={deleteBot}
          getBots={getBots}
          validation={validation}
          listBots={listBots}
          listInactivity={listInactivity}
          listCompanyScheduleOptions={listCompanyScheduleOptions}
          listAttempts={listAttempts}
          listAreas={listAreas}
          listAdvisers={listAdvisers}
          listBotsTemplates={listBotsTemplates}
          isDark={isDark}
          customStyles={customStyles}
          customStylesDark={customStylesDark}
          mode={mode}
          handleModalBotAssistant={setModalBotAssistant}
        />
      )}

      {mode === "create" && (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-2" style={{height:"6%"}}>
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex"
                onClick={() => {
                  setMode("list");
                  setFlows([]);
                  resetData();
                  getBots();
                }}
              >
                <FaAngleLeft className="mr-1" /> Bots
              </button>
            </div>

            <Header />
          </div>
          <section className="module-create-user" style={{ width: "100%", height:"90%" }}>
            <header className="message" style={{ height:"10%" }}>
              <div className="row justify-content-between align-items-center">
                {!selectedLog && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-left col-1-sm-3 d-flex align-items-center"
                      onClick={() => {
                        setShowEditName(true);
                        setEditName(nameBot);
                      }}
                      disabled={selectedLog}
                      data-tooltip-id="my-tooltip"  
                      data-tooltip-content="Editar nombre"
                    >
                      {nameBot} <BsPencilFill className="ml-2" style={{ fontSize: "18px" }} /> 
                    </button>
                  </div>
                )} 
                {selectedLog && (
                  <div
                    className="d-flex justify-content-center"
                    style={{
                      position: "absolute",
                      bottom: 5,
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <a
                      href="javascript:;"
                      className="btn btn-lg btn-default mr-1"
                      onClick={() => {
                        setNodes(editFlow.nodes);
                        setEdges(editFlow.edges);
                        setSelectedLog(false);
                      }}
                    >
                      Volver
                    </a>
                    <input
                      className="btn btn-lg btn-info"
                      value="Restaurar a esta versión"
                      name="submit"
                      id="submit"
                      type="button"
                      onClick={() => returnVersion()}
                    />
                  </div>
                )}
                {!selectedLog && (
                  <div className="dropdown">
                    <button
                      className="border-0 btn btn-left"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <IoIosSettings className="f-24 pointer" data-tooltip-id="my-tooltip" data-tooltip-content="Más opciones" />
                    </button>
                    <div
                        className="dropdown-menu p-2"
                        aria-labelledby="dropdownMenuButton"
                    > 
                        <button
                          onClick={handlePreview}
                          className="border-0 bg-transparent d-block pointer mt-2"
                        >
                          <FaWhatsapp style={{ fontSize: "18px", color: "#5BC0DE", marginTop: "4px" }}/>
                          <span className="text-dark ml-2 f-14">
                            Preview
                          </span>
                        </button>
                        <button 
                          onClick={() => setShowRemoveStatusesModal(true)}
                          className="text-danger border-0 bg-transparent d-block pointer mt-2"
                        >
                          <MdCleaningServices style={{ fontSize: "18px", color: "#5BC0DE" }}/>
                          <span className="text-dark ml-2 f-14">
                            Limpiar estados
                          </span>
                        </button>
                        <button 
                          className="text-muted border-0 bg-transparent d-block pointer mt-2" 
                          onClick={handleShowLogs}
                        >
                          <div className="d-flex aling-items-center">
                            <FaHistory style={{ fontSize: "18px", color: "#5BC0DE" }}/>
                            <span className="text-dark ml-2 f-14">
                              Historial
                            </span>
                          </div>
                        </button>
                        <button 
                          onClick={() => handleErrorMonitoring(false)}
                          className="text-danger border-0 bg-transparent d-block pointer mt-2"
                        >
                          <CustomBadge 
                            invisible={
                              (listDisconnectedNodes.length + 
                              listDisconnectedOptions.length + 
                              listNodesWithErrorInFields.length + 
                              (transitionsDuplicateCount || 0) + 
                              (triggersDuplicateCount || 0)) === 0
                            }
                            variant="dot"
                          >
                            <TbHeartRateMonitor style={{ fontSize: "18px", color: "#5BC0DE" }}/>
                          </CustomBadge>
                          <span className="text-dark ml-2 f-14">
                            Monitor
                          </span>
                        </button>
                    </div>
                  </div>
                )}
              </div>
            </header>
            <div style={{ height: '90%' }} className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow 
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onReconnect={onReconnect}
                onReconnectStart={onReconnectStart}
                onReconnectEnd={onReconnectEnd}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={memoizedNodeTypes}
                onNodeClick={handleNodeClick}
                onEdgeClick={handleEdgeClick}
                minZoom={0.2}
                maxZoom={2}
                fitView
              > 
                <Background color="#5BC0DE" variant={BackgroundVariant.Background}/>
                <MiniMap nodeStrokeWidth={3} pannable zoomable nodeColor={nodeColor}/>
                <Controls/>
                <div 
                  style={{
                    position: "relative"
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      zIndex: 1000,
                      top: "18px",
                      left: "-10px"
                    }}
                    type="button"
                    className="btn btn-left col-1-sm-3"
                    onClick={() => setShowNodes(!showNodes)}
                  >
                    <FaSquareShareNodes className="ml-2" style={{ fontSize: "22px" }}  data-tooltip-id="my-tooltip"  data-tooltip-content="Lista de nodos"/> 
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div 
                    className="card ml-5 mt-4 border border-12 border-black"
                    style={{
                      width: '300px', 
                      maxHeight: "460px", 
                      zIndex: 100, 
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", 
                      visibility: selectedLog || !showNodes ? "hidden" : "",
                    }}
                  >
                    <div 
                      id="content-list-chats" 
                      className="card-body" 
                      style={{overflowY: "auto"}}
                    >
                      <div style={{fontSize: "1.2rem", fontWeight: "bold", lineHeight: "normal"}}>
                        Opciones de Respuestas
                      </div>
                      <div style={{fontSize: "12px", fontWeight: "bold", lineHeight: "normal"}}>
                        Arrastra el nodo que desees utilizar
                      </div>
                      <div>
                        <ul className="list-group list-group-flush py-2">
                          <li
                            style={{cursor: 'grab', backgroundColor: '#00A884', borderRadius: "4px"}}
                            className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'BasicAnswer')} 
                            draggable
                          >
                            <div className="d-flex align-items-center">
                              <FiMessageSquare style={{ fontSize: "20px", fontWeight: "bold" }}/>
                              <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Respuestas Básicas</span>
                            </div>
                          </li>
                          <li
                            style={{cursor: 'grab', backgroundColor: '#F56F89', marginTop: '6px', borderRadius: "4px"}}
                            className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'TransferAgent')} 
                            draggable
                          >
                            <div className="d-flex align-items-center">
                              <MdOutlineSupportAgent style={{ fontSize: "20px", fontWeight: "bold" }}/>
                              <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Transferir Area/Agente</span>
                            </div>
                          </li>
                          <li
                            style={{cursor: 'grab', backgroundColor: '#5BC0DE', marginTop: '6px', borderRadius: "4px"}}
                            className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'Tags')} 
                            draggable
                          >
                            <div className="d-flex align-items-center">
                              <LuTags style={{ fontSize: "20px", fontWeight: "bold" }}/>
                              <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Etiquetas</span>
                            </div>
                          </li>
                          <li
                            style={{cursor: 'grab', backgroundColor: '#D89C7E', marginTop: '6px', borderRadius: "4px"}}
                            className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'SaveAnswer')} 
                            draggable
                          >
                            <div className="d-flex align-items-center">
                              <HiOutlineSave style={{ fontSize: "20px", fontWeight: "bold" }}/>
                              <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Guardar Respuestas</span>
                            </div>
                          </li>
                          <li
                            style={{cursor: 'grab', backgroundColor: '#B85C7C', marginTop: '6px', borderRadius: "4px"}}
                            className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'FlowsWhatsApp')} 
                            draggable
                          >
                            <div className="d-flex align-items-center">
                              <TiFlowMerge style={{ fontSize: "20px", fontWeight: "bold" }}/>
                              <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Flujos de Whatsapp</span>
                            </div>
                          </li>
                          <li
                            style={{
                              cursor: 'grab',
                              backgroundColor: '#7ECCD8',
                              marginTop: '6px',
                              borderRadius: '4px',
                            }}
                            className="grab list-group-item dndnode text-white"
                            onDragStart={(event) => onDragStart(event, 'QueryApi')} 
                            draggable
                          >
                            <div className="d-flex align-items-center">
                              <BsDatabaseFillAdd style={{ fontSize: "20px", fontWeight: "bold" }}/>
                              <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Consulta API</span>
                            </div>
                          </li>
                          <li
                            style={{
                              cursor: 'grab',
                              backgroundColor: '#A7ADA7',
                              marginTop: '6px',
                              borderRadius: '4px',
                              position: 'relative',
                              overflow: 'hidden',
                            }}
                            className="grab list-group-item dndnode text-white"
                            onDragStart={(event) => onDragStart(event, 'AssitantAI')}
                            draggable
                          >
                            <div className="d-flex align-items-center">
                              <FaBrain style={{ fontSize: '20px', fontWeight: 'bold' }} />
                              <span className="pl-2" style={{ fontSize: '14px', fontWeight: 'bold' }}>Asistente con IA</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      position: "relative", 
                      display: !selectedNode ? "none" : "",
                    }}
                  >
                    <div
                      className="card ml-5 mt-4 border border-12 border-black"
                      style={{
                        width: '300px', 
                        maxHeight: "460px", 
                        height: "100%",
                        zIndex: 100, 
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", 
                        position: "fixed",
                        left: position.x,
                      }}
                    >
                      {/* Basic Anwser */}
                      {!selectedLog && selectedNode?.type == 'basicAnswer' && (
                        <ViewBasicAnswer
                          selectedNode={selectedNode}
                          handleSelectedNode={setSelectedNode}
                          handleNodes={setNodes}
                          removeNode={removeNode}
                          nodes={nodes}
                          edges={edges}
                          editFlow={editFlow}
                          updateBot={updateBot}
                          truncateText={truncateText}
                          handleClickModalVariables={handleClickModalVariables}
                          onEmojiClick={onEmojiClick}
                          listRequestParams={listRequestParams}
                          customStyles={customStyles}
                          customStylesDark={customStylesDark}
                          isDark={isDark}
                          handleCursorPosition={handleCursorPosition}
                          watchSelectedNode={watchSelectedNode}
                          handleLoading={setLoading}
                        />
                      )}
                      {/* Transfer Agent */}
                      {!selectedLog && selectedNode?.type == 'transferAgent' && (
                        <ViewTransferAgent
                          selectedNode={selectedNode}
                          handleSelectedNode={setSelectedNode}
                          removeNode={removeNode}
                          nodes={nodes}
                          edges={edges}
                          editFlow={editFlow}
                          updateBot={updateBot}
                          truncateText={truncateText}
                          handleClickModalVariables={handleClickModalVariables}
                          onEmojiClick={onEmojiClick}
                          customStyles={customStyles}
                          customStylesDark={customStylesDark}
                          isDark={isDark}
                          listAreas={listAreas}
                          listAdvisers={listAdvisers}
                          handleCursorPosition={handleCursorPosition}
                          watchSelectedNode={watchSelectedNode}
                        />
                      )}
                      {/* Tags */}
                      {!selectedLog && selectedNode?.type == 'tags' && (
                        <ViewTags
                          selectedNode={selectedNode}
                          handleSelectedNode={setSelectedNode}
                          removeNode={removeNode}
                          nodes={nodes}
                          edges={edges}
                          editFlow={editFlow}
                          updateBot={updateBot}
                          truncateText={truncateText}
                          customStyles={customStyles}
                          customStylesDark={customStylesDark}
                          isDark={isDark}
                          listTags={listTags}
                          watchSelectedNode={watchSelectedNode}
                        />
                      )}
                      {/* Assistant AI */}
                      {!selectedLog && selectedNode?.type == 'assistantAI' && (
                        <ViewAssistant
                          selectedNode={selectedNode}
                          handleSelectedNode={setSelectedNode}
                          removeNode={removeNode}
                          nodes={nodes}
                          edges={edges}
                          editFlow={editFlow}
                          updateBot={updateBot}
                          truncateText={truncateText}
                          handleClickModalVariables={handleClickModalVariables}
                          onEmojiClick={onEmojiClick}
                          customStyles={customStyles}
                          customStylesDark={customStylesDark}
                          isDark={isDark}
                          listAssistants={listAssistants}
                          handleCursorPosition={handleCursorPosition}
                          watchSelectedNode={watchSelectedNode}
                        />
                      )}
                      {/* SaveAnswer */}
                      {!selectedLog && selectedNode?.type == 'saveAnswer' && (
                        <ViewSaveAnswer
                          selectedNode={selectedNode}
                          handleSelectedNode={setSelectedNode}
                          removeNode={removeNode}
                          nodes={nodes}
                          edges={edges}
                          editFlow={editFlow}
                          updateBot={updateBot}
                          truncateText={truncateText}
                          customStyles={customStyles}
                          customStylesDark={customStylesDark}
                          isDark={isDark}
                          correspondence={correspondence}
                          watchSelectedNode={watchSelectedNode}
                        />
                      )}
                      {/* FlowsWhatsApp */}
                      {!selectedLog && selectedNode?.type == 'flowsWhatsApp' && (
                        <ViewFlowsWhatsApp
                          selectedNode={selectedNode}
                          handleSelectedNode={setSelectedNode}
                          removeNode={removeNode}
                          nodes={nodes}
                          edges={edges}
                          editFlow={editFlow}
                          updateBot={updateBot}
                          truncateText={truncateText}
                          handleClickModalVariables={handleClickModalVariables}
                          onEmojiClick={onEmojiClick}
                          customStyles={customStyles}
                          customStylesDark={customStylesDark}
                          isDark={isDark}
                          flows={flows}
                          watchSelectedNode={watchSelectedNode}
                        />
                      )}
                      {/* Transfer QueryApi */}
                      {!selectedLog && selectedNode?.type == 'queryApi' && (
                        <ViewQueryApi
                          selectedNode={selectedNode}
                          handleSelectedNode={setSelectedNode}
                          removeNode={removeNode}
                          nodes={nodes}
                          edges={edges}
                          editFlow={editFlow}
                          updateBot={updateBot}
                          truncateText={truncateText}
                          handleClickModalVariables={handleClickModalVariables}
                          onEmojiClick={onEmojiClick}
                          customStyles={customStyles}
                          customStylesDark={customStylesDark}
                          isDark={isDark}
                          handleCursorPosition={handleCursorPosition}
                          handleEditModal={setEditModal}
                          handleModalHeaders={setModalHeaders}
                          handeHeaderParams={setHeaderParams}
                          handleModalRequestParams={setModalRequestParams}
                          handleRequestParams={setRequestParams}
                          handleModalRequestParamsBody={setModalRequestParamsBody}
                          handleRequestParamsBody={setRequestParamsBody}
                          handleModalResponseParams={setModalResponseParams}
                          handleResponseParams={setResponseParams}
                          watchSelectedNode={watchSelectedNode}
                        />
                      )}
                    </div>
                  </div>

                  {errorMonitoring && (
                    <ErrorMonitor
                      handleErrorMonitoring={handleErrorMonitoring}
                      goToInitialNode={goToInitialNode}
                      goToNode={goToNode}
                      findNodeColor={findNodeColor}
                      listDisconnectedNodes={listDisconnectedNodes}
                      listDisconnectedOptions={listDisconnectedOptions}
                      listNodesWithErrorInFields={listNodesWithErrorInFields}
                      triggersDuplicateCount={triggersDuplicateCount}
                      transitionsDuplicateCount={transitionsDuplicateCount}
                      animateClass={animateClass}
                      isDark={isDark}
                    />
                  )}

                  {showPreview && (
                    <Preview
                      handlePreview={handlePreview}
                      chatBot={editFlow}
                      animateClass={animateClass}
                      isDark={isDark}
                      countries={Countries}
                      customStyles={customStyles}
                      customStylesDark={customStylesDark}
                    />
                  )}

                  {showLogs && (
                    <HistoryVersions
                      selectLog={selectLog}
                      handleShowLogs={handleShowLogs}
                      listLogs={listLogs}
                      animateClass={animateClass}
                      isDark={isDark}
                    />
                  )}

                </div>
              </ReactFlow>
            </div>
          </section>
        </>
      )}
      
      {/* MODAL HEADERS PARAMS */}
      <Modal
        status={modalHeaders}
        changeStatus={setModalHeaders}
        title="Cabecera"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Clave de la Cabecera: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={headerParams.key}
                  onChange={(e) => 
                    setHeaderParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Valor de la Cabecera: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={headerParams.value}
                  onChange={(e) => 
                    setHeaderParams((prev) => ({
                      ...prev,
                      value: e.target.value
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalHeaders(false)
              setEditModal(false)
              setHeaderParams({})
            }}>
              Volver
            </a>
            <input className="btn btn-lg btn-info" style={{width: "150px"}} value="Guardar" 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.headers, 
                    headerParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      headers: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.headers
                  data[headerParams.index].key = headerParams.key
                  data[headerParams.index].value = headerParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      headers: data
                    },
                  }))
                }
                setModalHeaders(false)
                setEditModal(false)
                setHeaderParams({})
              }}
            />
          </div>
        </div>
      </Modal>
      
      {/* MODAL REQUEST PARAMS */}
      <Modal
        status={modalRequestParams}
        changeStatus={setModalRequestParams}
        title="Parámetros de petición"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Parámetro: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={requestParams.key}
                  onChange={(e) => 
                    setRequestParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Valor: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={requestParams.value}
                  onChange={(e) => 
                    setRequestParams((prev) => ({
                      ...prev,
                      value: e.target.value
                    }))
                  }
                />
              </div>
              <div className="pt-2">
                <button 
                  className="text-dark border-0 bg-transparent d-block pointer" 
                  type="button"
                  onClick={(event) => handleClickModalVariables(event, 'value', 'modalRequestParams')}
                >
                  <VscVariableGroup style={{ fontSize: "18px" }}/>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalRequestParams(false)
              setEditModal(false)
              setRequestParams({})
            }}>
              Volver
            </a>
            <button className="btn btn-lg btn-info" style={{width: "150px"}} 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.requestParams, 
                    requestParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParams: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.requestParams
                  data[requestParams.index].key = requestParams.key
                  data[requestParams.index].value = requestParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParams: data
                    },
                  }))
                }
                setModalRequestParams(false)
                setEditModal(false)
                setRequestParams({})
              }}
              disabled={!requestParams.key || !requestParams.value}
            >
              Guardar
            </button>
          </div>
        </div>
      </Modal>

      {/* MODAL REQUEST PARAMS BODY */}
      <Modal
        status={modalRequestParamsBody}
        changeStatus={setModalRequestParamsBody}
        title="Parámetros de petición Body"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Parámetro: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={requestParamsBody.key}
                  onChange={(e) => 
                    setRequestParamsBody((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Valor: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={requestParamsBody.value}
                  onChange={(e) => 
                    setRequestParamsBody((prev) => ({
                      ...prev,
                      value: e.target.value
                    }))
                  }
                />
              </div>
              <div className="pt-2">
                <button 
                  className="text-dark border-0 bg-transparent d-block pointer" 
                  type="button"
                  onClick={(event) => handleClickModalVariables(event, 'value', 'modalRequestParamsBody')}
                >
                  <VscVariableGroup style={{ fontSize: "18px" }}/>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalRequestParamsBody(false)
              setEditModal(false)
              setRequestParamsBody({})
            }}>
              Volver
            </a>
            <button className="btn btn-lg btn-info" style={{width: "150px"}} 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.requestParamsBody, 
                    requestParamsBody
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParamsBody: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.requestParamsBody
                  data[requestParamsBody.index].key = requestParamsBody.key
                  data[requestParamsBody.index].value = requestParamsBody.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParamsBody: data
                    },
                  }))
                }
                setModalRequestParamsBody(false)
                setEditModal(false)
                setRequestParamsBody({})
              }}
              disabled={!requestParamsBody.key || !requestParamsBody.value}
            >
              Guardar
            </button>
          </div>
        </div>
      </Modal>
      
      {/* MODAL RESPONSE PARAMS */}
      <Modal
        status={modalResponseParams}
        changeStatus={setModalResponseParams}
        title="Parámetros de respuesta"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Parámetro: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={responseParams.key}
                  onChange={(e) => 
                    setResponseParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
                <RichTreeView
                  sx={{ color: '#9fb4b9' }}  
                  items={listRequestParams}
                  onSelectedItemsChange={handleItemValSelect}
                />
                <p className="mt-2 p-2" style={{backgroundColor: "#F1F1F1", borderRadius: "4px"}}>
                  Para que se listen opciones de variables, 
                  debe realizar una consulta previa a la Api para listar los datos.
                </p>
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Variable: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={responseParams.value}
                  readOnly 
                />
                <div className="pt-2">
                  <button 
                    className="text-dark border-0 bg-transparent d-block pointer" 
                    type="button"
                    onClick={(event) => handleClickModalVariables(event, 'value', 'modalResponseParams')}
                  >
                    <VscVariableGroup style={{ fontSize: "18px" }}/>
                  </button>
                </div>
                <p className="mt-1">
                  Variable en la que se desea guardar la respuesta (Opcional).
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalResponseParams(false)
              setEditModal(false)
              setResponseParams({})
            }}>
              Volver
            </a>
            <button className="btn btn-lg btn-info" style={{width: "150px"}}
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.responseParams, 
                    responseParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      responseParams: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.responseParams
                  data[responseParams.index].key = responseParams.key
                  data[responseParams.index].value = responseParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      responseParams: data
                    },
                  }))
                }
                setModalResponseParams(false)
                setEditModal(false)
                setResponseParams({})
              }}
              disabled={!responseParams.key}
            >
              Guardar
            </button>
          </div>
        </div>
      </Modal>

      {/* MODAL REQUEST RESPONSE API */}
      <Modal
        status={modalConnectionTest}
        changeStatus={setModalConnectionTest}
        title="Prueba de Conexión"
        width={"550px"}
      >
        <div 
          id="content-list-chats"
          className="justify-content-center p-2"
          style={{
            maxHeight: "450px",
            overflowY: "auto",
            borderRadius: "4px"
          }}
        >
          <div 
            className="p-2" 
            style={{
              backgroundColor: 'rgba(126, 204, 216, 0.2)', 
              borderRadius: '4px',
              fontSize: 11,
            }}
          >
            <span style={{fontWeight: 600}}>Importante: </span> 
            Esta prueba funcionará con los datos ya previamente configurados como URL de la api y Protocol HTTP. 
            Por otro lado, los parametros como cabecera, parametros de petición entre otros, deben configurarse
            en la prueba directamente.
          </div>
          <div 
            className="mt-4 py-2 px-3"
            style={{
              border: "1px solid #545454",
              borderRadius: "4px"
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>
                Cabeceras (Opcional)
              </div>
              <div>
                <button
                  type="button"
                  style={{
                    border: 0,
                    borderRadius: "4px",
                    color: "#7ECCD8",
                    backgroundColor: "rgba(126, 204, 216, 0.3)",
                    cursor: "pointer",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "150px",
                    zIndex: 100,
                  }}
                  onClick={() => {
                    const newHeader = { key: "", value: "" };
                    setConnectionTestData((prev) => ({
                      ...prev,
                      headers: [...(prev.headers || []), newHeader],
                    }));
                  }}                                   
                > 
                  <IoMdAdd/>
                  Agregar Cabecera
                </button>
              </div>
            </div>
            {connectionTestData?.headers?.length > 0 && connectionTestData.headers.map((res, index) => (
              <div className="d-flex justify-content-around mt-2" key={index}>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <input
                      className="form-control"
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.key || ""}
                      onChange={(e) => {
                        const newHeaders = [...connectionTestData.headers];
                        newHeaders[index].key = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          headers: newHeaders,
                        }));
                      }}
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      className="form-control"
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.value || ""}
                      onChange={(e) => {
                        const newHeaders = [...connectionTestData.headers];
                        newHeaders[index].value = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          headers: newHeaders,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button 
                    className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                    onClick={() => {
                      setConnectionTestData((prev) => ({
                        ...prev,
                        headers: prev.headers.filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    <BsTrash3 style={{ fontSize: "16px" }}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div 
            className="mt-4 py-2 px-3"
            style={{
              border: "1px solid #545454",
              borderRadius: "4px"
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>
                Parametros (Opcional)
              </div>
              <div>
                <button
                  type="button"
                  style={{
                    border: 0,
                    borderRadius: "4px",
                    color: "#7ECCD8",
                    backgroundColor: "rgba(126, 204, 216, 0.3)",
                    cursor: "pointer",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "150px",
                    zIndex: 100,
                  }}
                  onClick={() => {
                    const newParam = { key: "", value: "" };
                    setConnectionTestData((prev) => ({
                      ...prev,
                      params: [...(prev.params || []), newParam],
                    }));
                  }}                                   
                > 
                  <IoMdAdd/>
                  Agregar Parametro
                </button>
              </div>
            </div>
            {connectionTestData?.params?.length > 0 && connectionTestData.params.map((res, index) => (
              <div className="d-flex justify-content-around mt-2" key={index}>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <input
                      className="form-control"
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.key || ""}
                      onChange={(e) => {
                        const newParams = [...connectionTestData.params];
                        newParams[index].key = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          params: newParams,
                        }));
                      }}
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      className="form-control"
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.value || ""}
                      onChange={(e) => {
                        const newParams = [...connectionTestData.params];
                        newParams[index].value = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          params: newParams,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button 
                    className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                    onClick={() => {
                      setConnectionTestData((prev) => ({
                        ...prev,
                        params: prev.params.filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    <BsTrash3 style={{ fontSize: "16px" }}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div 
            className="mt-4 py-2 px-3"
            style={{
              border: "1px solid #545454",
              borderRadius: "4px"
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>
                Cuerpo (Opcional)
              </div>
              <div>
                <button
                  type="button"
                  style={{
                    border: 0,
                    borderRadius: "4px",
                    color: "#7ECCD8",
                    backgroundColor: "rgba(126, 204, 216, 0.3)",
                    cursor: "pointer",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "150px",
                    zIndex: 100,
                  }}
                  onClick={() => {
                    const newData = { key: "", value: "" };
                    setConnectionTestData((prev) => ({
                      ...prev,
                      data: [...(prev.data || []), newData],
                    }));
                  }}                                   
                > 
                  <IoMdAdd/>
                  Agregar Dato
                </button>
              </div>
            </div>
            {connectionTestData?.data?.length > 0 && connectionTestData.data.map((res, index) => (
              <div className="d-flex justify-content-around mt-2" key={index}>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <input
                      className="form-control"
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.key || ""}
                      onChange={(e) => {
                        const newData = [...connectionTestData.data];
                        newData[index].key = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          data: newData,
                        }));
                      }}
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      className="form-control"
                      type="text"
                      style={{width: "100%", height: "35px"}}
                      value={res.value || ""}
                      onChange={(e) => {
                        const newData = [...connectionTestData.data];
                        newData[index].value = e.target.value;
                        setConnectionTestData((prev) => ({
                          ...prev,
                          data: newData,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button 
                    className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                    onClick={() => {
                      setConnectionTestData((prev) => ({
                        ...prev,
                        data: prev.data.filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    <BsTrash3 style={{ fontSize: "16px" }}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 d-flex align-items-center">
            <button
              type="button"
              style={{
                border: 0,
                borderRadius: "4px",
                color: "#fff",
                backgroundColor: "#7ECCD8",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "12px",
                fontWeight: "bold",
                width: "80px",
                height: "30px",
                zIndex: 100,
              }}
              onClick={() => requestApiNode()}
              disabled={!selectedNode?.data?.url_api || !selectedNode?.data?.protocol}
            > 
              <div className="d-flex align-items-center">
                <BiTransfer className="mr-1" style={{fontSize: "15px"}}/>
                <span>
                  Probar
                </span>
              </div>
            </button>
            {Object.keys(requestResponse).length > 0 && (
              <button
                type="button"
                className="ml-2"
                style={{
                  border: 0,
                  borderRadius: "4px",
                  color: "#fff",
                  backgroundColor: "#7ECCD8",
                  cursor: "pointer",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "150px",
                  height: "30px",
                  zIndex: 100,
                }}
                onClick={(event) => {responseToNode(event)}}
              > 
                <span>
                  Respuesta a Nodo
                </span>
              </button>
            )}
          </div>
          <div className="scrollable-content">
            <AceEditor
              mode="json"
              theme={isDark == 'true' ? 'github_dark' : 'github'}
              value={JSON.stringify(requestResponse, null, 2)}
              readOnly
              editorProps={{ $blockScrolling: true }}
              setOptions={{ useWorker: false }}
              style={{ width: '100%', height: '200px' }}
            />
          </div>
        </div>
      </Modal>

      {/* MODAL VARIABLES */}
      <Dialog
        open={variablesModal}
        onClose={() => setVariablesModal(false)}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            position: 'absolute',
            top: anchorEl ? anchorEl.getBoundingClientRect().top - 30 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
            minWidth: '200px',
            padding: '10px',
            overflow: 'visible',
            backgroundColor: isDark == 'true' ? '#162229' : '#fff',
            zIndex: 1200
          },
        }}
      > 
        <label id="variables" style={{color: "#455a64"}}>Variables: </label>
        <div className="su-control-group d-flex">
          <Select
            styles={isDark == 'true' ? {...customStylesDark, menuPortal: base => ({ ...base, zIndex: 1300 })} : {...customStyles, menuPortal: base => ({ ...base, zIndex: 1300 })}}
            className="form-control basic-multi-select"
            placeholder="Seleccione..."
            name="variables"
            onChange={(val) => {
              if(typeInput == "node") {
                setSelectedNode((prevInputValue) => ({
                  ...prevInputValue,
                  data: {
                    ...prevInputValue.data,
                    [typeInputValue]: prevInputValue.data[typeInputValue]? prevInputValue.data[typeInputValue] + " " + val.fieldCorresp : val.fieldCorresp
                  },
                }))
              } else if(typeInput == "edgeCondition") {
                setSelectedEdge((prevEdge) => {
                  let conditions = [...prevEdge.conditions];
                  const keys = typeInputValue.replace(/\[|\]/g, '').split('.');
                  let obj = conditions;
                  for (let i = 0; i < keys.length - 1; i++) {
                    const key = keys[i];
                    if (!obj[key]) {
                      obj[key] = {};
                    }
                    obj = obj[key];
                  }
                  obj[keys[keys.length - 1]] = val.fieldCorresp;
                
                  return {
                    ...prevEdge,
                    conditions: conditions,
                  };
                });                
              } else if(typeInput == "modalRequestParams") {
                setRequestParams((prevInputValue) => ({
                  ...prevInputValue,
                  [typeInputValue]: prevInputValue[typeInputValue] ? prevInputValue[typeInputValue] + " " + val.fieldCorresp : val.fieldCorresp
                }))
              } else if(typeInput == "modalRequestParamsBody") {
                setRequestParamsBody((prevInputValue) => ({
                  ...prevInputValue,
                  [typeInputValue]: prevInputValue[typeInputValue] ? prevInputValue[typeInputValue] + " " + val.fieldCorresp : val.fieldCorresp
                }))
              } else {
                setResponseParams((prevInputValue) => ({
                  ...prevInputValue,
                  [typeInputValue]: prevInputValue[typeInputValue] ? prevInputValue[typeInputValue] + " " + val.fieldCorresp : val.fieldCorresp
                }))
              }
            }}
            options={typeInput === 'edgeCondition' ? [{fieldName: 'Respuesta nodo', fieldCorresp: '{{response_node}}'}, ...correspondence] : correspondence}
            classNamePrefix="select"
            noOptionsMessage={() => "Sin opciones"}
            getOptionLabel={(option) => (
                <div className="option d-flex" style={{fontSize: "15px"}}>
                    {option.fieldName}
                </div>
            )}
            getOptionValue={(option) => option.fieldName}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
          />
        </div>
      </Dialog>

      {/* MODAL EDIT NAME */}
      <Modal
        status={showEditName}
        changeStatus={setShowEditName}
        title="Editar nombre"
        action={() => null}
        width="350px"
      >
        <div 
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <div>
              <input
                className="form-control"
                type="text"
                name="name"
                id="name"
                value={editName}
                onChange={(e) => {
                  setEditName(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="row justify-content-end bd col-12 mt-4">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setShowEditName(false);
                setEditName("");
              }}
            >
              Cancelar
            </a>
            <button
              href="javascript:;"
              className="btn btn-lg btn-info mr-1"
              onClick={() => updateNameBot(editFlow)}
              disabled={!editName.trim()}
            >
              Guardar
            </button> 
          </div>
        </div>
      </Modal>

      {/* MODAL RESPONSE TO NODE */}
      <Dialog
        open={responseToNodeModal}
        onClose={() => setResponseToNodeModal(false)}
        anchorEl={anchorEl2}
        PaperProps={{
          style: {
            position: 'absolute',
            top: anchorEl2 ? anchorEl2.getBoundingClientRect().top - 150 : 0,
            left: anchorEl2 ? anchorEl2.getBoundingClientRect().left + 100 : 0,
            minWidth: '200px',
            maxHeight: "400px",
            padding: '20px',
            overflow: 'visible',
            overflowY: "auto",
            backgroundColor: isDark == 'true' ? '#162229' : '#fff',
            zIndex: 1200
          },
        }}
      > 
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}> 
            <IoClose style={{color: "#61676B"}} onClick={() => {
              setResponseToNodeModal(false);
              setListToNodeData({
                description: null,
                options: []
              });
            }}/>
          </div>
          <div className="text-center pb-2" style={{color: "#545454", fontWeight: 700}}>
            Agregar botones u opciones
          </div>
          <div>
            <textarea
              className="form-control"
              placeholder="Escribe la respuesta del Bot"
              style={{ width: "100%", height: "80px", borderRadius: "4px" }}
              value={listToNodeData.description || ""}
              onChange={(e) => {
                setListToNodeData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
            />
          </div>
          <div>
            <button
              type="button"
              style={{
                border: 0,
                borderRadius: "4px",
                color: "#7ECCD8",
                backgroundColor: "rgba(126, 204, 216, 0.3)",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100%",
                zIndex: 100,
              }}
              onClick={() => {
                const length = listToNodeData.options.length
                const newOption = {
                  label: "",
                  id: selectedNode.id+'-'+(length+1)+'-Opción-' + (length +1),
                  name: 'titulo',
                }
                setListToNodeData((prev) => ({
                  ...prev,
                  options: [...(prev.options || []), newOption],
                }));
              }}  
              disabled={listToNodeData.options.length >= 10}                                 
            > 
              <IoMdAdd/>
              Agregar Opción
            </button>
          </div>
          {listToNodeData?.options?.length > 0 && listToNodeData.options.map((opt, index) => {
            const maxLengthValue = listToNodeData?.options?.length > 3 ? 24 : 20;
            return (
              <div 
                className="d-flex justify-content-around mt-2" 
                key={index}
              >
                <div style={{width: "80%", height: "35px"}}>
                  <input
                    className="form-control"
                    type="text"
                    value={opt.label || ""}
                    onChange={(e) => {
                      const newOption = [...listToNodeData.options];
                      newOption[index].label = e.target.value;
                      setListToNodeData((prev) => ({
                        ...prev,
                        options: newOption,
                      }));
                    }}
                    maxLength={maxLengthValue}
                  />
                </div>
                <div className="ml-2 d-flex align-intems-center">
                  <button 
                    className="border-0 bg-transparent d-block pointer mt-2" 
                    onClick={(event) => {
                      setVariablesOptionsModal(true);
                      setAnchorEl(event.currentTarget);
                      setIndexOptionResToNode(index);
                    }}
                  >
                    <LuListTree style={{ fontSize: "16px", color: "#61676B" }}/>
                  </button>
                  <button 
                    className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                    onClick={() => {
                      setListToNodeData((prev) => ({
                        ...prev,
                        options: prev.options.filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    <BsTrash3 style={{ fontSize: "16px" }}/>
                  </button>
                </div>
              </div>
            )
          })}
          <div className="mt-2">
            <button
              type="button"
              style={{
                backgroundColor: "#7ECCD8",
                border: 0,
                borderRadius: "4px",
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "12px",
                fontWeight: "bold",
                height: "30px",
                width: "100%",
              }}
              className="mr-2"
              onClick={convertToNode}
            >   
              <span>Convertir</span>
            </button>
          </div>
        </div>
      </Dialog>

      {/* MODAL VARIABLES OPTIONS RESPONSE TO NODE*/}
      <Dialog
        open={variablesOptionsModal}
        onClose={() => setVariablesOptionsModal(false)}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            position: 'absolute',
            top: anchorEl ? anchorEl.getBoundingClientRect().top - 30 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
            minWidth: '200px',
            maxHeight: '250px',
            padding: '10px',
            overflow: 'visible',
            overflowY: "auto",
            backgroundColor: isDark == 'true' ? '#162229' : '#fff',
            zIndex: 1200
          },
        }}
      > 
        <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}> 
          <IoClose style={{color: "#61676B"}} onClick={() => setVariablesOptionsModal(false)}/>
        </div>
        <RichTreeView
          sx={{ color: '#9fb4b9' }}  
          items={listOptionsResToNode}
          onSelectedItemsChange={(event, val) => {

            if(val !== 'response') {
              const maxLengthValue = listToNodeData?.options?.length > 3 ? 24 : 20;
              const selectedNode = findNodeById(listOptionsResToNode, val);

              if (selectedNode) {
                const selectedLabel = selectedNode.label;
                let selectedValue = selectedLabel.substring(selectedLabel.indexOf(':') + 2); 

                if (selectedValue.length > maxLengthValue) { 
                  selectedValue = selectedValue.substring(0, maxLengthValue);
                }

                const newOption = [...listToNodeData.options];
                newOption[indexOptionResToNode].label = selectedValue; 
                setListToNodeData((prev) => ({
                  ...prev,
                  options: newOption,
                }));
              }
            }
          }}
       />
      </Dialog>

      {/* MODAL CONDITIONS*/}
      <Dialog
        open={showConditionsModal}
        anchorEl={anchorElConditionsModel}
        PaperProps={{
          style: {
            position: 'absolute',
            left: anchorElConditionsModel ? anchorElConditionsModel.getBoundingClientRect().left : 0,
            minWidth: '450px',
            maxHeight: '95%',
            padding: '10px',
            overflow: 'visible',
            overflowY: 'auto',
            backgroundColor: isDark == 'true' ? '#111b21' : '#fff',
            color: '#455a64',
            zIndex: 1200,
            paddingInline: '12px'
          },
        }}
      >
        <form onSubmit={handleSubmit(saveConditions)} style={{ width: "100%" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ color: isDark === 'true' ? '#f1f1f1' : '#61676B', fontSize: '18px', fontWeight: 600 }}>Configurar Condición</div>
            <IoClose style={{ color: '#455a64', cursor: 'pointer', fontSize: '18px' }} onClick={() => setShowConditionsModal(false)} />
          </div>
          <div>
            <hr style={{ border: 'none', height: '2px', backgroundColor: '#f1f1f1', margin: '10px 0' }} />
          </div>
          <div style={{ overflowY: 'auto' }} id="content-list-chats">
            {selectedEdge?.conditions?.length > 0 && (
              <div className="px-3 pb-2">
                <label htmlFor="conditions_message" style={{color: '#6C757D', fontWeight: 500}}>Mensaje de control: </label>
                <textarea
                  className="form-control"
                  id="conditions_message"
                  name="conditions_message"
                  type="text"
                  style={{width: "100%", height: "60px", borderRadius: "4px"}}
                  value={selectedEdge?.message_control || ""}
                  {...register("conditions_message", {
                    required: {
                      value: true,
                      message: "El campo es requerido."
                    },
                    pattern: {
                      value: /\S+/,
                      message: "No puede ser solo espacios en blanco",
                    },
                    onChange: (e) => {
                      setSelectedEdge((edge) => {
                        return {
                          ...edge,
                          message_control: e.target.value
                        };
                      });
                    }
                  })}
                />
                {errors.conditions_message && <Alert>* {errors.conditions_message.message}</Alert>}
              </div>
            )}
            {selectedEdge?.conditions?.length > 0 && (
              <div style={{color: isDark === 'true' ? '#f1f1f1' : '#61676B'}}>
                Condición:
              </div>
            )}
            <div className="pt-2">
              {selectedEdge?.conditions?.map((condition, index) => (
                <div key={index}>
                  {index > 0 && (
                    condition.type === "OR" ? (
                      <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0', paddingInline: "12px" }}>
                        <hr style={{ flex: 1, border: 'none', height: '1px', backgroundColor: '#455a64' }} />
                        <div style={{ padding: '0 10px', color: '#455a64', fontWeight: 'bold' }}>OR</div>
                        <hr style={{ flex: 1, border: 'none', height: '1px', backgroundColor: '#455a64' }} />
                      </div>
                    ) : (
                      <div style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0' }}>
                        {condition.type}
                      </div>
                    )
                  )}
                  <div className="d-flex align-items-start col px-3">
                    <div style={{ width: '100%' }}>
                      <div className="pb-2">
                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                          <VscVariableGroup
                            style={{
                              position: 'absolute',
                              left: '10px',
                              color: '#61676B',
                              cursor: 'pointer',
                              fontSize: '24px',
                              paddingRight: '4px'
                            }}
                            onClick={(event) => handleClickModalVariables(
                              event,
                              `[${index}].rule.value1`, 
                              'edgeCondition'
                            )}
                          />
                          <input
                            className="form-control"
                            type="text"
                            style={{ width: '100%', height: '35px', paddingLeft: '35px' }}
                            value={condition.rule?.value1 || ''}
                            {...register(`conditions[${index}].rule.value1`, {
                              required: { 
                                value: true, 
                                message: "Este campo es requerido." 
                              },
                              pattern: {
                                value: /\S+/,
                                message: "No puede ser solo espacios en blanco",
                              },
                              onChange: (e) => {
                                setSelectedEdge((edge) => {
                                  edge.conditions[index].rule.value1 = e.target.value;
                                  return { ...edge, conditions: edge.conditions };
                                });
                              }
                            })}
                          />
                        </div>
                        {errors?.conditions?.[index]?.rule?.value1 && (
                          <Alert>
                            * {errors.conditions[index].rule.value1.message}
                          </Alert>
                        )}
                      </div>
                      <div className="su-control-group d-flex pb-2">
                        <Select
                          styles={{
                            ...(isDark === 'true' ? customStylesDark : customStyles),
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            menuList: (provided) => ({
                              ...provided,
                              scrollbarWidth: 'thin',
                            }),
                          }}
                          classNamePrefix="select"
                          className="form-control basic-multi-select"
                          placeholder="Seleccionar operador"
                          options={listOptionsOperators}
                          name={`conditions[${index}].rule.operator`}
                          onChange={(value) => {
                            setSelectedEdge((edge) => {
                              edge.conditions[index].rule.operator = value;
                              edge.conditions[index].rule.operatorType = value.type;
                              return { ...edge, conditions: edge.conditions };
                            });
                          }}
                          value={condition.rule.operator || null}
                          noOptionsMessage={() => 'Sin opciones'}
                          getOptionLabel={(option) => (
                            <div className="option d-flex" style={{ fontSize: '15px' }}>
                              {option.label}
                            </div>
                          )}
                          getOptionValue={(option) => option.id}
                          components={{ SingleValue: customSingleValue }}
                          menuPortalTarget={document.body}
                        />
                        {errors?.conditions?.[index]?.rule?.operator && (
                          <Alert>
                            * {errors.conditions[index].rule.operator.message}
                          </Alert>
                        )}
                      </div>
                      <div>
                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                          <VscVariableGroup
                            style={{
                              position: 'absolute',
                              left: '10px',
                              color: '#61676B',
                              cursor: 'pointer',
                              fontSize: '24px',
                              paddingRight: '4px'
                            }}
                            onClick={(event) => handleClickModalVariables(
                              event,
                              `[${index}].rule.value2`, 
                              'edgeCondition'
                            )}
                          />
                          <input
                            className="form-control"
                            type="text"
                            style={{ width: '100%', height: '35px', paddingLeft: '35px' }}
                            value={condition.rule?.value2 || ''}
                            {...register(`conditions[${index}].rule.value2`, {
                              required: { 
                                value: true, 
                                message: "Este campo es requerido." 
                              },
                              pattern: {
                                value: /\S+/,
                                message: "No puede ser solo espacios en blanco",
                              },
                              onChange: (e) => {
                                setSelectedEdge((edge) => {
                                  edge.conditions[index].rule.value2 = e.target.value;
                                  return { ...edge, conditions: edge.conditions };
                                });
                              }
                            })}
                          />
                        </div>
                        {errors?.conditions?.[index]?.rule?.value2 && (
                          <Alert>
                            * {errors.conditions[index].rule.value2.message}
                          </Alert>
                        )}
                      </div>
                    </div>
                    <button className="border-0 bg-transparent d-block pointer ml-1" onClick={() => removeCondition(index)}>
                      <BsTrash3 style={{ fontSize: '18px', color: isDark === 'true' ? '#f1f1f1' : '#61676B' }} />
                    </button>
                  </div>
                </div>
              ))}
              <div className="d-flex pl-3 pt-4">
                <button
                  className="btn mr-3"
                  style={{
                    color: "#fff",
                    backgroundColor: "#00A884"
                  }}
                  type="button"
                  onClick={() => addRule('and')}
                >
                  Agregar Regla AND
                </button>
                <button
                  className="btn"
                  style={{
                    color: "#fff",
                    backgroundColor: "#00A884"
                  }}
                  type="button"
                  onClick={() => addRule('or')}
                >
                  Agregar Regla OR
                </button>
              </div>
            </div>
          </div>
          <div>
            <hr style={{ border: 'none', height: '2px', backgroundColor: '#f1f1f1', margin: '14px 0' }} />
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setShowConditionsModal(false)
              }}
            >
              Cancelar
            </a>
            <input
              className="btn btn-lg btn-info"
              type="submit"
              value="Guardar"
            />
          </div>
        </form>
      </Dialog>

      {/* MODAL PARA REMOVER ESTADOS */}
      <Modal
        status={showRemoveStatusesModal}
        changeStatus={setShowRemoveStatusesModal}
        title="Limpiar Estados"
        action={() => null}
        width="350px"
      >
        <RemoveStatuses
          statusModal={setShowRemoveStatusesModal}
          editFlow={editFlow}
          isDark={isDark}
          countries={Countries}
          customStyles={customStyles}
          customStylesDark={customStylesDark}
        />
      </Modal>

      {/* MODAL CONFIRM TEMPLATE */}
      <Modal
          status={showModalCreateTemplate}
          changeStatus={setShowModalCreateTemplate}
          title="Confirmar Plantilla"
          action={() => null}
          width="350px"
      >
        <div 
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
          <div>
            <div className="text-center">
              ¿Estas seguro de crear el bot a partir esta plantilla?
            </div>
          </div>

          <div className="row justify-content-end bd col-12 mt-4">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setNameBot("");
                setDescriptionBot("");
                setNodes([]);
                setEdges([]);
                setShowModalCreateTemplate(false);
              }}
            >
              Cancelar
            </a>
            <button
              href="javascript:;"
              className="btn btn-lg btn-info mr-1"
              onClick={() => {
                setShowModalCreateTemplate(false);
                setMode("create");
                createBot();
              }}
            >
              Guardar
            </button> 
          </div>
        </div>
      </Modal>

      {/* MODAL CREATE BOT */}
      <Modal
        status={modalCreate}
        changeStatus={setModalCreate}
        title="Crear Bot"
        width="550px"
      >
        <form onSubmit={handleSubmit(createBot)} style={{ width: "100%" }}>
          <div 
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>
              <label>
                Ingresa un nombre para el bot
              </label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  id="name"
                  {...register("nameBot", {
                    required: {
                      value: modalCreate,
                      message:"El campo es requerido.",
                    },
                    pattern: {
                      value: /\S+/,
                      message: "No puede ser solo espacios en blanco",
                    },
                  })}
                />
                {errors.nameBot && <Alert>* {errors.nameBot.message}</Alert>}
              </div>
              <label className="pt-4">
                Ingresa una descripción para el bot
              </label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  name="description"
                  id="description"
                  {...register("descriptionBot", {
                    required: {
                      value: modalCreate,
                      message:"El campo es requerido.",
                    },
                    pattern: {
                      value: /\S+/,
                      message: "No puede ser solo espacios en blanco",
                    },
                  })}
                />
                {errors.descriptionBot && <Alert>* {errors.descriptionBot.message}</Alert>}
              </div>
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalCreate(false);
                  reset()
                }}
              >
                Cancelar
              </a>
              <input
                className="btn btn-lg btn-info"
                type="submit"
                value="Crear"
              />
            </div>
          </div>
        </form>
      </Modal>

      {/* MODAL CREATE BOT WITH AI */}
      <Modal
        status={modalBotAssistant}
        changeStatus={setModalBotAssistant}
        title="Crear Bot con IA"
        width={"650px"}
      >
        <Content>
          <form onSubmit={handleSubmit(createBotWithIA)} style={{ width: "100%" }}>
            <div className="scrollable-content scrollable-content-overflow">
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable" style={{marginTop: "0px"}}>
                <label>Nombre del Bot: </label>
                <div className="su-control-group">
                  <input
                    className="form-control px-2"
                    rows="4"
                    type="text"
                    name="name"
                    {...register("name_bot_ai", {
                      required: {
                        value: modalBotAssistant,
                        message:"El campo es requerido.",
                      },
                      pattern: {
                        value: /\S+/,
                        message: "No puede ser solo espacios en blanco",
                      },
                    })}
                  ></input>                    
                </div>
                {errors.name_bot_ai && <Alert>* {errors.name_bot_ai.message}</Alert>}
              </div>
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Descripción del Bot: </label>
                <div className="su-control-group">
                  <input
                    className="form-control px-2"
                    rows="4"
                    type="text"
                    name="description"
                    {...register("description_bot_ai", {
                      required: {
                        value: modalBotAssistant,
                        message:"El campo es requerido.",
                      },
                      pattern: {
                        value: /\S+/,
                        message: "No puede ser solo espacios en blanco",
                      },
                    })}
                  ></input>                    
                </div>
                {errors.description_bot_ai && <Alert>* {errors.description_bot_ai.message}</Alert>}
              </div>
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Instrucciones para crear el Bot: </label>
                <label className="f-w-100 font-italic d-block" >
                  Instrucciones para crear tu Bot con IA
                  Cuéntanos brevemente de qué trata tu negocio y qué
                  necesitas que haga el bot. Con esta información podremos 
                  crear un bot básico que se ajuste a tus objetivos.<br/><br/>

                  💬 Ejemplo: “Tengo una tienda de ropa online y quiero un bot que ayude
                  a responder preguntas sobre tallas, medios de pago y tiempos de entrega.”<br/><br/>
                </label>
                <div className="su-control-group">
                  <textarea
                    className="form-control px-2"
                    placeholder="Escribe las instrucciones..."
                    rows="4"
                    type="text"
                    name="content"
                    {...register("instructions_bot", {
                      required: {
                        value: modalBotAssistant,
                        message:"El campo es requerido.",
                      },
                      maxLength: {
                        value: 240000,
                        message: "El máximo es de 240.000 caracteres.",
                      },
                      pattern: {
                        value: /\S+/,
                        message: "No puede ser solo espacios en blanco",
                      },
                    })}
                  ></textarea>                    
                </div>
                {errors.instructions_bot && <Alert>* {errors.instructions_bot.message}</Alert>}
              </div>
            </div>
            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalBotAssistant(false);
                }}
              >
                Cancelar
              </a>
              <input
                className="btn btn-lg btn-info"
                type="submit"
                value="Crear"
              />
            </div>
          </form>
        </Content>
      </Modal> 

      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />

      <Tooltip 
        id="my-tooltip" 
        style={{ zIndex: 9999, backgroundColor: "black", color: "white", padding: "5px 10px", borderRadius: "5px", whiteSpace: "pre-line", maxWidth: "500px" }} 
      />
    </>
  );
};

const FlowWithProvider = () => {
  return (
    <ReactFlowProvider>
      <Flow/>
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;

const Content = styledComponent.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
`;