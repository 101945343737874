import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import styledComponent from "styled-components";
import Select from "react-select";
import { ToastContainer } from "react-toastify";

import { BsTrash3 } from "react-icons/bs";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { FaSpinner } from "react-icons/fa";
import { HiOutlineSave } from "react-icons/hi";

const SaveAnswer = ({
    selectedNode,
    handleSelectedNode,
    removeNode,
    nodes,
    edges,
    editFlow,
    updateBot,
    truncateText,
    customStyles,
    customStylesDark,
    isDark,
    correspondence,
    watchSelectedNode
}) => {
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        user_response: null,
        dynamic_buttons_variable: null,
        dynamic_buttons_variable_description: null,
        regular_expressions_error_message: null,
    });

    const [errorVariable, setErrorVariable] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    // ------> Dropdowns
    const [dropdownRegularExpression, setDropdownRegularExpression] = useState(false);

    // FUNCTIONS

    //# Function para manejar cierre y aparetura de dropdowns
    const handleDropdown = (property) => {
        switch(property) {
          case 'dropdownRegularExpression':
            setDropdownRegularExpression(!dropdownRegularExpression);
            break;
          default:
            console.error(`Property ${property} not recognized`);
        }
    };

    //# Function para manejar cambios de estados de campos
    const handleInputChange = (fieldPath, value) => {
        handleSelectedNode((prevInputValue) => {
            const updateNestedField = (obj = {}, path, val) => {
                const [key, ...rest] = path.split(".");
                return {
                    ...obj,
                    [key]:
                        rest.length > 0
                            ? updateNestedField(
                                  obj[key] || {},
                                  rest.join("."),
                                  val
                              )
                            : val,
                };
            };

            return {
                ...prevInputValue,
                data: updateNestedField(prevInputValue.data, fieldPath, value),
            };
        });
    };

    //# Function para guardar los datos
    const saveData = (values) => {

        if (!selectedNode.data.variable.length) {
            setErrorVariable(true);
            return;
        };

        setLoadingUpdate(true);

        const data = {
            title: values.title,
            user_response: values.user_response,
            flowResponseOrder: values.flow_response_order,
            advanced: {
                ...selectedNode.data?.advanced,
                regularExpressions: {
                    ...selectedNode.data?.advanced?.regularExpressions,
                    errorMessage: values.regular_expressions_error_message,
                },
            },
        };

        handleSelectedNode((prevInputValue) => ({
            ...prevInputValue,
            data: {
                ...prevInputValue.data,
                ...data,
                saved: true
            },
        }))
        setTimeout(async () => {
            await updateBot(editFlow, edges, nodes)
            setLoadingUpdate(false);
        }, 500)
    };

    //# Function para obtner data
    const getData = () => {
        let objectData = {
            title: selectedNode.data?.title ?? null,
            user_response: selectedNode.data?.user_response ?? null,
            flow_response_order: selectedNode.data?.flowResponseOrder,
            regular_expressions_error_message: selectedNode.data?.advanced?.regularExpressions?.errorMessage ?? null
        };        

        reset(objectData, { keepDefaultValues: true });
    }

    useEffect(() => {
        getData();
    }, [watchSelectedNode]);

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-between text-white"
                style={{
                    height: "50px",
                    padding: "10px",
                    borderBottom: "1px solid #CED4DA",
                    backgroundColor: "#D89C7E",
                }}
            >
                <div className="d-flex align-items-center">
                    <HiOutlineSave
                        className="mr-1"
                        style={{ fontSize: "24px" }}
                    />
                    <span style={{ fontSize: "22px", whiteSpace: "nowrap" }}>
                        {truncateText(selectedNode.data.title)}
                    </span>
                </div>
                <button
                    className="text-white border-0 bg-transparent d-block pointer mt-2"
                    onClick={() => {
                        removeNode(selectedNode.id);
                    }}
                >
                    <BsTrash3 style={{ fontSize: "18px" }} />
                </button>
            </div>
            <div
                id="content-list-chats"
                className="card-body"
                style={{ overflowY: "auto" }}
            >   
                <form onSubmit={handleSubmit(saveData)} style={{ width: "100%" }} >
                    <div>
                        <div className="col p-0">
                            <label
                                htmlFor="title"
                                style={{ color: "#6C757D", fontWeight: 600 }}
                            >
                                Título:{" "}
                            </label>
                            <div>
                                <input
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    type="text"
                                    style={{ width: "100%", height: "35px" }}
                                    {...register("title", {
                                        required: {
                                            value: true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.title && <Alert>* {errors.title.message}</Alert>}
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="user_response"
                                style={{ color: "#6C757D", fontWeight: 600 }}
                            >
                                Respuesta del cliente:{" "}
                            </label>
                            <div>
                                <textarea
                                    className="form-control"
                                    id="user_response"
                                    name="user_response"
                                    type="text"
                                    style={{
                                        width: "100%",
                                        height: "50px",
                                        borderRadius: "4px",
                                    }}
                                    {...register("user_response", {
                                        required: {
                                            value: selectedNode.data.first ? false : true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.user_response && <Alert>* {errors.user_response.message}</Alert>}
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 600,
                                    }}
                                >
                                    Mensaje que envía el cliente para activar la
                                    respuesta.
                                </p>
                                <div
                                    className="p-1"
                                    style={{
                                        backgroundColor: "rgba(216, 156, 126, 0.3)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: 11,
                                            color: "#6C757D",
                                            fontWeight: 600,
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Importante:
                                        </span>{" "}
                                        Los nodos se pueden activar con multiples
                                        respuestas según esten configuradas, para
                                        ello debe tener el siguiente formato:
                                        Respuesta 1 / Respuesta 2 / Respuesta 3
                                        configurado en dicho apartado.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label>Variable: </label>
                            <div className="su-control-group d-flex">
                                <Select
                                    styles={
                                        isDark == "true"
                                            ? customStylesDark
                                            : customStyles
                                    }
                                    isMulti
                                    className="form-control basic-multi-select"
                                    placeholder="Seleccione..."
                                    name="variables"
                                    value={selectedNode.data.variable}
                                    onChange={(val) => {
                                        handleInputChange(
                                            "variable",
                                            val
                                        );
                                        setErrorVariable(false);
                                    }}
                                    options={correspondence}
                                    classNamePrefix="select"
                                    noOptionsMessage={() => "Sin opciones"}
                                    getOptionLabel={(option) => (
                                        <div
                                            className="option d-flex"
                                            style={{ fontSize: "15px" }}
                                        >
                                            {option.fieldName}
                                        </div>
                                    )}
                                    getOptionValue={(option) => option.fieldName}
                                />
                            </div>
                            {errorVariable && (
                                <Alert>* Campo requerido</Alert>
                            )}

                            <p
                                style={{
                                    fontSize: 12,
                                    color: "#6C757D",
                                    fontWeight: 800,
                                }}
                                className="pt-2"
                            >
                                Ejecuta la acción y salta al siguiente nodo.
                            </p>

                            <div
                                className="p-1"
                                style={{
                                    backgroundColor: "rgba(216, 156, 126, 0.3)",
                                    borderRadius: "4px",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 600,
                                    }}
                                >
                                    <span style={{ fontSize: 12, fontWeight: 600 }}>
                                        Importante:
                                    </span>{" "}
                                    Ten en cuenta que en las respuestas de flujos
                                    (Flujos de WhatsApp) de acuerdo al json que
                                    retorne y según el orden de sus datos asi se
                                    guardará segun el orden de las variables en las
                                    que se desea almacenar.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-3 mt-4">
                            <input
                                name="regularExpressions"
                                id="regularExpressions"
                                type="checkbox"
                                value={
                                    selectedNode.data?.advanced?.regularExpressions
                                        ?.active || false
                                }
                                className="cmn-toggle cmn-toggle-round-flat"
                                checked={
                                    selectedNode.data?.advanced?.regularExpressions
                                        ?.active || false
                                }
                                onChange={(e) =>
                                    handleInputChange(
                                        "advanced.regularExpressions.active",
                                        e.target.checked
                                    )
                                }
                            />
                            <label
                                className="mr-2 mb-0"
                                htmlFor="regularExpressions"
                            ></label>

                            <div
                                style={{
                                    fontSize: 12,
                                    color: "#6C757D",
                                    fontWeight: 600,
                                }}
                            >
                                Expresiones regulares
                            </div>
                        </div>
                        {selectedNode.data?.advanced?.regularExpressions
                            ?.active && (
                            <div
                                className="col p-0 mt-2 mb-4 panel-group collapsible"
                                id="user-collapsible"
                            >
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading p-2"
                                        style={{
                                            border: "1px solid #4F4F4F",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <div
                                            id="title-regularExpression"
                                            className="panel-title d-flex justify-content-between align-items-center pointer"
                                            onClick={() =>
                                                handleDropdown(
                                                    "dropdownRegularExpression"
                                                )
                                            }
                                        >
                                            <span>Expresiones Regulares</span>
                                            {dropdownRegularExpression ? (
                                                <IoIosArrowDropup
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            ) : (
                                                <IoIosArrowDropdown
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {dropdownRegularExpression ? (
                                        <div
                                            className="panel-collapse p-2"
                                            style={{
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <div
                                                className="panel-body"
                                                style={{ paddingInline: 0 }}
                                            >
                                                <div className="col p-0 mt-4">
                                                    <label>
                                                        Tipo de expresión:{" "}
                                                    </label>
                                                    <div className="su-control-group d-flex">
                                                        <Select
                                                            styles={
                                                                isDark == "true"
                                                                    ? customStylesDark
                                                                    : customStyles
                                                            }
                                                            className="form-control basic-multi-select"
                                                            placeholder="Seleccionar"
                                                            value={
                                                                selectedNode.data
                                                                    ?.advanced
                                                                    ?.regularExpressions
                                                                    ?.type || false
                                                            }
                                                            name="expression_type"
                                                            onChange={(value) =>
                                                                handleInputChange("advanced.regularExpressions.type", value)
                                                            }
                                                            options={[
                                                                {
                                                                    label: "Fecha",
                                                                    id: "date",
                                                                },
                                                                {
                                                                    label: "Hora",
                                                                    id: "hour",
                                                                },
                                                                {
                                                                    label: "Correo",
                                                                    id: "mail",
                                                                },
                                                                {
                                                                    label: "Numérico",
                                                                    id: "number",
                                                                },
                                                                {
                                                                    label: "Teléfono",
                                                                    id: "phone",
                                                                },
                                                            ]}
                                                            classNamePrefix="select"
                                                            noOptionsMessage={() =>
                                                                "Sin opciones"
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => (
                                                                <div
                                                                    className="option d-flex"
                                                                    style={{
                                                                        fontSize:
                                                                            "15px",
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </div>
                                                            )}
                                                            getOptionValue={(
                                                                option
                                                            ) => option.id}
                                                        />
                                                    </div>
                                                </div>
                                                {![
                                                    "number",
                                                    "mail",
                                                    "phone",
                                                ].includes(
                                                    selectedNode.data?.advanced
                                                        ?.regularExpressions?.type
                                                        ?.id
                                                ) && (
                                                    <div className="col p-0 mt-4">
                                                        <label
                                                            htmlFor="expressionFormat"
                                                            style={{
                                                                color: "#6C757D",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            Formato:{" "}
                                                        </label>
                                                        <div>
                                                            <Select
                                                                styles={
                                                                    isDark == "true"
                                                                        ? customStylesDark
                                                                        : customStyles
                                                                }
                                                                className="form-control basic-multi-select"
                                                                placeholder="Seleccionar"
                                                                value={
                                                                    selectedNode
                                                                        .data
                                                                        ?.advanced
                                                                        ?.regularExpressions
                                                                        ?.format ||
                                                                    false
                                                                }
                                                                name="expressionFormat"
                                                                onChange={(value) =>
                                                                    handleInputChange("advanced.regularExpressions.format", value)
                                                                }
                                                                options={
                                                                    selectedNode
                                                                        .data
                                                                        ?.advanced
                                                                        ?.regularExpressions
                                                                        ?.type
                                                                        ?.id ===
                                                                    "date"
                                                                        ? [
                                                                            {
                                                                                label: "mm/dd/aaaa",
                                                                                id: "mm/dd/aaaa",
                                                                            },
                                                                            {
                                                                                label: "mm/dd/aa",
                                                                                id: "mm/dd/aa",
                                                                            },
                                                                            {
                                                                                label: "dd/mm/aaaa",
                                                                                id: "dd/mm/aaaa",
                                                                            },
                                                                            {
                                                                                label: "dd/mm/aa",
                                                                                id: "dd/mm/aa",
                                                                            },
                                                                            {
                                                                                label: "dd-mm-aaaa",
                                                                                id: "dd-mm-aaaa",
                                                                            },
                                                                            {
                                                                                label: "dd-mm-aa",
                                                                                id: "dd-mm-aa",
                                                                            },
                                                                            {
                                                                                label: "mm-dd-aaaa",
                                                                                id: "mm-dd-aaaa",
                                                                            },
                                                                            {
                                                                                label: "mm-dd-aa",
                                                                                id: "mm-dd-aa",
                                                                            },
                                                                            {
                                                                                label: "aaaa-mm-dd",
                                                                                id: "aaaa-mm-dd",
                                                                            },
                                                                            {
                                                                                label: "m/d/aaaa",
                                                                                id: "m/d/aaaa",
                                                                            },
                                                                            {
                                                                                label: "m/d/aa",
                                                                                id: "m/d/aa",
                                                                            },
                                                                            {
                                                                                label: "d/m/aaaa",
                                                                                id: "d/m/aaaa",
                                                                            },
                                                                            {
                                                                                label: "d/m/aa",
                                                                                id: "d/m/aa",
                                                                            },
                                                                            {
                                                                                label: "m-d-aaaa",
                                                                                id: "m-d-aaaa",
                                                                            },
                                                                            {
                                                                                label: "m-d-aa",
                                                                                id: "m-d-aa",
                                                                            },
                                                                            {
                                                                                label: "d-m-aaaa",
                                                                                id: "d-m-aaaa",
                                                                            },
                                                                            {
                                                                                label: "d-m-aa",
                                                                                id: "d-m-aa",
                                                                            },
                                                                            {
                                                                                label: "aaaa-mm-dd",
                                                                                id: "aaaa-mm-dd",
                                                                            },
                                                                        ]
                                                                        : [
                                                                            {
                                                                                label: "hh:mm",
                                                                                id: "hh:mm",
                                                                            },
                                                                        ]
                                                                }
                                                                classNamePrefix="select"
                                                                noOptionsMessage={() =>
                                                                    "Sin opciones"
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) => (
                                                                    <div
                                                                        className="option d-flex"
                                                                        style={{
                                                                            fontSize:
                                                                                "15px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </div>
                                                                )}
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                            />
                                                            <p
                                                                style={{
                                                                    fontSize: 11,
                                                                    color: "#6C757D",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Formato de la
                                                                respuesta que se le
                                                                solicta al usuario.
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col p-0 mt-4">
                                                    <label
                                                        htmlFor="expressionErrorMessage"
                                                        style={{
                                                            color: "#6C757D",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        Mensaje de error:{" "}
                                                    </label>
                                                    <div>
                                                        <textarea
                                                            className="form-control"
                                                            id="expressionErrorMessage"
                                                            name="expressionErrorMessage"
                                                            type="text"
                                                            style={{
                                                                width: "100%",
                                                                height: "80px",
                                                                borderRadius: "4px",
                                                            }}
                                                            {...register("regular_expressions_error_message", {
                                                                pattern: {
                                                                    value: /\S+/,
                                                                    message: "No puede ser solo espacios en blanco",
                                                                },
                                                            })}
                                                        />
                                                        <p
                                                            style={{
                                                                fontSize: 11,
                                                                color: "#6C757D",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            Mensaje que envía el
                                                            bot en si la respuesta no cumple con la expresión.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        )}
                        <div className="d-flex align-items-center mb-1 mt-4">
                            <input
                                name="flowResponse"
                                id="flowResponse"
                                type="checkbox"
                                value={selectedNode.data?.flowResponse || false}
                                className="cmn-toggle cmn-toggle-round-flat"
                                checked={selectedNode.data?.flowResponse || false}
                                onChange={(e) =>
                                    handleInputChange(
                                        "flowResponse",
                                        e.target.checked
                                    )
                                }
                            />
                            <label
                                className="mr-2 mb-0"
                                htmlFor="flowResponse"
                            ></label>

                            <div
                                style={{
                                    fontSize: 12,
                                    color: "#6C757D",
                                    fontWeight: 600,
                                }}
                            >
                                Respuesta de flujo
                            </div>
                        </div>
                        {selectedNode.data?.flowResponse && (
                            <div className="col p-0">
                                <label
                                    htmlFor="flowResponseOrder"
                                    style={{ color: "#6C757D", fontWeight: 600 }}
                                >
                                    Respuesta del cliente:{" "}
                                </label>
                                <div>
                                    <textarea
                                        className="form-control"
                                        id="flowResponseOrder"
                                        name="flowResponseOrder"
                                        type="text"
                                        style={{
                                            width: "100%",
                                            height: "50px",
                                            borderRadius: "4px",
                                        }}
                                        {...register("flow_response_order", {
                                            required: {
                                                value: selectedNode.data?.flowResponse ? true : false,
                                                message:"Campo requerido.",
                                            },
                                            pattern: {
                                                value: /\S+/,
                                                message: "No puede ser solo espacios en blanco",
                                            },
                                        })}
                                    />
                                    {errors.flow_response_order && <Alert>* {errors.flow_response_order.message}</Alert>}
                                    <p
                                        style={{
                                            fontSize: 11,
                                            color: "#6C757D",
                                            fontWeight: 600,
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                        >
                                            Importante:{" "}
                                        </span>
                                        Los campos del fuljo se deben ingresar en el
                                        orden en el que se quiere guardar, ejemplo:
                                        Campo 1 / Campo 2 / Campo 3. Estos campos
                                        deben tener el nombre exacto de como se
                                        configurarón en el flujo, de lo contrario no
                                        funcionará esta acción.
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        className="d-flex justify-content-end mt-4"
                        style={{ gap: "8px" }}
                    >
                        <a
                            href="javascript:;"
                            className="btn btn-lg btn-default mr-1"
                            onClick={() => {
                                setTimeout(() => {
                                    handleSelectedNode(null);
                                }, 200);
                            }}
                        >
                            Cerrar
                        </a>
                        <button
                            style={{
                                width: "100px",
                                backgroundColor: "#D89C7E",
                                borderColor: "#D89C7E",
                                color: "#e9edef",
                                boxShadow: "2px 2px 0px 0px #D89C7E",
                            }}
                            className="btn btn-lg"
                            type="submit"
                        >
                            {loadingUpdate ? (
                                <FaSpinner
                                    className="fa-spin"
                                    style={{ fontSize: "15px" }}
                                />
                            ) : (
                                <span>Guardar</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={
                    JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"
                }
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            />
        </>
    );
};

export default SaveAnswer;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;
